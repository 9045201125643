import React, { Component } from "react";
import ToggleSideNav from "../../UI/ToggleSideNav/ToggleSideNav";
import BackButton from "../../UI/BackButton/BackButton";
import ReactApexChart from "react-apexcharts";
import { withRouter } from "react-router-dom";
import fileSharingMethods from "../../../services/fileSharingApi";
import bytes from "bytes";
import CircularProgress from "@material-ui/core/CircularProgress";
import mqtt from "mqtt";
import info from "../../../assets/images/info.png";
import Menu from "@material-ui/core/Menu";
class DataSharing extends Component {
  state = {
    devices: [],
    series: [{}],
    options: {},
    series1: [],
    options1: {},
    group: JSON.parse(sessionStorage.getItem("Group")),
    userDevice: "",
    device_id: JSON.parse(window.sessionStorage.getItem("ConnectedDevice"))
      .device_id,
    devicesList: [],
    device: "",
    role_id: "",
    loader: false,
    mountpoint: "",
    device_name: "",
    cloudTotal: "",
    cloudUsed: "",
    externalTotal: "",
    externalAvailable: "",
    externalUsed: "",
    anchorEl: null,
  };

  componentDidMount = async () => {
    this.setState({ loader: true });
    await this.fetchHarwareDevicesList();
    this.setState({ loader: false });
  };

  redirectToFileSharing = () => {
    this.props.history.push({
      pathname: "/dashboard/fileSharing",
      state: {
        selectedDevice: this.state.device,
        mountpoint: this.state.device_name,
        device_id: this.state.device.userDevice,
        selected_device: this.state.device_id,
      },
    });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  subscribe = (device_name) => {
    console.log(this.state.device.device_id);
    var device_id = this.state.device.device_name;
    var selectedDevice = this.state.device_id;
    // var mountpoint = this.state.device_name;
    var mountpoint = device_name;
    var device = this.state.device;
    // var mosquitto = mqtt.connect("wss://cylenium.io", {
    //   port: 8083,
    //   username: "mqttdonald",
    //   password: "mqttdonald",
    //   clean: true,
    //   retain: false,
    // });
    // mosquitto.on("connect", function () {
    //   var successTopic = device_id + "/file_structure/app";
    //   // console.log(successTopic);

    //   var comms = "drisk/hardware";
    //   mosquitto.subscribe(successTopic, { retain: false, clean: true, qos: 2 });
    //   var payload = {
    //     module: "file_structure_request",
    //     mountpoint: mountpoint,
    //     path: "",
    //     device_id: device_id,
    //     selected_device_id: selectedDevice,
    //   };
    //   mosquitto.publish(comms, JSON.stringify(payload));
    //   // console.log(payload);
    //   mosquitto.on("message", function (topic, sub_message) {
    //     var payload = sub_message.toString();
    //     console.log("sub-message", payload);
    //   });
    // });
    console.log("this.state.userDevice", this.state.userDevice);
    this.props.history.push({
      pathname: "/dashboard/mqttFileSharing",
      state: {
        module: "file_structure_request",
        mountpoint: mountpoint,
        path: "",
        device_id: this.state.device.device_id,
        selected_device: selectedDevice,
        device: device,
        external_drive: this.state.external_drive,
      },
    });
  };

  changeUserDevice = (event) => {
    // console.log(event.target.value);
    this.setState({
      userDevice: event.target.value,
      series1: [],
    });
    if (event.target.value === "1") {
      this.setState({
        devices: [],
        series: [],
        options: {},
        series1: [],
        options1: {},
        group: JSON.parse(sessionStorage.getItem("Group")),
        userDevice: "",
        device_id: JSON.parse(window.sessionStorage.getItem("ConnectedDevice"))
          .device_id,
        device: "",
        role_id: "",
        device1: "",
        role_id1: "",
        mountpoint: "",
        loader: false,
        anchorEl: null,
      });
    } else {
      this.state.devicesList.forEach((element) => {
        if (event.target.value === element.device_name) {
          // console.log(element);

          this.setState({
            device: element,
            role_id: element.role_id,
            cloudTotal: element.total,
            cloudUsed: element.used,
            external_drive: element.external_drive,
          });
          // console.log(this.state.cloudTotal, typeof this.state.cloudTotal);

          let usedData = bytes.format(element.used, { unitSeparator: " " });
          let availData = bytes.format(element.total, { unitSeparator: " " });
          this.setState({
            // series: [parseInt(usedData.split(" ")[0]), parseInt(availData.split(" ")[0])],
            series: [parseInt(element.used), parseInt(element.total)],
            options: {
              chart: {
                type: "donut",
              },
              dataLabels: {
                enabled: true,
              },
              labels: ["Used", "Available"],
              legend: {
                show: false,
              },
              colors: ["#c12a37", "#53a5c0"],
              stroke: {
                show: false,
              },
              responsive: [
                {
                  breakpoint: 180,
                  options: {
                    chart: {
                      width: "10%",
                    },
                    legend: {
                      position: "bottom",
                    },
                  },
                },
              ],
              tooltip: {
                enabled: false,
              },
              // plotOptions: {
              //   pie: {
              //     donut: {
              //       labels: {
              //         show: true,
              //         total: {
              //           show: false,
              //           label: bytes.format(element.available, {
              //             unitSeparator: " ",
              //           }),
              //           color: "white",
              //         },
              //         tooltip: {
              //           enabled: true,
              //         },
              //       },
              //     },
              //   },
              // },
            },
          });
          if (element.external_drive.length > 0) {
            this.setState({
              device_name: element.external_drive[0].device_name,
              device1: element.external_drive,
              // role_id1: element.role_id,
              externalTotal: element.external_drive[0].total,
              externalAvailable: element.external_drive[0].available,
              externalUsed:
                element.external_drive[0].total -
                element.external_drive[0].available,
            });

            let availData = bytes.format(
              parseInt(element.external_drive[0].available),
              { unitSeparator: " " }
            );
            let totalData = bytes.format(
              parseInt(element.external_drive[0].total),
              { unitSeparator: " " }
            );
            let usedData = bytes.format(
              parseInt(
                element.external_drive[0].total -
                  element.external_drive[0].available
              )
            );

            this.setState({
              // series1: [parseInt(usedData.split(" ")[0]), parseInt(availData.split(" ")[0])],
              series1: [
                parseInt(
                  element.external_drive[0].total -
                    element.external_drive[0].available
                ),
                parseInt(element.external_drive[0].total),
              ],
              options1: {
                chart: {
                  type: "donut",
                },
                dataLabels: {
                  enabled: true,
                },
                labels: ["Used", "Available"],
                legend: {
                  show: false,
                },
                colors: ["#c12a37", "#53a5c0"],
                stroke: {
                  show: false,
                },
                responsive: [
                  {
                    breakpoint: 180,
                    options: {
                      chart: {
                        width: "10%",
                      },
                      legend: {
                        position: "bottom",
                      },
                    },
                  },
                ],
                tooltip: {
                  enabled: false,
                },
                // plotOptions: {
                //   pie: {
                //     donut: {
                //       labels: {
                //         show: true,
                //         total: {
                //           show: false,
                //           label: bytes.format(element.total, {
                //             unitSeparator: " ",
                //           }),
                //           color: "white",
                //         },
                //         tooltip: {
                //           enabled: true,
                //         },
                //       },
                //     },
                //   },
                // },
              },
            });
          }
        }
      });
    }
  };

  fetchHarwareDevicesList = async () => {
    let getDevices = {
      device_id: this.state.device_id,
      group_id: JSON.stringify(this.state.group.group_id),
    };

    const response = await fileSharingMethods.getHardwareDevicesList(
      getDevices
    );

    if (response.data.statusCode === 200) {
      this.setState({
        devicesList: response.data.result,
        devices: response.data.result,
      });
    }
  };

  upgradeStoragePlan = async () => {
    this.props.history.push({
      pathname: "/dashboard/upgradePlan",
      state: {
        device_id: this.state.device.device_id,
      },
    });
  };

  render() {
    const { anchorEl } = this.state;
    // console.log(this.state);
    return (
      <div className="routing-main-wrap">
        <div className="page-head">
          <div className="page_head_btn ">
            <div className="aboutus-flex d-flex align-items-center">
              <BackButton count={0} getPath="/dashboard/trusted" />
              <h3 className="pd-l10">Back to CoT Groups</h3>
            </div>
          </div>
          <ToggleSideNav batteryRefresh="false" />
        </div>
        <div className="rout-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="device-details ">
                <div className="d-flex ">
                  <div className="text-left registered-title col-6">
                    {/* <h6> Group Name: {this.state.group.group_name}</h6> */}
                    <h6>Data Sharing</h6>
                  </div>
                  {this.state.role_id === 3 ? (
                    <div
                      className="text-right upgrade-title col-6"
                      onClick={this.upgradeStoragePlan}
                    >
                      <div>
                        <h6> Upgrade </h6>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="black-card settings-card mr-b10 mr-t20">
                  <div className="col-md-12">
                    <select
                      className="fp_modal_input"
                      onChange={this.changeUserDevice}
                      value={this.state.userDevice}
                      disabled={this.state.loader}
                    >
                      <option value="1">
                        {this.state.loader
                          ? "Loading..."
                          : "Select ARMIA Device"}
                        {/* Select ARMIA Device */}
                      </option>
                      {this.state.devicesList.map((device, index) => (
                        <option value={device.device_name} key={index}>
                          {device.device_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {this.state.userDevice ? (
                    <div className="mr-t50 mr-b50">
                      <div className="text-center registered-title mr-b30">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <h6> Cloud </h6>
                          
                          {this.state.device.current_plan == "" ? null : (
                            <img
                              src={info}
                              width="15"
                              height="15"
                              alt=""
                              onClick={(e) => this.handleClick(e)}
                            />
                          )}
                          <Menu
                            style={{ marginTop: 45 }}
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                          >
                             Current Plan :{" "}
                            {this.state.device.current_plan}  <br />
                             Expiry Date : {
                              this.state.device.expire_date
                            }{" "}
                            
                          </Menu>
                        </div>
                      </div>

                      <div className="col-md-6 local-block">
                        <ReactApexChart
                          options={this.state.options}
                          series={this.state.series}
                          type="donut"
                          height={200}
                          onClick={this.redirectToFileSharing}
                        />
                      </div>

                      <div style={{ cursor: "pointer" }}>
                        <h4 className="local-title">Cloud Storage</h4>
                        <h4 className="local-title">
                          {/* {bytes.format(this.state.device.total, {
                            unitSeparator: " ",
                          })} */}
                          {this.state.cloudTotal < 1000
                            ? this.state.cloudTotal + " B"
                            : this.state.cloudTotal > 1000000 &&
                              this.state.cloudTotal < 1000000000
                            ? (this.state.cloudTotal / 1000000).toFixed(2) +
                              " MB"
                            : this.state.cloudTotal > 1000000000
                            ? (this.state.cloudTotal / 1000000000).toFixed(2) +
                              " GB"
                            : this.state.cloudTotal === 0
                            ? "0.0 MB"
                            : null}
                        </h4>
                      </div>
                    </div>
                  ) : null}
                  {this.state.role_id === 3
                    ? this.state.series1.length !== 0
                      ? this.state.external_drive.map((data, index) => (
                          <div className="mr-t50 mr-b50" key={index}>
                            <div className="text-center registered-title mr-b30">
                              <h6> External Storage </h6>
                            </div>

                            <div className="col-md-6 local-block">
                              <ReactApexChart
                                options={this.state.options1}
                                series={[
                                  data.used ? data.used : 0,
                                  data.available ? data.available : 0,
                                ]}
                                type="donut"
                                height={200}
                                onClick={() => this.subscribe(data.device_id)}
                              />
                            </div>

                            <div style={{ cursor: "pointer" }}>
                              <h4 className="local-title">
                                External Storage {index + 1}
                              </h4>
                              <h4 className="local-title">
                                {/* {bytes.format(this.state.device.total, {
                              unitSeparator: " ",
                            })} */}
                                {data.total < 1000
                                  ? data.total + " B"
                                  : data.total > 1000000 &&
                                    data.total < 1000000000
                                  ? (data.total / 100000).toFixed(2) + " MB"
                                  : data.total > 1000000000
                                  ? (data.total / 1000000000).toFixed(2) + " GB"
                                  : data.total === 0
                                  ? "0.0 MB"
                                  : 123}
                              </h4>
                            </div>
                          </div>
                        ))
                      : null
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DataSharing);
