import React, { Component } from "react";
import ToggleSideNav from "../UI/ToggleSideNav/ToggleSideNav";
import userMethods from "../../services/userApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Pagination } from "@material-ui/lab";
import { TablePagination } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Notification extends Component {
  state = {
    getAlerts: [],
    device_id: JSON.parse(window.sessionStorage.getItem("ConnectedDevice"))
      ?.device_id,
    noData: false,
    showLogout: false,
    open: false,
    message: "",
    pageNumber: 0,
    pageSize: 10,
    loading: false,
  };

  componentDidMount() {
    this.fetchNotifications(this.state.pageNumber, this.state.pageSize);
  }

  fetchNotifications = async (pageNumber, pageSize) => {
    this.setState({ loader: true });

    const response = await userMethods.getNotification(
      pageNumber + 1,
      pageSize
    );

    if (response.data.statusCode === 200) {
      this.setState({
        getAlerts: response.data.result,
      });
    }

    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message != "Session expires. Please login again"
    ) {
      this.setState({
        open: true,
        message: response.data.message,
        loader: false,
      });
    }

    if (this.state.getAlerts.length === 0) {
      this.setState({ noData: true });
    }
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ pageSize: event.target.value });
    this.fetchNotifications(this.state.pageNumber, event.target.value);
  };
  handleChangePage = (event, page) => {
    console.log("page", page);
    this.setState({ pageNumber: page });
    this.fetchNotifications(page, this.state.pageSize);
  };

  logoutUser = () => {
    window.sessionStorage.clear();
    this.props.history.replace({
      pathname: "/",
    });
  };

  updateNotifications = async (data, value) => {
    var updatedStatus = {};

    if (data.group_id === null) {
      updatedStatus = {
        id: data.notification_id,
        status: value,
        device_id: data.device_id,
        user_type: data.user_type,
      };
    } else {
      updatedStatus = {
        id: data.notification_id,
        group_id: data.group_id,
        status: value,
        device_id: data.device_id,
      };
    }

    const response = await userMethods.updateNotificationStatus(updatedStatus);

    if (response.data.statusCode === 200) {
      this.fetchNotifications();
      if (value == "Accepted") window.location.reload();
    }
  };

  render() {
    return (
      <div className="routing-main-wrap">
        <div className="page-head">
          <Snackbar
            open={this.state.open}
            autoHideDuration={3000}
            onClose={() => this.setState({ open: false })}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert
              // onClose={handleClose}
              severity="info"
              sx={{ width: "100%" }}
            >
              {this.state.message}
            </Alert>
          </Snackbar>
          <h3>Alerts</h3>
          <ToggleSideNav batteryRefresh="false" />
        </div>
        <div className="rout-body">
          <div className="row">
            <div className="col-sm-12">
              {this.state.getAlerts.length === 0 ? (
                this.state.noData ? (
                  <div className="text-center registered-title mr-b20 mr-t20">
                    <label style={{ color: "#fff" }}>No notifications.</label>
                  </div>
                ) : (
                  <CircularProgress
                    color="secondary"
                    style={{ margin: "20% 0% 0% 50%" }}
                  />
                )
              ) : (
                <div className="device-details">
                  {this.state.getAlerts?.records?.map((data, index) => (
                    <div className="black-card d-d-card mr-t10" key={index}>
                      <div className="d-d-card-head">
                        {" "}
                        <h4 className="alerts-msg">{data.message}</h4>
                      </div>
                      <div className="device-info-wrap mr-t10">
                        <div className="row">
                          {data.input_required === 1 ? (
                            data.status === "Pending" ? (
                              <div className="col-md-12">
                                <div
                                  className="d-info-card alerts"
                                  onClick={() =>
                                    this.updateNotifications(data, "Accepted")
                                  }
                                >
                                  <label className="col-md-2 accept">
                                    Accept
                                  </label>
                                </div>
                                <div
                                  className="d-info-card alerts"
                                  onClick={() =>
                                    this.updateNotifications(data, "Rejected")
                                  }
                                >
                                  <label className="col-md-2 reject">
                                    Reject
                                  </label>
                                </div>
                              </div>
                            ) : data.status === "Accepted" ? (
                              <div className="col-md-12">
                                <div
                                  className="d-info-card alerts"
                                  onClick={() =>
                                    this.updateNotifications(data, "Accepted")
                                  }
                                >
                                  <label className="col-md-2 accepted">
                                    Accepted
                                  </label>
                                </div>
                              </div>
                            ) : data.status === "Rejected" ? (
                              <div className="col-md-12">
                                <div
                                  className="d-info-card alerts"
                                  onClick={() =>
                                    this.updateNotifications(data, "Rejected")
                                  }
                                >
                                  <label className="col-md-2 rejected">
                                    Rejected
                                  </label>
                                </div>
                              </div>
                            ) : null
                          ) : null}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "rgb(74, 172, 220)",
                          }}
                        >
                          {
                            <span
                              className=""
                              style={{ color: "rgb(74, 172, 220)" }}
                            >
                              {" " + data?.device_id}
                            </span>
                          }
                          {data.created_date}
                        </div>
                      </div>
                    </div>
                  ))}
                  <TablePagination
                    component="div"
                    count={this.state?.getAlerts?.total_records}
                    page={this.state.pageNumber}
                    onPageChange={this.handleChangePage}
                    rowsPerPage={this.state.pageSize}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />{" "}
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.showLogout ? (
          <Modal
            open={this.state.showLogout}
            aria-labelledby="DeleteUserProfile"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Session Expired
                  </h5>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">
                      Please login again.
                      <br />
                      <br />
                    </h6>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.logoutUser}
                    // disabled={this.state.loader}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default Notification;
