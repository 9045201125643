import React, { Component } from "react";
import ToggleSideNav from "../../UI/ToggleSideNav/ToggleSideNav";
import $ from "jquery";
import BackButton from "../../UI/BackButton/BackButton";
import { Checkbox, IconButton } from "@material-ui/core";
import fileSharingMethods from "../../../services/fileSharingApi";
import moment from "moment";
import bytes from "bytes";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import battery from "../../../assets/images/battery.png";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import McModal from "./McModal";
import CopyToDrive from "./CopyToDrive";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class SubFileSharing extends Component {
  toggleSideNav() {
    $(".sidebar-wrap").toggleClass("show-menu");
    $(".menu-toggle-btn").toggleClass("t-change-btn");
  }
  state = {
    group: JSON.parse(sessionStorage.getItem("Group")),
    getUrl: {
      pathname: "/dashboard/fileSharing",
      state: { selectedDevice: this.props.location.state[0].selectedDevice },
    },
    folder: this.props.location.state[0].folder,
    selectedDevice: this.props.location.state[0].selectedDevice,
    mountpoint: this.props.location.state[0].mountpoint,
    device_id: this.props.location.state[0].device_id,
    selected_device: this.props.location.state.selectedDevice,
    subfileStructureList: [],
    loader: false,
    checkFiles: [
      { id: 1, name: "File 1", isChecked: false },
      { id: 2, name: "File 2", isChecked: false },
      { id: 3, name: "File 3", isChecked: false },
    ],
    count: 0,
    prevFolder: this.props.location.state[0].folder,
    anchorEl: null,
    anchorElHeader: null,
    editModal: false,
    uploadFile: null,
    folderName: "",
    rename: "",
    errorModal: "",
    modalTitle: "Folder",
    modalLabel: "Enter new Folder",
    updateType: "Folder",
    fileName: "",
    selectedFileList: [],
    file: {},
    toBeUploadedFileList: [],
    toBeUploadedFileNameList: [],
    toBeUploadedFileListSize: 0,
    currentPath: "",
    destPath: "",
    MCselectedFileList: [],
    showLoader: false,
    oldFileName: "",
  };

  componentDidMount() {
    this.fetchSubFileStructure();
  }

  fetchSubFileStructure = async () => {
    //
    this.setState({
      loader: true,
    });
    let pathObject = {
      path: `/dRisk/${this.state.selectedDevice.device_id}/${this.state.folder.basename}`,
    };
    {
      this.setState({ currentPath: pathObject.path });
    }
    const response = await fileSharingMethods.getFileStructure(pathObject);
    console.log("response", response);
    if (response.data.statusCode === 200) {
      this.setState({
        subfileStructureList: response.data.result,
        loader: false,
      });
    }
  };

  redirectToSubFileStructure = (data) => {
    //
    // this.props.history.push({
    //   pathname: "/dashboard/subFileSharing",
    //   state: [{ selectedDevice: this.state.selectedDevice, folder: data }],
    // });
    this.setState(
      {
        selectedDevice: this.state.selectedDevice,
        prevFolder: this.state.folder,
        folder: {
          ...data,
          basename: this.state.folder.basename + "/" + data.basename,
        },
        count: this.state.count + 1,
      },
      () => this.fetchSubFileStructure()
    );
  };

  redirectBack = (data) => {
    //
    // this.props.history.push({
    //   pathname: "/dashboard/subFileSharing",
    //   state: [{ selectedDevice: this.state.selectedDevice, folder: data }],
    // });
    this.setState(
      {
        selectedDevice: this.state.selectedDevice,
        folder: {
          basename: this.state.folder.basename.substring(
            0,
            this.state.folder.basename.lastIndexOf("/")
          ),
        },
        count: this.state.count - 1,
      },
      () => this.fetchSubFileStructure()
    );
  };

  handleFileDownload = (e, data) => {
    //
    const array = this.state.selectedFileList;
    if (e.target.checked) {
      array.push(data.filename);
    } else {
      array.splice(array.indexOf(data.filename), 1);
    }
    // if (array.includes(data.fileName)) {
    //   array.push(data.fileName);
    // } else {
    //   array.splice(data.fileName);
    // }
    this.setState({ selectedFileList: array });
    //
    // if (this.state.selectedFileList.length > 1) {
    // } else {
    // }
  };

  handleClick = (event, file) => {
    this.setState({ anchorEl: event.currentTarget });
    // this.setState({ anchorElHeader: event.currentTarget });
    this.setState({ file: file });
  };
  handleSingle = (event, key) => {
    // this.setState({ anchorEl: event.currentTarget });
    this.setState({ anchorElHeader: event.currentTarget });
    key = { key };
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ anchorElHeader: null });
    this.setState({ editModal: false });
  };

  fetchHarwareDevicesList = async () => {
    let getDevices = {
      device_id: this.state.device_id,
      group_id: JSON.stringify(this.state.group.group_id),
    };

    const response = await fileSharingMethods.getHardwareDevicesList(
      getDevices
    );

    if (response.data.statusCode === 200) {
      this.setState({
        devicesList: response.data.result,
        devices: response.data.result,
      });
    }
  };

  handleOpen = (filename, type) => {
    //
    this.setState({
      editModal: true,
      updateType: type,
      errorModal: "",
    });

    if (type === "Folder") {
      this.setState({
        modalTitle: "New Folder",
        modalLabel: "Enter folder name",
      });
    }

    if (type === "rename") {
      //  Set Password
      this.setState({
        modalTitle: "Rename",
        modalLabel: "Enter new name",
        oldFileName: filename.basename,
      });
    }

    if (type === "File") {
      //  Set Password
      this.setState({
        modalTitle: "Upload File",
        modalLabel: "Choose files",
      });
    }

    if (type === "Move/Copy") {
      //  Set Password
      this.setState({
        modalTitle: "Move/Copy",
        modalLabel: "Select Destination",
      });
    }

    if (type === "MoveToExternal") {
      //  Set Password
      this.setState({
        modalTitle: "Move To External Drive",
        modalLabel: "Select Destination",
      });
    }
  };

  handleUpdateValue = async (event) => {
    await this.setState({
      [event.target.name]: event.target.value,
    });
  };
  // -----------------------------------------------file upload
  changeHandler = (event) => {
    this.setState({
      toBeUploadedFileList: Array.from(event.target.files),
      // setIsSelected(true);
    });
  };

  Validation = () => {
    const fileNameList = [];
    let totalFileSize = 0;
    this.state.toBeUploadedFileList.forEach((file) => {
      //
      fileNameList.push(file.name);
      totalFileSize += file.size;
    });
    this.setState({
      toBeUploadedFileNameList: fileNameList,
      toBeUploadedFileListSize: totalFileSize,
    });
    //
    return {
      fileNameList,
      totalFileSize,
    };
  };

  updateTextSettingData = async (filename) => {
    this.setState({
      showLoader: true,
    });
    //
    //
    if (this.state.updateType === "Folder") {
      if (this.state.folderName === "") {
        this.setState({
          errorModal: "Please enter a name",
        });
        const regex = /[<>:"\/\\|?*\x00-\x1F]/;
        if (regex.test(this.state.folderName)) {
          this.setState({
            errorModal: "Special symbols not allowed",
          });
        }
      } else {
        this.setState({
          errorModal: "",
          loader: true,
        });
        var updateObject = {
          path: `/dRisk/${this.state.selectedDevice.device_id}/${this.state.folder.basename}/${this.state.folderName}`,
        };
        const response = await fileSharingMethods.createDirectory(updateObject);
        if (response.data.statusCode === 200) {
          this.setState({
            open: true,
            message: response.data.message,
            loader: false,
          });
          this.resetValues();
        }

        this.fetchSubFileStructure();
      }
    }

    if (this.state.updateType === "rename") {
      if (this.state.rename === "") {
        this.setState({
          errorModal: "Please enter a name",
        });
      } else {
        this.setState({
          errorModal: "",
          loader: true,
        });
        var updateObject = {
          source: this.state.file.filename,
          newName: this.state.rename,
        };
        const response = await fileSharingMethods.renameFiles(updateObject);
        if (response.data.statusCode === 200) {
          this.setState({
            open: true,
            message: response.data.message,
            loader: false,
          });
          this.resetValues();
        }
        this.fetchSubFileStructure();
      }
    }

    if (this.state.updateType === "File") {
      const { fileNameList, totalFileSize } = this.Validation();
      if (this.state.toBeUploadedFileList === "") {
        this.setState({
          errorModal: "Please select a file",
        });
      } else {
        this.setState({
          errorModal: "",
          loader: true,
        });
        //
        //
        var updateObject = {
          device_id: this.state.selectedDevice.device_id,
          file_size: totalFileSize,
          file_names: fileNameList,
          despath: `/dRisk/${this.state.selectedDevice.device_id}/${this.state.folder.basename}`,
        };
        // var upload = {
        //   demo_image: this.state.toBeUploadedFileList,
        //   desPath: `/dRisk/${this.state.selectedDevice.device_id}`,
        // };
        let formData = new FormData(); //formdata object
        // formData.append("demo_image", this.state.toBeUploadedFileList);
        this.state.toBeUploadedFileList?.forEach((data) => {
          formData.append("demo_image", data);
        });
        formData.append(
          "desPath",
          `/dRisk/${this.state.selectedDevice.device_id}/${this.state.folder.basename}`
        );

        const response = await fileSharingMethods.validateSize(updateObject);
        if (response.data.statusCode === 200) {
          const response = await fileSharingMethods.uploadFiles(formData);
          if (response.data.statusCode === 200) {
            this.resetValues();
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        }
        this.setState({
          loader: true,
        });
        this.fetchSubFileStructure();
        if (response.data.statusCode === 200) {
          this.setState({
            subfileStructureList: response.data.result,
            loader: false,
            showLoader: false,
          });
        }
      }
    }
  };
  moveFile = async (filename) => {
    var updateObject = {
      source: this.state.selectedFileList,
      dest: this.state.destPath,
    };
    const response = await fileSharingMethods.moveFiles(updateObject);
    if (response.data.statusCode === 200) {
      this.resetValues();
    }
    //
    this.fetchFileStructure();
  };

  copyFile = async (filename) => {
    var updateObject = {
      source: this.state.selectedFileList,
      dest: this.state.destPath,
    };
    const response = await fileSharingMethods.copyFiles(updateObject);
    if (response.data.statusCode === 200) {
      this.resetValues();
    }
    //
    this.fetchFileStructure();
  };

  download = async (file) => {
    if (file.type === "file") {
      var updateObject = {
        path: [file.filename],
      };
      const response = await fileSharingMethods.downloadFiles(updateObject);
      window.location.href = `http://3.214.93.141:5002/api/v3.0/fileSharing/downloadFiles/{"path":"${file.filename}"}`;
      if (response.data.statusCode === 200) {
        // window.open(fileSharingMethods.downloadFiles(updateObject), "_blank");
        // window.location.href = `http://3.214.93.141:5002/api/v3.0/fileSharing/downloadFiles/{"path":"/dRisk/WINJIT-3268414905/Canada_Passport_2.JPG"}`;
        this.resetValues();
      }
    } else {
      var updateObject = {
        path: [file.filename],
      };
      const response = await fileSharingMethods.downloadFolders(updateObject);
      window.location.href = `http://3.214.93.141:5002/api/v3.0/fileSharing/downloadFoldersWeb/{"path":"${file.filename}"}`;
      if (response.data.statusCode === 200) {
        this.resetValues();
      }
    }
  };

  delete = async (filename) => {
    var updateObject = {
      path: [filename],
    };
    const response = await fileSharingMethods.deleteFile(updateObject);
    if (response.data.statusCode === 200) {
      this.resetValues();
    }
    this.fetchSubFileStructure();
  };

  //-----------------------------------------------------------------------------------------multiple-files handling
  downloadMultiple = async (file) => {
    var updateObject = {
      path: this.state.selectedFileList,
    };
    const response = await fileSharingMethods.downloadFolders(updateObject);
    window.location.href = `http://3.214.93.141:5002/api/v3.0/fileSharing/downloadFoldersWeb/{"path":"${this.state.selectedFileList}"}`;
    if (response.data.statusCode === 200) {
      // window.open(fileSharingMethods.downloadFiles(updateObject), "_blank");
      // window.location.href = `http://3.214.93.141:5002/api/v3.0/fileSharing/downloadFiles/{"path":"/dRisk/WINJIT-3268414905/Canada_Passport_2.JPG"}`;
      this.resetValues();
    }
  };

  deleteMultiple = async (filename) => {
    var updateObject = {
      path: this.state.selectedFileList,
    };
    const response = await fileSharingMethods.deleteFile(updateObject);
    if (response.data.statusCode === 200) {
      this.resetValues();
    }
    this.fetchSubFileStructure();
  };

  resetValues = () => {
    this.setState({
      fileStructureList: [],
      subfileStructureList: [],
      loader: false,
      anchorEl: null,
      anchorElHeader: null,
      editModal: false,
      uploadFile: null,
      folderName: "",
      rename: "",
      errorModal: "",
      modalTitle: "Folder",
      modalLabel: "Enter new Folder",
      updateType: "Folder",
      fileName: "",
      selectedFileList: [],
      file: {},
      toBeUploadedFileList: [],
      toBeUploadedFileNameList: [],
      toBeUploadedFileListSize: 0,
    });
  };

  render() {
    const { anchorEl } = this.state;
    console.log(this.props);
    return (
      <>
        <div className="routing-main-wrap">
          <Snackbar
            open={this.state.open}
            autoHideDuration={3000}
            onClose={() => this.setState({ open: false })}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert
              // onClose={handleClose}
              severity="info"
              sx={{ width: "100%" }}
            >
              {this.state.message}
            </Alert>
          </Snackbar>
          <div className="page-head">
            <div className="page_head_btn">
              <div className="aboutus-flex d-flex align-items-center">
                <BackButton
                  count={this.state.count}
                  redirectBack={this.redirectBack}
                  getPath={this.state.getUrl}
                />
                <h3 className="pd-l10">Cloud Storage</h3>
              </div>
            </div>
            {/* <ToggleSideNav batteryRefresh="false" /> */}
            <div>
              <div>
                <div style={{ display: "inline-block" }}>
                  <AddBoxIcon
                    aria-owns={this.state.anchorElHeader ? "simple" : undefined}
                    key={1}
                    aria-haspopup="true"
                    onClick={this.handleSingle}
                  />
                  {/* Open Menu
        </Button> */}
                  <Menu
                    style={{ marginTop: 30 }}
                    id="simple"
                    anchorEl={this.state.anchorElHeader}
                    open={Boolean(this.state.anchorElHeader)}
                    onClose={this.handleClose}
                  >
                    <MenuItem onClick={() => this.handleOpen("", "File")}>
                      Add File
                    </MenuItem>
                    <MenuItem onClick={() => this.handleOpen("", "Folder")}>
                      Create New Folder
                    </MenuItem>
                  </Menu>
                </div>
                {/* <img
                  src={battery}
                  alt="battery"
                  height="25px"
                  width="25px"
                  align="left"
                  style={{ paddingRight: "10px" }}
                /> */}
                <button
                  type="button"
                  className="menu-toggle-btn"
                  onClick={this.toggleSideNav}
                >
                  <svg
                    className="toggle-icon"
                    width="18"
                    height="12"
                    viewBox="0 0 18 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 12H9V10H0V12Z" fill="#E5E5E5" />
                    <path d="M0 7H18V5H0V7Z" fill="#E5E5E5" />
                    <path d="M9 0V2H18V0H9Z" fill="#E5E5E5" />
                  </svg>
                  <svg
                    className="close-menu"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                      fill="#ffffff"
                    />
                  </svg>
                </button>
              </div>
              {this.state.editModal ? (
                <Modal
                  open={this.state.editModal}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <div
                    className="modal-dialog modal-lg modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content modal_fp_content">
                      <div className="modal-header forget_pw_header">
                        {/* <button
                        type="button"
                        className="close modal_fp_close_btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.handleClose}
                      >
                        <span aria-hidden="true">
                          <svg
                            width="10"
                            height="18"
                            viewBox="0 0 10 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </button> */}
                        <h5
                          className="modal-title modal_fp_title "
                          id="exampleModalLabel"
                        >
                          {this.state.modalTitle}
                        </h5>
                        <IconButton
                          style={{ fontSize: 48, color: "white" }}
                          aria-label="Close"
                          onClick={() => {
                            this.handleClose();
                            this.fetchSubFileStructure();
                          }}
                        >
                          <CloseRoundedIcon size="large" />
                        </IconButton>
                      </div>

                      <div className="modal-body fp_modal_body">
                        <div className="col-12 col-sm-12 col-md-12 modal_fp_body ">
                          <div className="fp_label">
                            {/* <h6>
                            {this.state.modalLabel}
                            <em className="mandatory">* </em>
                          </h6> */}

                            {/* {this.state.updateType === "Folder" ? (
                            <input
                              type="text"
                              className="fp_modal_input"
                              value={this.state.folderName}
                              name="folderName"
                              onChange={this.handleUpdateValue}
                            />
                          ) : (
                            <input
                              type="file"
                              className="fp_modal_input"
                              name="file"
                              // value={this.state.rename}
                              onChange={this.onFileChange}
                            />
                          )} */}
                            {this.state.updateType === "Folder" && (
                              <input
                                type="text"
                                className="fp_modal_input"
                                value={this.state.folderName}
                                name="folderName"
                                onChange={this.handleUpdateValue}
                              />
                            )}
                            {this.state.updateType === "File" && (
                              <input
                                type="file"
                                multiple
                                className="fp_modal_input"
                                name="file"
                                // value={this.state.UploadFiles}
                                onChange={this.changeHandler}
                              />
                            )}
                            {this.state.updateType === "rename" && (
                              <input
                                type="text"
                                className="fp_modal_input"
                                name="rename"
                                defaultValue={this.state.oldFileName}
                                onChange={this.handleUpdateValue}
                              />
                            )}
                            {/* ---------------------------------------------------------Move/Copy Modal */}
                            {this.state.updateType === "Move/Copy" && (
                              <>
                                <McModal
                                  selectedDevice={this.state.selectedDevice}
                                  initialPath={this.state.currentPath}
                                  initialArray={this.state.selectedFileList}
                                  sourcePath={this.state.MCselectedFileList}
                                  handleClose={this.handleClose}
                                  fetchFileStructure={
                                    this.fetchSubFileStructure
                                  }
                                  state={this.state}
                                />
                              </>
                            )}
                            {/* --------------------------------------ExternalDrive */}
                            {this.state.updateType === "MoveToExternal" && (
                              <>
                                <CopyToDrive
                                  selectedDevice={this.state.selectedDevice}
                                  mountpoint={this.state.mountpoint}
                                  device_id={this.state.device_id}
                                  selected_device={this.state.selectedDevice}
                                  filename={this.state.file.filename}
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="color-red">
                          <i>{this.state.errorModal}</i>
                        </div>
                      </div>
                      {this.state.updateType === "Move/Copy" ||
                      this.state.updateType === "MoveToExternal" ? null : ( // </div> //   </button> //     {this.state.loader ? <i className="fa fa-spinner fa-spin"></i> : null} //     Copy  //   > //     disabled={this.state.loader} //     onClick={this.copyFile} //     className="btn-fp-footer btn-Reset" //     type="button" //   <button //   </button> //     {this.state.loader ? <i className="fa fa-spinner fa-spin"></i> : null} //     Move  //   > //     disabled={this.state.loader} //     onClick={this.moveFile} //     className="btn-fp-footer btn-Reset" //     type="button" //   <button // <div className="modal-footer footer_fp_modal">
                        <div className="modal-footer footer_fp_modal">
                          <button
                            type="button"
                            className="btn-fp-footer btn-Reset"
                            onClick={this.updateTextSettingData}
                            disabled={this.state.loader}
                          >
                            Update 
                            {this.state.loader ? (
                              <i className="fa fa-spinner fa-spin"></i>
                            ) : null}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </Modal>
              ) : null}
            </div>
          </div>

          <div className="rout-body">
            <div className="row">
              <div className="col-sm-12">
                <div className="device-details">
                  <ul className="filesharing-card row">
                    {this.state.selectedFileList.length > 1 ? (
                      <div
                        style={{
                          paddingBottom: "15px",
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <button
                          style={{
                            backgroundColor: "#c12a37",
                            color: "white",
                            borderRadius: "10px",
                            height: "38px",
                            width: "150px",
                          }}
                          onClick={(e) => this.downloadMultiple()}
                        >
                          Download All
                        </button>
                        <button
                          style={{
                            backgroundColor: "#c12a37",
                            color: "white",
                            borderRadius: "10px",
                            height: "38px",
                            width: "150px",
                          }}
                          onClick={(e) =>
                            this.handleOpen(
                              this.state.file.filename,
                              "Move/Copy"
                            )
                          }
                        >
                          Move/Copy All
                        </button>
                        <button
                          style={{
                            backgroundColor: "#c12a37",
                            color: "white",
                            borderRadius: "10px",
                            height: "38px",
                            width: "150px",
                          }}
                          onClick={(e) => this.deleteMultiple()}
                        >
                          Delete All
                        </button>
                      </div>
                    ) : null}
                    {this.state.subfileStructureList.length > 0 ? (
                      this.state.subfileStructureList.map((data, index) => (
                        <li key={index} className="col-md-12">
                          {data.type === "directory" ? (
                            <div
                              className="col-2 col-sm-2 col-md-2"
                              onClick={() =>
                                this.redirectToSubFileStructure(data)
                              }
                            >
                              <h3
                                style={{
                                  color: "#6BC4E2",
                                  cursor: "pointer",
                                }}
                              >
                                <i
                                  className="fa fa-folder"
                                  aria-hidden="true"
                                ></i>
                              </h3>
                            </div>
                          ) : (
                            <div className="col-2 col-sm-2 col-md-2 ">
                              <h3
                                style={{
                                  color: "#6BC4E2",
                                  cursor: "pointer",
                                }}
                              >
                                <i
                                  className="fa fa-file"
                                  aria-hidden="true"
                                ></i>
                              </h3>
                            </div>
                          )}

                          <div className="col-6 col-sm-6 col-md-6 header_button_flex">
                            <div className="head_btn_flex">
                              <div className="head_btn_flex_headings">
                                <h5 className="drisk-name">{data.basename}</h5>
                                <h6 className="drisk-date">
                                  {moment(data.lastmod).format("LL")}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="col-2 col-sm-2 col-md-2 header_button_flex mr-t10">
                            <div className="head_btn_flex">
                              <div className="head_btn_flex_headings">
                                <h6 className="drisk-date">
                                  {bytes.format(data.size, {
                                    unitSeparator: " ",
                                  })}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="col-1 col-sm-1 col-md-1">
                            <Checkbox
                              value={this.state.checkFiles[0].name}
                              // onChange={(data) => {
                              //   this.handleFileDownload();
                              //
                              // }}
                              onChange={(e) => this.handleFileDownload(e, data)}
                              // checked={this.state.checkFiles[0].isChecked}
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                            />
                          </div>

                          <div className="col-1 col-sm-1 col-md-1">
                            <label
                              style={{
                                color: "#6BC4E2",
                                cursor: "pointer",
                              }}
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                                aria-owns={anchorEl ? "simple-menu" : undefined}
                                aria-haspopup="true"
                                onClick={(e) => this.handleClick(e, data)}
                                key={data.fileid}
                              />
                              <Menu
                                style={{ marginTop: 45 }}
                                id="simple-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={this.handleClose}
                              >
                                <MenuItem
                                  onClick={() =>
                                    this.handleOpen(this.state.file, "rename")
                                  }
                                >
                                  Rename
                                </MenuItem>
                                <MenuItem
                                  onClick={() => this.download(this.state.file)}
                                >
                                  Download
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    this.handleOpen(
                                      this.setState({
                                        MCselectedFileList:
                                          this.state.file.filename,
                                      }),
                                      "Move/Copy"
                                    )
                                  }
                                >
                                  Move/Copy to
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    this.handleOpen(
                                      this.state.file,
                                      "MoveToExternal"
                                    )
                                  }
                                >
                                  Move to external drive
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    this.delete(this.state.file.filename)
                                  }
                                >
                                  Delete
                                </MenuItem>
                              </Menu>
                            </label>
                          </div>
                        </li>
                      ))
                    ) : this.state.showLoader == true ? (
                      <CircularProgress
                        color="secondary"
                        style={{ margin: "0% 0% 0% 50%" }}
                      />
                    ) : (
                      <div className="text-center registered-title mr-b20 mr-t20">
                        <h6
                          style={{
                            color: "#C12A37",
                            fontSize: "15px",
                            display: "block !important",
                            width: "750px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          No Data Available
                        </h6>
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SubFileSharing;
