import React, { Component } from "react";
import ToggleSideNav from "../../UI/ToggleSideNav/ToggleSideNav";
import BackButton from "../../UI/BackButton/BackButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Checkbox } from "@material-ui/core";
import fileSharingMethods from "../../../services/fileSharingApi";
import moment from "moment";
import bytes from "bytes";
import { Menu, Button, IconButton } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import $ from "jquery";
import battery from "../../../assets/images/battery.png";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import CopyToCloud from "./CopyToCloud";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

class FileSharing extends Component {
  toggleSideNav() {
    $(".sidebar-wrap").toggleClass("show-menu");
    $(".menu-toggle-btn").toggleClass("t-change-btn");
  }
  state = {
    group: JSON.parse(sessionStorage.getItem("Group")),
    getUrl: "/dashboard/dataSharing",
    showfolders: true,
    showDownload: false,
    checkFiles: [
      { id: 1, name: "File 1", isChecked: false },
      { id: 2, name: "File 2", isChecked: false },
      { id: 3, name: "File 3", isChecked: false },
    ],
    module: "file_structure_request",
    mountpoint: this.props.location.state.mountpoint,
    path: "",
    device_id: this.props.location.state.device_id,
    selectedDevice: this.props.location.state.selected_device,
    currentDevice: this.props.location.state.device,
    external_drive: this.props.location.state.external_drive,

    fileStructureList: [],
    desPath: "",
    loader: false,
    anchorEl: null,
    anchorE2: null,
    editModal: false,
    uploadFile: null,
    folderName: "",
    urlPath: [],
    rename: "",
    errorModal: "",
    modalTitle: "Folder",
    modalLabel: "Enter new Folder",
    updateType: "Folder",
    fileName: "",
    selectedFileList: [],
    file: {},
    toBeUploadedFileList: [],
    toBeUploadedFileNameList: [],
    toBeUploadedFileListSize: 0,
    currentPath: "", //navigate through the modal
    currentWorkingPath: "", //navigating through the folders
    currentHandlingFileName: "", // com path to pass fwd as prop
    count: 0,
    showLoader: false,
    //--------------------------------------------------
  };

  componentDidMount = async () => {
    this.getData("", "initial");
  };

  getData = async (data) => {
    console.log(data);
    this.setState({
      showLoader: true,
    });

    let payload = {};
    if (!data?.length) {
      this.setState({ urlPath: [] });
      payload = {
        device_id: this.state.device_id,
        path: this.state.mountpoint,
      };
    } else {
      this.setState({ urlPath: data });
      let tempUrl = data.join("/");
      payload = {
        device_id: this.state.device_id,
        path: `${this.state.mountpoint}/${tempUrl}`,
      };
    }
    console.log(payload);
    const response = await fileSharingMethods.fileStructure(payload);
    // console.log("response", response);
    if (response.data.statusCode == 200) {
      this.setState({
        fileStructureList: response.data.result,
        showLoader: false,
        flag: "check",
      });
    } else {
      this.setState({ fileStructureList: [], showLoader: false });
    }
  };

  handleClick = (event, file) => {
    this.setState({ anchorEl: event.currentTarget });
    // this.setState({ anchorE2: event.currentTarget });
    this.setState({ file: file });
    this.setState({ currentHandlingFileName: file.file });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ editModal: false });
  };

  handleOpen = (filename, type) => {
    //
    this.setState({
      editModal: true,
      updateType: type,
      errorModal: "",
      fileName: filename,
    });
    console.log("Selected Device", this.state.currentDevice.device_name);
    if (type === "Copy") {
      this.setState({
        modalTitle: "Copy to" + " " + this.state.currentDevice.device_name,
        modalLabel: "Select destination",
      });
    }
  };

  updateTextSettingData = async (data) => {
    if (this.state.updateType === "Copy") {
      var updateObject = {
        source: [data.name],
        dest: this.state.desPath, //get dest from CopyToCloud
      };
      const response = await fileSharingMethods.copyFiles(updateObject);
      this.getData();
    }
  };

  getCharCount(str) {
    let charCount = 0;
    for (let i = 0; i < str.length; i++) {
      if (str.charAt(i) === "/") charCount++;
    }
    return charCount;
  }

  redirectToMqttSubFolder(data) {
    sessionStorage.setItem("previouspath", JSON.stringify(data.name));
    // this.subscribe(data.name, "post");
    const urlPath = this.state.urlPath;
    urlPath.push(data?.name);

    // console.log("data", data);
    this.getData(urlPath);
  }
  goBack() {
    const urlPath = this.state.urlPath;
    urlPath.pop();
    this.getData(urlPath);
    if (!urlPath?.length) this.props.history.push(this.state.getUrl);
  }
  render() {
    const { anchorEl } = this.state;
    return (
      <div className="routing-main-wrap">
        <div className="page-head">
          <div className="page_head_btn">
            <div className="aboutus-flex d-flex align-items-center">
              <button
                type="button"
                className="back-btn-about-us"
                onClick={() => this.goBack()}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M3 9.62109H20" stroke="white" strokeWidth="2" />
                  <path
                    d="M11.3103 1L2 10.3103L11.3103 19.6207"
                    stroke="white"
                    strokeWidth="2"
                  />
                </svg>
                
              </button>
              <h3 className="pd-l10">Hardware Storage</h3>
            </div>
          </div>
          {/* ------------------------------------------------Header */}
          {/* <ToggleSideNav batteryRefresh="false" /> */}
          <div>
            <div>
              {/* Open Menu
        </Button> */}
              <img
                src={battery}
                alt="battery"
                height="25px"
                width="25px"
                align="left"
                style={{ paddingRight: "10px" }}
              />
              <button
                type="button"
                className="menu-toggle-btn"
                onClick={this.toggleSideNav}
              >
                <svg
                  className="toggle-icon"
                  width="18"
                  height="12"
                  viewBox="0 0 18 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 12H9V10H0V12Z" fill="#E5E5E5" />
                  <path d="M0 7H18V5H0V7Z" fill="#E5E5E5" />
                  <path d="M9 0V2H18V0H9Z" fill="#E5E5E5" />
                </svg>
                <svg
                  className="close-menu"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                    fill="#ffffff"
                  />
                </svg>
              </button>
            </div>
            {this.state.editModal ? (
              <Modal
                open={this.state.editModal}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <div
                  className="modal-dialog modal-lg modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content modal_fp_content">
                    <div className="modal-header forget_pw_header">
                      {/* <button
                        type="button"
                        className="close modal_fp_close_btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.handleClose}
                      >
                        <span aria-hidden="true">
                          <svg
                            width="10"
                            height="18"
                            viewBox="0 0 10 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </button> */}
                      <h5
                        className="modal-title modal_fp_title "
                        id="exampleModalLabel"
                      >
                        {this.state.modalTitle}
                      </h5>
                      {this.getCharCount(this.state.currentWorkingPath) < 2 ? (
                        <IconButton
                          style={{ fontSize: 48, color: "white" }}
                          aria-label="Close"
                          onClick={() => {
                            this.handleClose();
                          }}
                        >
                          <CloseRoundedIcon size="large" />
                        </IconButton>
                      ) : this.state.currentWorkingPath.split("/").length >
                        2 ? (
                        this.subscribe(this.state.currentWorkingPath, "pre")
                      ) : (
                        this.subscribe("", "initial")
                      )}
                    </div>

                    <div className="modal-body fp_modal_body">
                      <div className="col-12 col-sm-12 col-md-12 modal_fp_body">
                        <div className="fp_label">
                          {/* ---------------------------------------------------------Move/Copy Modal */}
                          {this.state.updateType === "Copy" && (
                            <>
                              <CopyToCloud
                                selectedDevice={
                                  this.state.currentDevice.device_id
                                }
                                currentWorkingPath={this.state.fileName}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="color-red">
                        <i>{this.state.errorModal}</i>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            ) : null}
          </div>
        </div>

        {/* ----------------------------------------------- Actual body */}
        <div className="rout-body">
          <div className="row">
            <div className="col-sm-12">
              <div>
                {/* <Button
                  variant="contained"
                  onClick={() => {
                    
                    if (this.state.currentWorkingPath.split("/").length > 2) {
                      this.subscribe(this.state.currentWorkingPath, "pre");
                    } else {
                      this.subscribe("", "initial");
                    }
                  }}
                >
                  Go Backthis.state.count
                </Button> */}
              </div>
              <div className="device-details">
                <ul className="filesharing-card row">
                  {this.state.showLoader === true ? (
                    <CircularProgress
                      color="secondary"
                      style={{ margin: "0% 0% 0% 50%" }}
                    />
                  ) : null}
                  {this.state.fileStructureList.length ? (
                    this.state.fileStructureList.map((data, index) => (
                      <li key={data.name} className="col-md-12">
                        {/* {cdata.name)} */}
                        {data.type === "directory" ? (
                          <div
                            className="col-2 col-sm-2 col-md-2"
                            onClick={() => {
                              this.redirectToMqttSubFolder(data);
                            }}
                          >
                            <h3
                              style={{
                                color: "#6BC4E2",
                                cursor: "pointer",
                              }}
                            >
                              <i
                                className="fa fa-folder"
                                aria-hidden="true"
                              ></i>
                            </h3>
                          </div>
                        ) : (
                          <div className="col-2 col-sm-2 col-md-2 ">
                            <h3
                              style={{
                                color: "#6BC4E2",
                                cursor: "pointer",
                              }}
                            >
                              <i className="fa fa-file" aria-hidden="true"></i>
                            </h3>
                          </div>
                        )}

                        <div className="col-6 col-sm-6 col-md-6 header_button_flex">
                          <div className="head_btn_flex">
                            <div className="head_btn_flex_headings">
                              <h5 className="drisk-name">{data.name}</h5>
                              <h6 className="drisk-date">
                                {moment(data.lastmod).format("LL")}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 col-sm-2 col-md-2 header_button_flex mr-t10">
                          <div className="head_btn_flex">
                            <div className="head_btn_flex_headings">
                              <h6 className="drisk-date">
                                {bytes.format(data.size, {
                                  unitSeparator: " ",
                                })}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-1 col-sm-1 col-md-1"></div>
                        {/* {cdata.name)} */}
                        <div className="col-1 col-sm-1 col-md-1">
                          <label
                            style={{
                              color: "#6BC4E2",
                              cursor: "pointer",
                            }}
                          >
                            {data.type === "file" ? (
                              <div>
                                <i
                                  className="fa fa-ellipsis-v"
                                  aria-hidden="true"
                                  aria-owns={
                                    anchorEl ? "simple-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  onClick={(e) => this.handleClick(e, data)}
                                  key={data.fileid}
                                />
                                <Menu
                                  style={{ marginTop: 45 }}
                                  id="simple-menu"
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={this.handleClose}
                                >
                                  <MenuItem
                                    onClick={() =>
                                      this.handleOpen(data.filename, "Copy")
                                    }
                                  >
                                    Copy to cloud
                                  </MenuItem>
                                </Menu>
                              </div>
                            ) : null}
                          </label>
                        </div>
                      </li>
                    ))
                  ) : (
                    <div className="text-center registered-title mr-b20 mr-t20">
                      <h6
                        style={{
                          color: "#C12A37",
                          fontSize: "15px",
                          display: "block !important",
                          width: "750px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        No Data Available
                      </h6>
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FileSharing;
