import React, { Component } from "react";
import ToggleSideNav from "../UI/ToggleSideNav/ToggleSideNav";
import settingsMethods from "../../services/settingsApi";
import CheckBox from "../UI/CheckBox/CheckBox";
import Modal from "@material-ui/core/Modal";
import userMethods from "../../services/userApi";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import WifiIcon from "@material-ui/icons/Wifi";
import CustomToolTip from "../Common/CustomToolTip";
import {
  blockedDevices,
  networkInfo,
  security_info_info_txt,
  settingsdevice_info_txt,
} from "../VulnerabilityScan/messages";
import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Settings extends Component {
  state = {
    connect_pref: "",
    disconnectNetwork: false,
    responseMessage: "Scanning for networks..",
    wifi_profile: "",
    getSettings: [],
    device_id: JSON.parse(window.sessionStorage.getItem("ConnectedDevice"))
      ?.device_id,
    role_id: JSON.parse(window.sessionStorage.getItem("ConnectedDevice"))
      ?.role_id,
    showLogout: false,
    settings: [
      {
        id: 1,
        value: "mobile_data",
        name: "Mobile Data",
        isChecked: false,
        isDisabled: false,
      },
      // {
      //   id: 2,
      //   value: "gps_tracking",
      //   name: "GPS Tracking",
      //   isChecked: false,
      // },
      {
        id: 3,
        value: "wifi_service",
        name: "Wi-Fi Services",
        isChecked: false,
        isDisabled: false,
      },

      {
        id: 4,
        value: "ethernet_host",
        name: "Network Tethering",
        isChecked: false,
        isDisabled: false,
      },
      {
        id: 5,
        value: "wifi_mode",
        name: "Wi-Fi Mode",
        isChecked: false,
        isDisabled: false,
      },
      // {
      //   id: 4,
      //   value: "wifi_name_ssid",
      //   name: "Wi-Fi Name (SSID)",
      //   isChecked: false,
      // },
      // { id: 5, value: "sim_pin_lock", name: "Sim Pin-lock", isChecked: false },
    ],
    devices: [
      {
        id: 6,
        value: "notification",
        name: "Notification",
        isChecked: false,
        isDisabled: false,
      },
      {
        id: 7,
        value: "remote_mgt",
        name: "Remote Management",
        isChecked: false,
        isDisabled: false,
      },
      {
        id: 8,
        value: "software_update",
        name: "Software Update",
        isChecked: false,
        isDisabled: false,
      },
    ],
    securities: [
      {
        id: 9,
        value: "protect_mode",
        name: "Protect Mode",
        isChecked: false,
        isDisabled: false,
      },
      {
        id: 10,
        value: "monitoring_mode",
        name: "Monitoring Mode",
        isChecked: false,
        isDisabled: false,
      },
      {
        id: 11,
        value: "ghost_mode",
        name: "Ghost Mode",
        isChecked: false,
        isDisabled: false,
      },
      {
        id: 12,
        value: "auto_block",
        name: "Auto Block",
        isChecked: false,
        isDisabled: false,
      },
      // {
      //   id: 12,
      //   value: "encryption_mode",
      //   name: "Encryption Mode",
      //   isChecked: false,
      // },
      // { id: 13, value: "auto_vpn", name: "Auto VPN", isChecked: false },
    ],
    availableDevice: [],
    type: "",
    keyValue: 0,
    setting_id: 0,
    wifi_name_ssid: "",
    bssid: "",
    editModal: false,
    wifi_mode: false,
    ethernet_host: false,
    modalTitle: "SSID",
    modalLabel: "Enter new SSID",
    errorModal: "",
    updateType: "SSID",
    password: "",
    changePassword: "",
    modalPasswordLabel: "Enter confirm password",
    connectedDevices: [],
    open: false,
    message: "",
    networks: [
      {
        id: 11,
        value: "ghost_mode",
        name: "Ghost Mode",
        isChecked: false,
        isDisabled: false,
        status: "Disconnect",
      },
      {
        id: 12,
        value: "ghost_mode",
        name: "Network",
        isChecked: false,
        isDisabled: false,
        status: "Connect",
      },
    ],
  };

  async componentDidMount() {
    this.fetchAllDriskDevices();
    this.getAvailableWiFiNetworks();
    const response = await settingsMethods.getSettings({
      device_id: this.state.device_id,
    });
    //
    if (response.data.statusCode === 200) {
      this.setState({
        getSettings: response.data.result[0],
        setting_id: response.data.result[0].setting_id,
      });

      this.setValues();
    }
  }

  fetchAllDriskDevices = async () => {
    const response = await userMethods.getAllDriskDevices();
    var connectedArray = [];

    if (response.data.statusCode === 200) {
      var result = response.data.result.device_details;
      result.forEach((element) => {
        if (element.is_connected === "connected") {
          connectedArray.push(element);
        }
      });
      {
        connectedArray[0] != null
          ? this.setState({
            connectedDevices: connectedArray[0].name,
          })
          : this.setState({
            connectedDevices: "",
          });
      }
      // console.log(this.state.connectedDevices);
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message != "Session expires. Please login again"
    ) {
      this.setState({
        open: true,
        message: response.data.message,
        loader: false,
      });
    }
  };
  getAvailableWiFiNetworks = async () => {
    const response = await userMethods.getAvailableWiFiNetworks(
      this.state.device_id
    );

    if (response.data.statusCode === 200) {
      this.setState({ availableDevice: response.data.result, loader: false });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message != "Session expires. Please login again"
    ) {
      this.setState({
        open: true,
        message: response.data.message,
        loader: false,
      });
    }
    this.setState({
      loader: false,
      responseMessage: response.data.message
        ? response.data.message
        : "Scanning for networks..",
    });
  };

  logoutUser = () => {
    window.sessionStorage.clear();
    this.props.history.replace({
      pathname: "/",
    });
  };

  async setValues() {
    let getSettings = this.state.getSettings;
    let settings = this.state.settings;
    let devices = this.state.devices;
    let securities = this.state.securities;
    let role_id = JSON.parse(
      window.sessionStorage.getItem("ConnectedDevice")
    ).role_id;

    Object.keys(getSettings).forEach((key) => {
      settings.forEach((setting) => {
        if (setting.value === key) {
          setting.isChecked = getSettings[key] === 0 ? false : true;
        }
        setting.isDisabled = role_id === 2 ? true : false;
      });

      devices.forEach((device) => {
        if (device.value === key) {
          device.isChecked = getSettings[key] === 0 ? false : true;
        }
        if (device.id !== 6) {
          device.isDisabled = role_id === 2 ? true : false;
        }
      });

      securities.forEach((security) => {
        if (security.value === key) {
          security.isChecked = getSettings[key] === 0 ? false : true;
        }
        security.isDisabled = role_id === 2 ? true : false;
      });
    });

    //
    this.setState({
      connect_pref: this.state.getSettings.connect_pref,
      wifi_profile: this.state.getSettings.wifi_profile,
      wifi_name_ssid: this.state.getSettings.wifi_name_ssid,
      settings: settings,
      devices: devices,
      securities: securities,
    });
    //
  }

  handleCheckChildElement = async (event) => {
    let settings = this.state.settings;
    let devices = this.state.devices;
    let securities = this.state.securities;
    //
    //
    // console.log(event.target.name, event.target.checked);
    if (
      (event.target.value === "ethernet_host" ||
        event.target.value === "wifi_mode") &&
      event.target.checked === true
    ) {
      if (event.target.value === "ethernet_host")
        this.setState({ ethernet_host: true });
      if (event.target.value === "wifi_mode")
        this.setState({ wifi_mode: true });
      return 0;
    }
    settings.forEach((setting) => {
      if (setting.value === event.target.value)
        setting.isChecked = event.target.checked;
    });

    devices.forEach((device) => {
      if (device.value === event.target.value)
        device.isChecked = event.target.checked;
    });

    securities.forEach((security) => {
      if (security.value === event.target.value)
        security.isChecked = event.target.checked;
    });
    if (event.target.value === "protect_mode") {
      securities[0].isChecked = event.target.checked;
      securities[1].isChecked = !event.target.checked;
    }
    if (event.target.value === "monitoring_mode") {
      securities[1].isChecked = event.target.checked;
      securities[0].isChecked = !event.target.checked;
    }

    await this.setState({
      settings: settings,
      devices: devices,
      securities: securities,
      type: event.target.value,
      keyValue: event.target.checked ? 1 : 0,
    });

    this.updateSettingData();
  };

  handleConnPrefChange = async (event) => {
    await this.setState({
      connect_pref: event.target.value,
      type: "connect_pref",
      keyValue: event.target.value,
    });

    this.updateSettingData();
  };

  handleWifiProfileChange = async (event) => {
    await this.setState({
      wifi_profile: event.target.value,
      type: "wifi_profile",
      keyValue: event.target.value,
    });

    this.updateSettingData();
  };

  updateSettingData() {
    const combinedObject = [
      ...this.state.settings,
      ...this.state.devices,
      ...this.state.securities,
    ];

    var updatedData = {
      setting_id: this.state.getSettings.setting_id,
      device_id: this.state.device_id,
      connect_pref: this.state.connect_pref,
      wifi_profile: this.state.wifi_profile,
    };

    combinedObject.forEach((element) => {
      updatedData[element.value] = element.isChecked === false ? 0 : 1;
    });

    this.updateSettings();
  }

  async updateSettings() {
    var updatedData = {
      setting_id: this.state.setting_id,
      device_id: this.state.device_id,
      type: this.state.type,
      [this.state.type]: this.state.keyValue,
    };

    const response = await settingsMethods.updateSettings(updatedData);
    //
    if (response.data.statusCode === 200) {
      toast.success(response.data.message);
      this.setState({
        getSettings: response.data.result[0],
        setting_id: response.data.result[0].setting_id,
      });
      if (response.data.result[0].remote_mgt === 0) {
        this.setState({ open: true, message: response.data.message });
      }
      this.setValues();
    } else {
      toast.error(response.data.message);
    }
  }
  async updateSettingsNetwork(data) {
    var updatedData =
      data == "ethernet_host"
        ? {
          setting_id: this.state.setting_id,
          device_id: this.state.device_id,
          type: "ethernet_host",
          ethernet_host: 1,
        }
        : {
          setting_id: this.state.setting_id,
          device_id: this.state.device_id,
          type: "wifi_mode",
          wifi_mode: 1,
        };

    if (data === "wifi_mode") {
      this.getAvailableWiFiNetworks();
    }

    const response = await settingsMethods.updateSettings(updatedData);
    //
    if (response.data.statusCode === 200) {
      toast.success(response.data.message);
      this.setState({
        getSettings: response.data.result[0],
        setting_id: response.data.result[0].setting_id,
      });
      if (response.data.result[0].remote_mgt === 0) {
        this.setState({ open: true, message: response.data.message });
      }
      this.setValues();
      const tempResponse = await settingsMethods.getSettings({
        device_id: this.state.device_id,
      });
      //
      if (tempResponse.data.statusCode === 200) {
        this.setState({
          getSettings: tempResponse.data.result[0],
          setting_id: tempResponse.data.result[0].setting_id,
        });

        this.setValues();
      }
    } else {
      toast.error(response.data.message);
    }
  }
  async connectToNetwork(ssid, password, device_id, bssid) {
    // console.log("checking ", ssid);
    const payload = {
      ssid: ssid,
      password: password,
      device_id: device_id,
      bssid: bssid,
    };

    const response = await settingsMethods.connectWiFiClient(payload);
    //
    if (response.data.statusCode === 200) {
      // this.setState({
      //   password: "",
      // });
      this.getAvailableWiFiNetworks();
      toast.success(response.data.message);
      this.handleClose();

    } else {
      toast.error(response.data.message);
    }
  }
  async disconnectToNetwork() {
    const response = await settingsMethods.disconnectWiFiClient(
      this.state.device_id
    );
    //
    if (response.data.statusCode === 200) {
      toast.success(response.data.message);
      this.getAvailableWiFiNetworks();
      // this.handleClose();
    } else {
      toast.error(response.data.message);
    }
  }

  handleOpen = (type) => {
    // console.log(type);
    this.setState({
      editModal: true,
      updateType: type,
      errorModal: "",
    });

    if (type === "SSID") {
      this.setState({
        modalTitle: "SSID",
        modalLabel: "Enter new SSID",
      });
    }

    if (type === "PASSWORD") {
      //  Set Password
      this.setState({
        modalTitle: "Password",
        modalLabel: "Enter new password",
      });
    }
    if (type === "CONNECT") {
      this.setState({
        modalTitle: "CONNECT TO NETWORK",
        modalLabel: "Network",
      });
    }
  };

  handleClose = () => {
    this.setState({ editModal: false });
    this.setState({ password: "" });
};

  handleUpdateValue = async (event) => {
    await this.setState({
      [event.target.name]: event.target.value,
    });

    if (this.state.updateType === "PASSWORD") {
      if (this.state.password !== this.state.changePassword) {
        this.setState({
          errorModal: "Password and Confirm Password should be same",
        });
      } else {
        this.setState({
          errorModal: "",
        });
      }
    }
  };

  updateTextSettingData = async () => {
    if (this.state.updateType === "SSID") {
      if (this.state.wifi_name_ssid === "") {
        this.setState({
          errorModal: "Please enter the SSID",
        });
      } else {
        this.setState({
          errorModal: "",
          loader: true,
        });

        var updateObject = {
          device_id: this.state.device_id,
          type: "wifi_name_ssid",
          value: this.state.wifi_name_ssid,
        };

        const response = await settingsMethods.updateSSID(updateObject);

        if (response.data.statusCode === 200) {
          this.updateSettingData();

          this.resetValues();
        }
      }
    }

    if (this.state.updateType === "PASSWORD") {
      if (this.state.password === "" || this.state.changePassword === "") {
        this.setState({
          errorModal: "Please enter the password",
          password: "",
          changePassword: "",
        });
      } else if (
        this.state.password.length < 10 ||
        this.state.changePassword.length < 10
      ) {
        this.setState({
          errorModal: "Minimum 10 characters require to set password",
          password: "",
          changePassword: "",
        });
      } else {
        this.setState({
          errorModal: "",
          loader: true,
        });

        updateObject = {
          device_id: this.state.device_id,
          type: "wifi_password",
          value: this.state.changePassword,
        };

        const response = await settingsMethods.updateSSID(updateObject);

        if (response.data.statusCode === 200) {
          this.updateSettingData();
          this.resetValues();
        }
      }
    }
  };

  togglePassword() {
    var pssInput = document.getElementById("toggle");
    var passIcon = document.getElementById("toggleIcon");

    if (pssInput.type === "password") {
      pssInput.type = "text";
      passIcon.className = "fa fa-eye";
    } else {
      pssInput.type = "password";
      passIcon.className = "fa fa-eye-slash";
    }
  }

  resetValues = () => {
    this.setState({
      editModal: false,
      modalTitle: "SSID",
      modalLabel: "Enter new SSID",
      errorModal: "",
      updateType: "SSID",
      password: "",
      changePassword: "",
      modalPasswordLabel: "Enter confirm password",
      loader: false,
    });
  };

  render() {
    return (
      <div className="routing-main-wrap">
        <div className="page-head">
          <Snackbar
            open={this.state.open}
            autoHideDuration={3000}
            onClose={() => this.setState({ open: false })}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert
              // onClose={handleClose}
              severity="info"
              sx={{ width: "100%" }}
            >
              {this.state.message}
            </Alert>
          </Snackbar>
          <h3>Settings</h3>
        {this.state.connectedDevices != null ? (
            <h3>{this.state.connectedDevices}</h3>
) : null}

          <ToggleSideNav batteryRefresh="false" />
        </div>
        {this.state.connectedDevices.length > 0 ? (
          <div className="rout-body">
            <div className="row">
              <div className="col-sm-12">
                <div className="settings-device-details">
                  <div className="black-card settings-card mr-b20">
                    <div className="d-d-card-head">
                      <h4>Network</h4>
                      {/* <div className="row flex-row-reverse mr-2 h-10"> */}
                      <CustomToolTip message={networkInfo} />
                      {/* </div> */}
                    </div>
                    <div className="device-info-wrap mr-t10">
                      <div className="row">
                        {this.state.settings.map((setting) => {
                          return (
                            <CheckBox
                              key={setting.id}
                              onChange={this.handleCheckChildElement}
                              {...setting}
                            />
                          );
                        })}

                        <div className="col-6 col-sm-auto">
                          <div className="d-info-card">
                            <label>Wi-Fi Profile</label>
                            <div
                              className="cus-select"
                              style={{ display: "flex" }}
                            >
                              {this.state.role_id !== 2 ? (
                                <select
                                  name="wifi_profile"
                                  onChange={(e) =>
                                    this.handleWifiProfileChange(e)
                                  }
                                  value={this.state.wifi_profile}
                                  style={{
                                    display: "flex",
                                    minWidth: "80px",
                                    padding: "0px 3px",
                                  }}
                                >
                                  <option value="2.4 GH" name="2.4 GH" id="4">
                                    2.4 GHz
                                  </option>
                                  <option value="5 GH" name="5 GH" id="5">
                                    5 GHz
                                  </option>
                                </select>
                              ) : (
                                <text
                                  style={{
                                    color: "white",
                                  }}
                                >
                                  {this.state.wifi_profile} GHz
                                </text>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-6 col-sm-auto">
                          <div className="d-info-card">
                            <label>Wi-Fi Name (SSID)</label>
                            <div className="cus-input">
                              <h6
                                name="wifi_name_ssid"
                                value={this.state.wifi_name_ssid}
                                className="cus-wifi-name mr-5"
                              >
                                {this.state.getSettings.wifi_name_ssid}
                              </h6>
                              {this.state.role_id !== 2 ? (
                                <svg
                                  width="15"
                                  height="15"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  onClick={() => this.handleOpen("SSID")}
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M20 18V11H18V18H2V2H9V0H2C0.89543 0 0 0.89543 0 2V18C0 19.1046 0.89543 20 2 20H18C19.1046 20 20 19.1046 20 18ZM17.1781 0.723423C16.7197 0.26142 16.0921 0 15.4374 0C14.7834 0 14.1564 0.26083 13.6954 0.724628L5.3265 9.09342C4.57867 9.75233 4.08844 10.7328 4.00325 11.7873L4 15.0023V16.0023H8.1346C9.2689 15.9245 10.259 15.4295 10.9575 14.6238L19.279 6.30584C19.7407 5.84416 20.0001 5.21799 20.0001 4.56508C20.0001 3.91217 19.7407 3.286 19.279 2.82432L17.1781 0.723423ZM8.06398 14.0048C8.59821 13.967 9.09549 13.7184 9.49479 13.2616L15.5567 7.19972L12.8024 4.44527L6.6961 10.5496C6.29095 10.9079 6.04031 11.4092 6 11.8678V14.0029L8.06398 14.0048ZM14.2169 3.03128L16.9709 5.78551L17.8648 4.89162C17.9514 4.80502 18.0001 4.68756 18.0001 4.56508C18.0001 4.4426 17.9514 4.32514 17.8648 4.23854L15.7611 2.13486C15.6755 2.04855 15.5589 2 15.4374 2C15.3158 2 15.1992 2.04855 15.1136 2.13486L14.2169 3.03128Z"
                                    fill="#c12a37"
                                  />
                                </svg>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-6 col-sm-auto">
                        <div className="d-info-card">
                          <label>Connection Preference</label>
                          <div className="cus-select">
                            <select
                              name="connect_pref"
                              value={this.state.connect_pref}
                              onChange={(e) => this.handleConnPrefChange(e)}
                              style={{
                                display: "flex",
                                minWidth: "110px",
                                padding: "0px 3px",
                              }}
                            >
                              <option
                                value="Auto Connect"
                                name="Auto Connect"
                                id="1"
                              >
                                Auto Connect
                              </option>
                              <option value="Manual" id="2" name="Manual">
                                Manual
                              </option>
                            </select>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                    {this.state.settings[3]?.isChecked ? (
                      <div>
                        <div className="device-info-wrap ">
                          <div className="row">
                            <div className="col-1 col-md-1 ">
                              <div className="d-info-card">
                                <label>Network</label>
                              </div>
                            </div>
                            <div className="col-6 col-md-7 pd-l50">
                              <div className="d-info-card">
                                <label>Network Name</label>
                              </div>
                            </div>
                            <div className="col-3 col-md-3 ">
                              <div className="d-info-card">
                                <label>
                                  <h4>Network Status</h4>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {this.state.availableDevice?.length ? (
                          this.state.availableDevice.map((data, index) => (
                            <div key={index} className="device-info-wrap ">
                              <div className="row">
                                <div className="col-1 col-md-1 ">
                                  <div className="d-info-card">
                                    <WifiIcon />
                                  </div>
                                </div>
                                <div className="col-6 col-md-7 pd-l50">
                                  <div className="d-info-card">
                                    <label>
                                      <h4 style={{ color: "#6BC4E2" }}>
                                        {data.ssid}
                                      </h4>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3 col-md-3 ">
                                  <div className="d-info-card">
                                    {data.connected_status == "0" ? (
                                      <label
                                        className="cursor"
                                        onClick={() => {
                                          this.handleOpen("CONNECT");
                                          this.setState({
                                            wifi_name_ssid: data.ssid,
                                            bssid: data.bssid,
                                          });
                                        }}
                                      >
                                        <h4 style={{ color: "#6BC4E2" }}>
                                          Connect
                                        </h4>
                                      </label>
                                    ) : (
                                      <label
                                        className="cursor"
                                        onClick={() =>
                                          // this.disconnectToNetwork()
                                          this.setState({
                                            disconnectNetwork: true,
                                          })
                                        }
                                      >
                                        <h4 style={{ color: "#c12a37" }}>
                                          Connected
                                        </h4>
                                      </label>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : this.state.loader ? (
                          <CircularProgress
                            color="secondary"
                            style={{ margin: "0% 0% 0% 50%" }}
                          />
                        ) : (
                          <div className="col-12 col-md-12  text-center">
                            <div className="d-info-card">
                              <label>{this.state.responseMessage}</label>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div className="black-card settings-card mr-b20">
                    <div className="d-d-card-head">
                      <h4>Device</h4>
                      <CustomToolTip message={settingsdevice_info_txt} />
                    </div>
                    <div className="device-info-wrap mr-t10">
                      <div className="row">
                        {this.state.devices.map((device) => {
                          return (
                            <CheckBox
                              key={device.id}
                              onChange={this.handleCheckChildElement}
                              {...device}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="black-card settings-card mr-b20">
                    <div className="d-d-card-head">
                      <h4>Security</h4>
                      <CustomToolTip message={security_info_info_txt} />
                    </div>
                    <div className="device-info-wrap mr-t10">
                      <div className="row">
                        {this.state.securities.map((security) => {
                          return (
                            <CheckBox
                              key={security.id}
                              onChange={this.handleCheckChildElement}
                              {...security}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {/* <div className="black-card settings-card mr-b20">

                  <div className="b-card-link">
                    <a href="/">
                      Change dashboard password
                      <svg
                        width="23"
                        height="24"
                        viewBox="0 0 23 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.8189 12L6.90942 4.70711L8.24929 3.29289L16.4986 12L8.24929 20.7071L6.90942 19.2929L13.8189 12Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </div>
                </div> */}

                  {this.state.role_id !== 2 ? (
                    <div
                      className="black-card settings-card"
                      onClick={() => this.handleOpen("PASSWORD")}
                    >
                      <div className="b-card-link b-card-link-settings">
                        <a>
                          <div className="d-d-card-head">
                            <h4> Change Wi-Fi Password</h4>
                          </div>
                          <svg
                            width="23"
                            height="24"
                            viewBox="0 0 23 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M13.8189 12L6.90942 4.70711L8.24929 3.29289L16.4986 12L8.24929 20.7071L6.90942 19.2929L13.8189 12Z"
                              fill="white"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              color: "#BC2835",
            }}
          >
            No device connected
          </div>
        )}

        {this.state.editModal ? (
          <Modal
            open={this.state.editModal}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    {this.state.modalTitle}
                  </h5>
                </div>

                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body">
                    <div className="fp_label">
                      <h6>
                        {this.state.modalLabel}
                        <em className="mandatory">* </em>
                      </h6>
                      {this.state.updateType === "CONNECT" ? (
                        <div>
                          <input
                            type="text"
                            className="fp_modal_input"
                            value={this.state.wifi_name_ssid}
                            name="wifi_name_ssid"
                            onChange={this.handleUpdateValue}
                            disabled={true}
                          />
                          <h6 className="mt-3">
                            Enter Password
                            <em className="mandatory">* </em>
                          </h6>
                          {/* </div> */}
                        </div>
                      ) : null}
                      {this.state.updateType === "SSID" ? (
                        <input
                          type="text"
                          className="fp_modal_input"
                          value={this.state.wifi_name_ssid}
                          name="wifi_name_ssid"
                          onChange={this.handleUpdateValue}
                        />
                      ) : (
                        <input
                          type="password"
                          className="fp_modal_input"
                          name="password"
                          value={this.state.password}
                          onChange={this.handleUpdateValue}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body">
                    <div className="fp_label">
                      {this.state.updateType === "PASSWORD" ? (
                        <div>
                          <div className="mr-t20">
                            <h6>
                              {this.state.modalPasswordLabel}
                              <em className="mandatory">* </em>
                            </h6>
                            <input
                              id="toggle"
                              type="password"
                              className="fp_modal_input"
                              name="changePassword"
                              value={this.state.changePassword}
                              onChange={this.handleUpdateValue}
                            />
                            <a onClick={this.togglePassword}>
                              <i
                                className="fa fa-eye-slash"
                                id="toggleIcon"
                              ></i>
                            </a>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="color-red">
                      <i>{this.state.errorModal}</i>
                    </div>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  {this.state.updateType === "CONNECT" ? (
                    <button
                      type="button"
                      className="btn-fp-footer btn-Reset"
                      onClick={() =>
                        this.connectToNetwork(
                          this.state.wifi_name_ssid,
                          this.state.password,
                          this.state.device_id,
                          this.state.bssid,
                        )
                      }
                      disabled={this.state.loader}
                    >
                      Connect
                      {this.state.loader ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : null}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn-fp-footer btn-Reset"
                      onClick={this.updateTextSettingData}
                      disabled={this.state.loader}
                    >
                      Update
                      {this.state.loader ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : null}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
        <Modal
          open={this.state.disconnectNetwork}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
            role="document"
          >
            <div className="modal-content modal_fp_content">
              <div className="modal-header forget_pw_header">
                <button
                  type="button"
                  className="close modal_fp_close_btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ disconnectNetwork: false })}
                >
                  <span aria-hidden="true">
                    <svg
                      width="10"
                      height="18"
                      viewBox="0 0 10 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </button>
                <h5
                  className="modal-title modal_fp_title "
                  id="exampleModalLabel"
                >
                  Disconnect Device
                </h5>
              </div>

              <div className="modal-body fp_modal_body">
                <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                  <h6 className="delete-dialog">
                    Are you sure you want to disconnect this device?
                    <br />
                    <br />
                    <i>Note: This action cannot be undone!</i>
                  </h6>
                </div>
              </div>
              <div className="modal-footer footer_fp_modal">
                <button
                  type="button"
                  className="btn-fp-footer btn-Reset"
                  onClick={() => {
                    this.setState({ disconnectNetwork: false });
                    this.disconnectToNetwork();
                  }}
                >
                  Disconnect
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.wifi_mode}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
            role="document"
          >
            <div className="modal-content modal_fp_content">
              <div className="modal-header forget_pw_header">
                <button
                  type="button"
                  className="close modal_fp_close_btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ wifi_mode: false })}
                >
                  <span aria-hidden="true">
                    <svg
                      width="10"
                      height="18"
                      viewBox="0 0 10 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </button>
                <h5
                  className="modal-title modal_fp_title "
                  id="exampleModalLabel"
                >
                  Switch Networks?
                </h5>
              </div>

              <div className="modal-body fp_modal_body">
                <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                  <h6 className="delete-dialog">
                    Are you sure you want to switch to WiFi mode?
                    <br />
                    <br />
                    <i>
                      {" "}
                      Note: This action will automatically turn off the network
                      tethering mode, To avoid any network interruption!
                    </i>
                  </h6>
                </div>
              </div>
              <div className="modal-footer footer_fp_modal">
                <button
                  type="button"
                  className="btn-fp-footer btn-Reset"
                  onClick={() => {
                    this.setState({ wifi_mode: false });
                    this.updateSettingsNetwork("wifi_mode");
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.ethernet_host}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
            role="document"
          >
            <div className="modal-content modal_fp_content">
              <div className="modal-header forget_pw_header">
                <button
                  type="button"
                  className="close modal_fp_close_btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ ethernet_host: false })}
                >
                  <span aria-hidden="true">
                    <svg
                      width="10"
                      height="18"
                      viewBox="0 0 10 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </button>
                <h5
                  className="modal-title modal_fp_title "
                  id="exampleModalLabel"
                >
                  Switch Networks?
                </h5>
              </div>

              <div className="modal-body fp_modal_body">
                <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                  <h6 className="delete-dialog">
                    Are you sure you want to switch to Network Tethering mode?
                    <br />
                    <br />
                    <i>
                      Note: This action will automatically change the Wi-Fi mode
                      from client to Access Point, To avoid any network
                      interruption!
                    </i>
                  </h6>
                </div>
              </div>
              <div className="modal-footer footer_fp_modal">
                <button
                  type="button"
                  className="btn-fp-footer btn-Reset"
                  onClick={() => {
                    this.setState({ ethernet_host: false });
                    this.updateSettingsNetwork("ethernet_host");
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {this.state.showLogout ? (
          <Modal
            open={this.state.showLogout}
            aria-labelledby="DeleteUserProfile"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Session Expired
                  </h5>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">
                      Please login again.
                      <br />
                      <br />
                    </h6>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.logoutUser}
                  // disabled={this.state.loader}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default Settings;
