import React, { Component } from "react";
import "./register.css";
import BackButton from "../../components/UI/BackButton/BackButton";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Moment from "@date-io/moment";
import moment from "moment";
import userMethods from "../../services/userApi";
import Modal from "@material-ui/core/Modal";
import { withRouter } from "react-router-dom";

class Register extends Component {
  state = {
    first_name: "",
    last_name: "",
    phone: "",
    errorFirstName: "",
    // dob: moment(),
    dob: moment(new Date()).format("MM/DD/YYYY"),
    email: "",
    password: "",
    confirmPassword: "",
    errorName: "",
    alertName: false,
    errorPhone: "",
    alertPhone: false,
    errorEmail: "",
    alertEmail: false,
    isEmailValid: false,
    errorPassword: "",
    alertPassword: "",
    loader: false,
    showRegister: false,
    title: "",
    success: "",
    statusCode: "",
    userType: "individual",
  };

  handleFormValues = async (event) => {
    await this.setState({ [event.target.name]: event.target.value });

    if (event.target.name === "email") {
      // Checks if email is valid or not
      this.setState({ isEmailValid: this.validateEmail(this.state.email) });

      if (!this.state.isEmailValid) {
        this.setState({
          errorEmail: "Please enter valid email",
          alertEmail: true,
        });
      } else {
        this.setState({ alertEmail: false, errorEmail: "" });
      }
    }
  };

  handleDateOfBirth = (date) => {
    this.setState({
      dob: moment(date).format("MM/DD/yyyy"),
    });
  };

  registerUser = async () => {
    if (this.state.first_name === "") {
      this.setState({
        alertName: true,
        errorFirstName: "first name field required",
        loader: false,
      });
    } else {
      this.setState({
        alertName: false,
      });
    }

    if (this.isValidPhoneNumber(this.state.phone)) {
      if (this.state.phone?.includes("-"))
        if (this.state.phone.length !== 12) {
          this.setState({
            alertPhone: true,
            phone: "",
            errorPhone: "Please put 10 digit phone number",
            loader: false,
          });
        } else {
          this.setState({
            alertPhone: false,
          });
        }
      else {
        if (this.state.phone.length !== 10) {
          this.setState({
            alertPhone: true,
            phone: "",
            errorPhone: "Please put 10 digit phone number",
            loader: false,
          });
        } else {
          this.setState({
            alertPhone: false,
          });
        }
      }
    } else {
      this.setState({
        alertPhone: true,
        phone: "",
        errorPhone: "Please put valid phone number",
        loader: false,
      });
    }

    if (this.state.email === "") {
      this.setState({
        alertEmail: true,
        errorEmail: "Email field required",
        loader: false,
      });
    } else {
      this.setState({
        alertEmail: false,
      });
    }

    if (this.state.confirmPassword === "") {
      this.setState({
        alertPassword: true,
        errorPassword: "Please confirm your password",
        loader: false,
      });
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        alertPassword: true,
        errorPassword: "Password and confirm password should be same",
        loader: false,
      });
    } else {
      this.setState({
        alertPassword: false,
      });
    }

    if (
      this.state.first_name !== "" &&
      (this.state.phone.length === 10 || this.state.phone.length === 12) &&
      this.state.email !== "" &&
      this.state.confirmPassword !== "" &&
      this.state.password === this.state.confirmPassword
    ) {
      this.setState({ loader: true });

      var registerObject = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        password: this.state.confirmPassword,
        email_address: this.state.email,
        phone_number: this.state.phone,
        dob: this.state.dob,
        user_type: this.state.userType,
        loginType: "eRisk",
        comapny_name: "",
        device_id: "",
        firebase_token:
          "dablNdbySFuPblulmPAE8d:APA91bHE_BpZDjYSSxX1ii15cCFtUGUEUTg21HA8dfac0EOtTiyeO0uvNMdQPT_mtCU6bT7pZ1cd_fVJPnFJChQXtpV_hcdrv0spgSFei6q4OMRkvVRTMpDcOvSmCjiawJjYgDoQ0YdL",
      };
      const response = await userMethods.userRegister(registerObject);

      // userMethods
      //   .userRegister(registerObject)
      //   .then((response) => {
      //     console.log(response.data);
      //     this.setState({
      //       loader: false,
      //       showRegister: true,
      //       title: "Success",
      //       success: "User Registered Successfully!",
      //     });
      //   })
      //   .then((error) => {
      //     // console.log(error);
      //     this.setState({
      //       loader: false,
      //       showRegister: true,
      //       title: "Error",
      //       success: "Email Address or mobile number already exists!",
      //     });
      //   });

      console.log(registerObject);
      if (response.data.statusCode === 200) {
        this.setState({
          loader: false,
          showRegister: true,
          title: "Success",
          statusCode: "200",
          success: "User Registered Successfully!",
        });

        this.resetForm();
      } else if (
        response.data.message ===
        "Email Address or mobile number already exists"
      ) {
        this.setState({
          loader: false,
          showRegister: true,
          title: "Error",
          success: "Email Address or mobile number already exists!",
        });
      }

      this.setState({
        loader: false,
      });
    }
  };

  validateEmail(email) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    return pattern.test(email);
  }

  togglePassword() {
    var pssInput = document.getElementById("toggle");
    var passIcon = document.getElementById("toggleIcon");

    if (pssInput.type === "password") {
      pssInput.type = "text";
      passIcon.className = "fa fa-eye";
    } else {
      pssInput.type = "password";
      passIcon.className = "fa fa-eye-slash";
    }
  }

  handleClose = () => {
    this.setState({
      showRegister: false,
    });
    if (this.state.statusCode == 200) this.props.history.push("/");
  };

  changeUserType = (event) => {
    console.log(event.target.value);
    this.setState({
      userType: event.target.value,
    });
  };

  resetForm = () => {
    this.setState({
      first_name: "",
      last_name: "",
      phone: "",
      dob: moment(),
      email: "",
      password: "",
      confirmPassword: "",
      errorFirstName: "",
      alertName: false,
      errorPhone: "",
      alertPhone: false,
      errorEmail: "",
      alertEmail: false,
      isEmailValid: false,
      errorPassword: "",
      alertPassword: "",
    });
  };

  isValidPhoneNumber = (phoneNumber) => {
    // Regex pattern to match phone number in formats 987-213-2134 or 0987654321
    const regexPattern = /^(?:\d{3}-\d{3}-\d{4}|\d{10})$/;
    return regexPattern.test(phoneNumber);
  };
  render() {
    return (
      <div className="routing-main-wrap-register">
        <div className="page-head">
          <div className="page_head_btn w-100">
            <div className="aboutus-flex d-flex w-100 align-items-center">
              <BackButton getPath="/" count={0} />

              <h3>Register</h3>
            </div>
          </div>
        </div>
        <div className="rout-body">
          <div className="row register-content ">
            <div className="col-sm-12 mr-t30">
              <div className="device-details">
                <div className="register_detail_main">
                  <div className="row register_detail_wrap ">
                    <div className="col-12 col-sm-6 col-md-6">
                      <h6>
                        First Name<em className="mandatory">* </em>
                      </h6>
                      <input
                        value={this.state.first_name}
                        name="first_name"
                        type="text"
                        onChange={this.handleFormValues}
                        className="form-control black-input-control"
                        // className="registration_inputs"
                        style={{ cursor: "pointer" }}
                        autoFocus
                        autoComplete="off"
                        required
                      />
                      {this.state.alertName ? (
                        <span className="color-red">
                          <i>{this.state.errorFirstName}</i>
                        </span>
                      ) : null}
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <h6>Last Name</h6>
                      <input
                        value={this.state.last_name}
                        name="last_name"
                        type="text"
                        onChange={this.handleFormValues}
                        className="form-control black-input-control"
                        // className="registration_inputs"
                        style={{ cursor: "pointer" }}
                        autoFocus
                        autoComplete="off"
                        required
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <h6>
                        Phone Number<em className="mandatory">* </em>
                      </h6>
                      <input
                        value={this.state.phone}
                        name="phone"
                        type="text"
                        onChange={this.handleFormValues}
                        className="form-control black-input-control"
                        style={{ cursor: "pointer" }}
                        autoComplete="off"
                        required
                      />
                      {this.state.alertPhone ? (
                        <span className="color-red">
                          <i>{this.state.errorPhone}</i>
                        </span>
                      ) : null}
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <h6>
                        Date of Birth<em className="mandatory">* </em>
                      </h6>
                      <MuiPickersUtilsProvider utils={Moment}>
                        <DatePicker
                          value={this.state.dob}
                          name="dob"
                          onChange={this.handleDateOfBirth}
                          format="MM/DD/yyyy"
                          className="form-control black-input-control text-color"
                          inputVariant="outlined"
                          style={{ color: "black" }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <h6>
                        Email<em className="mandatory">* </em>
                      </h6>
                      <input
                        value={this.state.email}
                        name="email"
                        type="email"
                        onChange={this.handleFormValues}
                        className="form-control black-input-control"
                        style={{ cursor: "pointer" }}
                        autoComplete="off"
                        required
                      />
                      {this.state.alertEmail ? (
                        <span className="color-red">
                          <i>{this.state.errorEmail}</i>
                        </span>
                      ) : null}
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <div className="reg-input">
                        <h6>
                          Password<em className="mandatory">* </em>
                        </h6>
                        <input
                          id="toggle"
                          value={this.state.password}
                          type="password"
                          className="form-control black-input-control"
                          placeholder="Password"
                          name="password"
                          onChange={this.handleFormValues}
                          autoComplete="off"
                          required
                        />
                        {/* <input
                          id="toggle"
                          value={this.state.password}
                          name="password"
                          type="password"
                          onChange={this.handleFormValues}
                          className="registration_inputs"
                          style={{ cursor: "pointer", color: "white" }}
                          autoComplete="off"
                          required
                        /> */}
                        <a href="#" onClick={this.togglePassword}>
                          <i className="fa fa-eye-slash" id="toggleIcon"></i>
                        </a>
                        {/* <a href="#" onClick={this.togglePassword}>
                          <i className="fa fa-eye-slash" id="toggleIcon"></i>
                        </a> */}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <div className="reg-input">
                        <h6>
                          User Type<em className="mandatory">* </em>
                        </h6>
                        <select
                          className="form-control black-input-control"
                          onChange={this.changeUserType}
                        >
                          <option value="individual">{"Individual"}</option>
                          <option value="corporate">{"Corporate"}</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <h6>
                        Confirm Password<em className="mandatory">* </em>
                      </h6>
                      <input
                        value={this.state.confirmPassword}
                        name="confirmPassword"
                        type="password"
                        onChange={this.handleFormValues}
                        className="form-control black-input-control"
                        style={{ cursor: "pointer" }}
                        autoComplete="off"
                        required
                      />
                      {this.state.alertPassword ? (
                        <span className="color-red">
                          <i>{this.state.errorPassword}</i>
                        </span>
                      ) : null}
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 mr-t40">
                      <button
                        type="button"
                        className="registration_btn last_reg_red_btn"
                        onClick={this.registerUser}
                        disabled={this.state.loader}
                      >
                        Register
                        {this.state.loader ? (
                          <i className="fa fa-spinner fa-spin"></i>
                        ) : null}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.showRegister ? (
          <Modal
            open={this.state.showRegister}
            aria-labelledby="showRegister"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    {this.state.title}
                  </h5>
                </div>

                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">{this.state.success}</h6>
                  </div>
                </div>

                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.handleClose}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default withRouter(Register);
