import React, { Component } from "react";
import userMethods from "../../../services/userApi";

class RegisteredUsers extends Component {
  state = {
    registeredUsers: [],
  };

  componentDidMount() {
    this.getRegisteredUsers();
  }

  getRegisteredUsers = async () => {
    const response = await userMethods.registeredUsers();
    // if (response.data.statusCode === 401) {
    //   window.sessionStorage.clear();
    //   this.props.history.replace({
    //     pathname: "/",
    //   });
    // }
    // console.log(response.data.result);
    // {
    //   response.data.result.registered_users
    //     ? await this.setState({
    //         registeredUsers: response.data.result.registered_users,
    //       })
    //     : null;
    // }
    if (response.data.result) {
      this.setState({
        registeredUsers: response.data.result.registered_users,
      });
    } else
      this.setState({
        registeredUsers: null,
      });
    // await this.setState({
    //   registeredUsers: response.data.result.registered_users,
    // });
  };

  render() {
    return (
      <div className="registered-users mr-t20">
        <div className="text-center registered-title">
          <h6> Registered Users </h6>
        </div>

        <div className="registered-user-table text-center table-responsive col-md-12 mr-t20">
          <table className="table table-bordered table-sm">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Status</th>
                {/* <th>Tinode Username</th> */}
              </tr>
            </thead>
            <tbody>
              {this.state?.registeredUsers?.length > 0
                ? this.state.registeredUsers.map((user, index) => (
                    <tr key={index}>
                      <td>{user.first_name}</td>
                      <td>{user.last_name}</td>
                      <td>{user.email_id}</td>
                      <td>{user.phone_number}</td>
                      <td>{user.isRegistered ? "Active" : "In-active"}</td>
                      {/* <td>{user.tinode_username}</td> */}
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default RegisteredUsers;
