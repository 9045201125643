import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@material-ui/core/SvgIcon";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import { useSpring, animated } from "react-spring"; // web.cjs is required for IE 11 support
import WifiIcon from "@material-ui/icons/Wifi";
import userMethods from "../../../services/userApi";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent}></TreeItem>
));

const useStyles = makeStyles({
  root: {
    height: 150,
    flexGrow: 1,
    // maxWidth: 400,
    overflowY: "auto",
  },
});

export default function DeviceTree() {
  const classes = useStyles();
  const [deviceTree, setDeviceTree] = React.useState({});
  const [statusCode, setStatusCode] = React.useState({
    code: 200,
    message: "",
  });
  React.useEffect(async () => {
    const response = await userMethods.registeredUsers();
    // console.log(response.data.result.device_tree);
    // setDeviceTree(response.data.result.device_tree);
    console.log("Stat", response.data.statusCode);
    if (response.data.statusCode == 200) {
      if (response.data.result) {
        setDeviceTree(response.data?.result?.device_tree);
      }
    } else
      setStatusCode({
        code: 401,
        message: response.data.message
          ? response.data.message
          : "Access to Admin panel functionalities requires ownership of the device. please ensure that you are the owner of the currently connected device to proceed",
      });
  }, []);
  console.log(statusCode);
  return (
    <div className="device-tree tree-title col-md-12">
      <h6 className="text-center"> Device Tree </h6>
      {statusCode?.code == 200 ? (
        <TreeView
          className={classes.root}
          defaultExpanded={["1"]}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<CloseSquare />}
        >
          {/* <StyledTreeItem nodeId="1" label="Cervais Inc" color="#c12a37">
          <StyledTreeItem nodeId="2" label="tsalman" name="30" info="3000" />

          <StyledTreeItem nodeId="3" label="jaustin">
            <StyledTreeItem nodeId="6" label="James D-RISK 1" />
            <StyledTreeItem nodeId="7" label="James D-RISK 2" />
          </StyledTreeItem>

          <StyledTreeItem nodeId="4" label="jjohnson">
            <StyledTreeItem nodeId="8" label="Janelin's D-RISK 1" />
            <StyledTreeItem nodeId="9" label="Janelin's D-RISK 2" />
          </StyledTreeItem>
          <StyledTreeItem nodeId="5" label="Imarcum">
            <StyledTreeItem nodeId="10" label="Lori's D-RISK 1" />
            <StyledTreeItem nodeId="11" label="Lori'ss D-RISK 2" />
            <StyledTreeItem nodeId="12" label="Lori's D-RISK 3" />
            <StyledTreeItem nodeId="13" label="Lori's D-RISK 4">
              <StyledTreeItem nodeId="14" label="MacBook Pro Laptop" icon={<WifiIcon />} />
              <StyledTreeItem nodeId="15" label="iPhone 12 Pro" icon={<WifiIcon />} />
              <StyledTreeItem nodeId="16" label="HP Deskjet Printer" icon={<WifiIcon />} />
            </StyledTreeItem>
          </StyledTreeItem>
        </StyledTreeItem> */}

          <StyledTreeItem
            nodeId="1"
            label={deviceTree.company_name ? deviceTree.company_name : "Empty"}
            color="#c12a37"
          >
            {deviceTree.user_details &&
              deviceTree.user_details.map((singleUserDetail, index) => (
                <StyledTreeItem
                  key={index}
                  nodeId={(index + 5).toString()}
                  label={singleUserDetail.user_name}
                >
                  {singleUserDetail.devices &&
                    singleUserDetail.devices.map((userDevice, index) => (
                      <StyledTreeItem
                        key={index}
                        nodeId={(index + 1000).toString()}
                        label={userDevice.device_name}
                      >
                        {userDevice.iot_devices &&
                          userDevice.iot_devices.map((iot_device, index) => (
                            <StyledTreeItem
                              key={index}
                              nodeId={(index + 100000).toString()}
                              label={iot_device.iot_name}
                            />
                          ))}
                      </StyledTreeItem>
                    ))}
                </StyledTreeItem>
              ))}
          </StyledTreeItem>
        </TreeView>
      ) : (
        <div className="text-center mt-2">
          <span
            className="text-center color-red mx-3 p-2"
            style={{ fontSize: 14 }}
          >
            {statusCode?.message}
          </span>
        </div>
      )}
    </div>
  );
}
