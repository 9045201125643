import React from "react";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { Badge } from "@material-ui/core";
import { useHistory } from "react-router";
export const Notification = ({ value }) => {
  const history = useHistory();
  const handleClick = () => {
    history.push("/dashboard/notification");
  };
  return (
    // <IconButton>
    <div className="mr-2 custom-icon">
      <Badge badgeContent={value} color="error" fontSize="medium">
        <NotificationsNoneIcon fontSize="large" onClick={handleClick} />
      </Badge>
    </div>
  );
};
