import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ToggleSideNav from "../UI/ToggleSideNav/ToggleSideNav";
import settingsMethods from "../../services/settingsApi";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class More extends Component {
  state = {
    getMoreData: [],
    device_id: JSON.parse(window.sessionStorage.getItem("ConnectedDevice"))
      ?.device_id,
    showLogout: false,
    open: false,
    message: "",
  };

  async componentDidMount() {
    const response = await settingsMethods.getMoreDetails({
      device_id: this.state.device_id,
    });

    if (response.data.statusCode === 200) {
      this.setState({
        getMoreData: response.data.result[0],
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message != "Session expires. Please login again"
    ) {
      this.setState({
        open: true,
        message: response.data.message,
        loader: false,
      });
    }
  }

  logoutUser = () => {
    window.sessionStorage.clear();
    this.props.history.replace({
      pathname: "/",
    });
  };

  redirectToPrivacyPolicy() {
    const privacyPolicyUrl =
      "https://1295e85e-4830-48e4-85eb-f6e96e2b71f6.filesusr.com/ugd/d050f1_b3314a554972485aa1fe387072dfe256.pdf";
    window.open(privacyPolicyUrl, "_blank");
  }

  render() {
    return (
      <div className="routing-main-wrap">
        <div className="page-head">
          <Snackbar
            open={this.state.open}
            autoHideDuration={3000}
            onClose={() => this.setState({ open: false })}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert
              // onClose={handleClose}
              severity="info"
              sx={{ width: "100%" }}
            >
              {this.state.message}
            </Alert>
          </Snackbar>
          <h3>More</h3>
          <ToggleSideNav batteryRefresh="false" />
        </div>

        <div className="rout-body">
          {this.state.getMoreData ? (
            <div className="row">
              <div className="col-sm-12">
                <div className="black-card d-d-card ">
                  <div className="d-d-card-head mr-t10">
                    <h4>More</h4>
                  </div>
                  <br />
                  <div className="his-card mr-b30">
                    <ul className="his-list">
                      <li>
                        About Us
                        <button
                          type="button"
                          className="trans-btn"
                          onClick={() =>
                            this.props.history.push("/dashboard/aboutUs")
                          }
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.72423 6.00005L0.862305 1.13812L1.80511 0.195312L7.60985 6.00005L1.80511 11.8048L0.862305 10.862L5.72423 6.00005Z"
                              fill="#6BC4E2"
                            />
                          </svg>
                        </button>
                      </li>
                      <li>
                        Privacy Notice
                        <button
                          type="button"
                          className="trans-btn"
                          onClick={this.redirectToPrivacyPolicy}
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.72423 6.00005L0.862305 1.13812L1.80511 0.195312L7.60985 6.00005L1.80511 11.8048L0.862305 10.862L5.72423 6.00005Z"
                              fill="#6BC4E2"
                            />
                          </svg>
                        </button>
                      </li>
                      <li>
                        Manufacture
                        <span>{this.state.getMoreData.manufacture}</span>
                      </li>
                      <li>
                        Model <span>{this.state.getMoreData.model}</span>
                      </li>
                      <li>
                        Serial Number
                        <span>{this.state.getMoreData.device_name}</span>
                      </li>
                      <li>
                        Software Version
                        <span>{this.state.getMoreData.version}</span>
                      </li>
                      {/* <li>
                        Configuration version
                        <span>{this.state.getMoreData.manufacture}</span>
                      </li>
                      <li>
                        Phone number (MDN)
                        <span>{this.state.getMoreData.device_phone_number}</span>
                      </li>
                      <li>
                        IMEI <span>{this.state.getMoreData.IMEI}</span>
                      </li>
                      <li>
                        ICCID <span>{this.state.getMoreData.ICCID}</span>
                      </li>
                      <li>
                        FCC ID <span>{this.state.getMoreData.FCCID}</span>
                      </li>
                      <li>
                        IP address
                        <span>{this.state.getMoreData.ip_address}</span>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="black-card d-d-card ">
                  <div className="d-d-card-head mr-t10">
                    <h4>Wi-Fi</h4>
                  </div>
                  <br />
                  <div className="his-card mr-b30">
                    <ul className="his-list">
                      <li>
                        SSID Name
                        <span>{this.state.getMoreData.wifi_name_ssid}</span>
                      </li>
                      <li>
                        SSID Password Number
                        <span>{this.state.getMoreData.wifi_password}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* <div className="col-sm-12">
                <div className="black-card d-d-card">
                  <div className="d-d-card-head mr-t10">
                    <h4>Battery</h4>
                  </div>
                  <br />
                  <div className="his-card mr-b30">
                    <ul className="his-list">
                      <li>
                        Capacity
                        <span>{this.state.getMoreData.capacity}</span>
                      </li>
                      <li>
                        Temp <span>{this.state.getMoreData.temp}</span>
                      </li>
                      <li>
                        Voltage
                        <span>{this.state.getMoreData.voltage}</span>
                      </li>
                      <li>
                        Chemistry
                        <span>{this.state.getMoreData.chemistry}</span>
                      </li>
                      <li>
                        CPU
                        <span>{this.state.getMoreData.CPU}</span>
                      </li>
                      <li>
                        CPU Cores
                        <span>{this.state.getMoreData.cpu_cores}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          ) : (
            <div
              style={{
                paddingTop: "20px",
                display: "flex",
                justifyContent: "center",
                fontSize: "20px",
                color: "#BC2835",
              }}
            >
              No device connected
            </div>
          )}
        </div>
        {this.state.showLogout ? (
          <Modal
            open={this.state.showLogout}
            aria-labelledby="DeleteUserProfile"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Session Expired
                  </h5>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">
                      Please login again.
                      <br />
                      <br />
                    </h6>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.logoutUser}
                    // disabled={this.state.loader}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default withRouter(More);
