export const AutoScan =
  "When the Auto Scan feature is turned on, this allows the SG-X device to start scanning automatically as soon as an endpoint device tries to connect to your SG-X device";
export const bandWidth =
  "This feature allows you to view data usage and bandwidth for cellular and Local Area Network connections.";
export const wirelessNetwork =
  "This feature allows you to view data usage of the various wireless networks.";
export const connectedDevices =
  "These are the devices that have their access blocked on your SG-X device.";
export const blockedDevices =
  "These are the devices that have their access blocked on your SG-X device.";
export const cotInfo =
  "This feature allows you to enable/disable the Circle of Trust feature. This feature allows you to create circles of trust by creating groups that can consist of people or devices. This feature also allows people and devices to perform direct peer-to-peer communications by utilizing Quantum Encryption.";
export const networkInfo = (
  <div>
    <p>
      {" "}
      <b> Mobile Data</b> - Allows you to turn off cellular data to prevent all
      internet traffic from using the SG-X device’s data connection.{" "}
    </p>{" "}
    <p>
      {" "}
      <b> Wi-Fi Services -</b> This feature allows you to connect your computers
      and mobile devices to the SG-X device Wi-Fi network.
    </p>{" "}
    <p>
      {" "}
      <b> Network Tethering – </b> This feature allows you to connect any
      endpoint device directly to the SG-X device using a USB cable, instead of
      connecting your devices using Wi-Fi.
    </p>{" "}
    <p>
      {" "}
      <b> Wi-Fi Mode – </b>
      When enabled, this puts the SG-X in host mode in which the SG-X can now
      connect to another access point which would provide wireless capability to
      the established network.
    </p>{" "}
    <p>
      {" "}
      <b> Wi-Fi Name (SSID) –</b> Allows you to change the SG-X device network
      name (SSID).{" "}
    </p>{" "}
    <p>
      {" "}
      <b> Wi-Fi Profile -</b> This allows you to select the appropriate Wi-Fi
      setting for the SG-X device.
    </p>
  </div>
);

export const settingsdevice_info_txt = (
  <div>
    <p>
      <b>Notifications -</b> This feature allows you to enable/disable
      notifications that are generated by features of the SG-X device.
    </p>{" "}
    <p>
      {" "}
      <b> Remote Management - </b> This feature allows you to perform remote
      management of the SG-X device such as changing configuration settings.{" "}
    </p>{" "}
    <p>
      {" "}
      <b> Software Update - </b>This feature allows the SG-X device to receive
      automatic software updates.
    </p>
  </div>
);

export const security_info_info_txt = (
  <div>
    <p>
      <b>Protect Mode - </b>Protect Mode allows the SG-X device to safeguard
      your wireless devices from potential cyber threats and intrusions. It is
      recommended that you keep your device in Protect Mode at all times.
    </p>{" "}
    <p>
      {" "}
      <b> Monitoring Mode -</b> Monitoring Mode allows the SG-X device to
      monitor traffic from all connected devices and send alerts based on the
      activity detected, without performing mitigation actions.
    </p>{" "}
    <p>
      {" "}
      <b>Ghost Mode - </b> This feature prevents the SG-X device from being
      discovered
    </p>{" "}
    <p>
      {" "}
      <b> Auto Block -</b> When enabled, the system automatically blocks all new endpoints unless explicitly authorized.
    </p>
  </div>
);
