import React, { Component } from "react";
import ToggleSideNav from "../../UI/ToggleSideNav/ToggleSideNav";
import BackButton from "../../UI/BackButton/BackButton";
import ReactApexChart from "react-apexcharts";
import tower from "../../../assets/images/tower.png";
import wired_img from "../../../assets/images/wired_img.png";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import moment from "moment";
import userMethods from "../../../services/userApi";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
class IOTDevicesDetails extends Component {
  state = {
    series: [],
    options: {},
    seriesDonut: [25, 75],
    optionsDonut: {
      labels: ["data upload", "data download"],
      chart: {
        type: "donut",
        height: "100%",
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: "462.2",
                name: "MB",
              },
              tooltip: {
                enabled: false,
              },
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
              show: false,
            },
          },
        },
      ],

      fill: {
        colors: ["#C12A37", "#2196F3"],
      },
    },
    dashboardType: "day",
    showDate: true,
    displayedDate: moment(new Date()).format("MM/DD/yyyy"),
    showWeek: false,
    weeklyDate: "",
    showMonth: false,
    currentMonth: moment().format("MMMM"),
    globalDate: new Date(),
    startDate: moment(new Date()).format("MM/DD/yyyy"),
    endDate: moment(new Date()).format("MM/DD/yyyy"),
    networkType: "AT&T",
    barchartData: [],
    showChart: false,
    device_id: JSON.parse(window.sessionStorage.getItem("ConnectedDevice"))
      ?.device_id,
    // device_id: 125,
    vulnerabilityData: [],
    IsAdminMap: false,
  };

  componentDidMount() {
    this.getGraphData();
    this.getVulnerabilityData();

    this.setState({ IsAdminMap: window.sessionStorage.getItem("IsAdminMap") });
  }

  changeToPreviousDay = async () => {
    await this.setState({
      displayedDate: moment(new Date(this.state.displayedDate))
        .subtract(1, "day")
        .format("MM/DD/yyyy"),
    });
  };

  changeToNextDay = () => {
    this.setState({
      displayedDate: moment(new Date(this.state.displayedDate))
        .add(1, "days")
        .format("MM/DD/yyyy"),
    });
  };

  changeToPreviousMonth = () => {
    const additionOfMonths = 1;
    this.state.globalDate.setMonth(
      this.state.globalDate.getMonth() - additionOfMonths
    ); // For subtract use minus (-)

    this.setState({
      currentMonth: moment(this.state.globalDate.toString()).format("MMMM"),
    });

    this.getGraphData();
  };

  changeToNextMonth = () => {
    const additionOfMonths = 1;
    this.state.globalDate.setMonth(
      this.state.globalDate.getMonth() + additionOfMonths
    ); // For add use plus (+)

    this.setState({
      currentMonth: moment(this.state.globalDate.toString()).format("MMMM"),
    });

    this.getGraphData();
  };

  changeDashboardData = async (event) => {
    if (event.target.value === "day") {
      await this.setState({
        showWeek: false,
        showDate: true,
        showMonth: false,
        displayedDate: moment(new Date()).format("MM/DD/yyyy"),
        dashboardType: event.target.value,
      });

      this.getGraphData();
    } else if (event.target.value === "yesterday") {
      await this.setState({
        showWeek: false,
        showDate: true,
        showMonth: false,
        displayedDate: moment(new Date())
          .subtract(1, "day")
          .format("MM/DD/yyyy"),
        dashboardType: event.target.value,
      });

      this.getGraphData();
    } else if (event.target.value === "weekly") {
      var currentDate = moment();
      var weekStart = currentDate.clone().startOf("isoWeek");
      var days = [];

      for (var i = 0; i <= 6; i++) {
        days.push(moment(weekStart).add(i, "days").format("MM/DD/yyyy"));
      }

      await this.setState({
        showWeek: true,
        showDate: false,
        showMonth: false,
        weeklyDate: days[0] + " - " + days[6],
        dashboardType: event.target.value,
      });

      this.getGraphData();
    } else if (event.target.value === "monthly") {
      var date2 = new Date();

      date2.setMonth(date2.getMonth());

      await this.setState({
        showWeek: false,
        showDate: false,
        showMonth: true,
        currentMonth: moment().format("MMMM"),
        globalDate: date2,
        dashboardType: event.target.value,
      });

      this.getGraphData();
    } else if (event.target.value === "lastmonth") {
      var date3 = new Date();
      date3.setMonth(date3.getMonth() - 1);

      await this.setState({
        showWeek: false,
        showDate: false,
        showMonth: true,
        currentMonth: moment(date3.toString()).format("MMMM"),
        globalDate: date3,
        dashboardType: event.target.value,
      });

      this.getGraphData();
    }
  };

  changeNetworkType = (event) => {
    this.setState({
      networkType: event.target.value,
    });
  };

  getGraphData = async () => {
    var dashboardObject = {
      device_id: this.state.device_id,
      startDate: "",
      endDate: "",
      type: this.state.dashboardType,
      network: this.state.networkType,
    };

    if (this.state.dashboardType === "yesterday") {
      var yesterdayDate = moment(new Date())
        .subtract(1, "day")
        .format("MM/DD/yyyy");

      dashboardObject.startDate = yesterdayDate;
      dashboardObject.endDate = yesterdayDate;
    }

    if (this.state.dashboardType === "day") {
      var todayDate = moment(new Date()).format("MM/DD/yyyy");

      dashboardObject.startDate = todayDate;
      dashboardObject.endDate = todayDate;
    }

    if (this.state.dashboardType === "weekly") {
      var firstDay = this.state.weeklyDate.split(" - ")[0];
      var lastDay = this.state.weeklyDate.split(" - ")[1];

      dashboardObject.startDate = firstDay;
      dashboardObject.endDate = lastDay;
    }

    if (this.state.dashboardType === "monthly") {
      var year = this.state.globalDate.getFullYear();
      var month = this.state.globalDate.getMonth();
      var firstDate = new Date(year, month, 1);
      var lastDate = new Date(year, month + 1, 0);

      firstDate = moment(firstDate).format("MM/DD/yyyy");
      lastDate = moment(lastDate).format("MM/DD/yyyy");

      dashboardObject.startDate = firstDate;
      dashboardObject.endDate = lastDate;
    }

    if (this.state.dashboardType === "lastmonth") {
      var yearlast = this.state.globalDate.getFullYear();
      var monthlast = this.state.globalDate.getMonth();
      var lastfirstDate = new Date(yearlast, monthlast, 1);
      var lastlastDate = new Date(yearlast, monthlast + 1, 0);

      lastfirstDate = moment(lastfirstDate).format("MM/DD/yyyy");
      lastlastDate = moment(lastlastDate).format("MM/DD/yyyy");

      dashboardObject.startDate = lastfirstDate;
      dashboardObject.endDate = lastlastDate;
      dashboardObject.type = "monthly";
    }

    const response = await userMethods.getDashboardGraph(dashboardObject);

    if (response.data.message === "No information available") {
      this.setState({ showChart: false });
    }

    if (response.data.statusCode === 200) {
      this.setState({
        barchartData: response.data.result,
      });
      var download = [];
      var upload = [];
      var categories = [];

      this.state.barchartData.data.forEach((element) => {
        download.push(element.data_download.split("MB")[0]);
        upload.push(element.data_upload.split("MB")[0]);

        if (dashboardObject.type === "weekly") {
          categories.push(element.time);
        } else if (dashboardObject.type === "monthly") {
          categories.push(element.date);
        }
      });

      this.setState({
        series: [
          {
            name: "data_download",
            data: download,
          },
          {
            name: "data_upload",
            data: upload,
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 150,
            stacked: true,
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              borderRadius: 8,
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            type: "data",
            categories: categories,
            labels: {
              show: true,
              rotate: -45,
              rotateAlways: false,
              showDuplicates: false,
              trim: true,
              style: {
                colors: "#fff",
                fontSize: "12px",
              },
            },
          },
          legend: {
            position: "right",
            offsetY: 40,
            show: false,
          },
          fill: {
            colors: ["#2196F3", "#C12A37"],
          },
        },
        showChart: true,
      });
    }
  };

  getVulnerabilityData = async () => {
    var deviceId = {
      device_id: this.state.device_id,
    };

    const response = await userMethods.getVulnerabilityLogs(deviceId);

    if (response.data.statusCode === 200) {
      this.setState({
        vulnerabilityData: response.data.result,
      });
    }
  };

  render() {
    return (
      <div className="routing-main-wrap">
        <div className="page-head">
          <div className="page_head_btn ">
            <div className="aboutus-flex d-flex align-items-center">
              {this.state.IsAdminMap ? (
                <BackButton count={0} getPath="/dashboard/admin" />
              ) : (
                <BackButton count={0} getPath="/dashboard/map" />
              )}
              <h3 className="pd-l10"> IoT Device Details</h3>
            </div>
          </div>
          <ToggleSideNav batteryRefresh="false" />
        </div>
        <div className="rout-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="iot-device-details">
                <div className="black-card d-d-card">
                  <div className="d-d-card-head">
                    <div className="card_wifi_name d-flex align-items-center">
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 3.99702C2.82855 1.40534 6.35972 -0.00607102 10 1.96299e-05C13.7887 1.96299e-05 17.2679 1.49702 20 3.99702L18.3333 6.33001C15.9761 4.17054 13.0335 2.99466 10 3.00002C6.8435 3.00002 3.94341 4.24801 1.66667 6.33001L0 3.99702ZM2.77719 7.88701C4.82016 6.01501 7.37068 4.99555 10 5.00001C12.7356 5.00001 15.2485 6.08101 17.2228 7.88601L15.5561 10.22C13.9845 8.78022 12.0225 7.99629 10 8.00001C7.89567 8.00001 5.96286 8.83201 4.44386 10.22L2.77719 7.88601V7.88701ZM5.55526 11.777C6.81242 10.6249 8.38194 9.99741 10 10C11.6835 10 13.2299 10.665 14.4447 11.776L12.7781 14.11C11.9922 13.3902 11.0113 12.9982 10 13C8.98875 12.9982 8.00778 13.3902 7.22193 14.11L5.55526 11.776V11.777ZM8.33333 15.667C8.80464 15.2347 9.39319 14.9992 10 15C10.6313 15 11.2113 15.25 11.6667 15.666L10 18L8.33333 15.667Z"
                          fill="#C12A37"
                        />
                      </svg>

                      <h4 className="mr-l15">35JSKD</h4>
                    </div>
                    {/* <button type="button" className="trans-btn">
                      <svg
                        className="edit-btn-iot-device"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
                          fill="black"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M22 20V13H20V20H4V4H11V2H4C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20ZM19.1781 2.72342C18.7197 2.26142 18.0921 2 17.4374 2C16.7834 2 16.1564 2.26083 15.6954 2.72463L7.3265 11.0934C6.57867 11.7523 6.08844 12.7328 6.00325 13.7873L6 17.0023V18.0023H10.1346C11.2689 17.9245 12.259 17.4295 12.9575 16.6238L21.279 8.30584C21.7407 7.84416 22.0001 7.21799 22.0001 6.56508C22.0001 5.91217 21.7407 5.286 21.279 4.82432L19.1781 2.72342ZM10.064 16.0048C10.5982 15.967 11.0955 15.7184 11.4948 15.2616L17.5567 9.19972L14.8024 6.44527L8.6961 12.5496C8.29095 12.9079 8.04031 13.4092 8 13.8678V16.0029L10.064 16.0048ZM16.2169 5.03128L18.9709 7.78551L19.8648 6.89162C19.9514 6.80502 20.0001 6.68756 20.0001 6.56508C20.0001 6.4426 19.9514 6.32514 19.8648 6.23854L17.7611 4.13486C17.6755 4.04855 17.5589 4 17.4374 4C17.3158 4 17.1992 4.04855 17.1136 4.13486L16.2169 5.03128Z"
                          fill="white"
                        />
                        <path
                          d="M4 1H20V-1H4V1ZM23 4V20H25V4H23ZM20 23H4V25H20V23ZM1 20V4H-1V20H1ZM4 23C2.34315 23 1 21.6569 1 20H-1C-1 22.7614 1.23858 25 4 25V23ZM23 20C23 21.6569 21.6569 23 20 23V25C22.7614 25 25 22.7614 25 20H23ZM20 1C21.6569 1 23 2.34315 23 4H25C25 1.23858 22.7614 -1 20 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
                          fill="white"
                          fillOpacity="0.12"
                        />
                      </svg>
                    </button> */}
                  </div>
                  <div className="device-info-wrap mr-t30">
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="d-info-card d-info-card-iot-device">
                          <label>Phone number</label>
                          <h4>704-342-9865</h4>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="d-info-card">
                          <label>MAC</label>
                          <h4>38:e6:b2:87:d1:f4</h4>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="d-info-card">
                          <label>OS</label>
                          <h4>Android</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* --------- Header Tabs --------- */}
                <div className="header_tabs">
                  <div className="month_tab">
                    <div className="monthtab_input_select">
                      <select
                        className="custom_select"
                        onChange={this.changeDashboardData}
                        value={this.state.dashboardType}
                      >
                        <option value="day" defaultValue>
                          Today
                        </option>
                        <option value="yesterday">Yesterday</option>
                        <option value="weekly">Week</option>
                        <option value="monthly">Current Month</option>
                        <option value="lastmonth">Last Month</option>
                      </select>
                    </div>
                  </div>

                  <div className="month_name_tab">
                    <div className="month_input_select">
                      {this.state.showDate ? (
                        <span>
                          <span className="drop-icons-left">
                            <ArrowBackIosIcon
                              onClick={this.changeToPreviousDay}
                            />
                          </span>
                          {this.state.displayedDate}
                          <span className="drop-icons-right">
                            <ArrowForwardIosIcon
                              onClick={this.changeToNextDay}
                            />
                          </span>
                        </span>
                      ) : null}

                      {this.state.showWeek ? (
                        <span className="week-date">
                          {this.state.weeklyDate}
                        </span>
                      ) : null}

                      {this.state.showMonth ? (
                        <span>
                          <span className="drop-icons-left">
                            <ArrowBackIosIcon
                              onClick={this.changeToPreviousMonth}
                            />
                          </span>
                          {this.state.currentMonth}
                          <span className="drop-icons-right">
                            <ArrowForwardIosIcon
                              onClick={this.changeToNextMonth}
                            />
                          </span>
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="month_tab">
                    <div className="monthtab_input_select">
                      <select
                        className="networking_custom_select"
                        style={{ border: "none" }}
                        onChange={this.changeNetworkType}
                        value={this.state.networkType}
                      >
                        <option value="AllNetworks">All Networks</option>
                        <option value="AT&T">AT&T</option>
                        <option value="Wi-fi">Wi-Fi</option>
                        <option value="Cellular">Cellular</option>
                        <option value="Bluetooth">Bluetooth</option>
                        <option value="Verizon">Verizon</option>
                      </select>
                    </div>
                  </div>
                </div>

                {/* --------- Header Graph --------- */}
                <div className="header_graph mr-t50">
                  {this.state.showChart ? (
                    <ReactApexChart
                      options={this.state.options}
                      series={this.state.series}
                      type="bar"
                      height={250}
                    />
                  ) : (
                    <div
                      style={{
                        height: "100px",
                        marginLeft: "40%",
                        marginTop: "8%",
                      }}
                    >
                      <h6 style={{ color: "#E3C710" }}>
                        No chart data available.
                      </h6>
                    </div>
                  )}
                </div>

                {/* ------ Bandwidth Data -------- */}
                <div className="bandwidth_main mr-t30 ">
                  <div className="bandwidth_limiter">
                    <div className=" bandwidth_card1">
                      <div className="band_subgrid">
                        <div className="bandwidth_sub_limiter">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15 0C23.28 0 30 6.72 30 15C30 23.28 23.28 30 15 30C6.72 30 0 23.28 0 15C0 6.72 6.72 0 15 0ZM15 27C21.63 27 27 21.63 27 15C27 8.37 21.63 3 15 3C8.37 3 3 8.37 3 15C3 21.63 8.37 27 15 27ZM16.5 15H21L15 21L9 15H13.5V9H16.5V15Z"
                              fill="#2196F3"
                            />
                          </svg>
                          <h5>420.0 Mbps</h5>
                          <h6>221.4 Mbps</h6>
                        </div>

                        <div className="bandwidth_sub_limiter">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15 30C6.72 30 0 23.28 0 15C0 6.72 6.72 0 15 0C23.28 0 30 6.72 30 15C30 23.28 23.28 30 15 30ZM15 3C8.37 3 3 8.37 3 15C3 21.63 8.37 27 15 27C21.63 27 27 21.63 27 15C27 8.37 21.63 3 15 3ZM13.5 15H9L15 9L21 15H16.5V21H13.5V15Z"
                              fill="#C12A37"
                            />
                          </svg>

                          <h5>420.0 Mbps</h5>
                          <h6>221.4 Mbps</h6>
                        </div>
                      </div>
                      <div className="bandwidth_sub_limiter_text">
                        <h6>Bandwidth Limiter</h6>
                      </div>
                    </div>
                    <div className="bandwidth_card2">
                      <ReactApexChart
                        options={this.state.optionsDonut}
                        series={this.state.seriesDonut}
                        type="donut"
                        height={200}
                      />
                    </div>
                    <div className=" bandwidth_card3">
                      <div className="internet_head text-center">
                        <h5>Internet</h5>
                      </div>
                      <div className="internet_grid">
                        <div className="internet_img">
                          <img src={tower} alt="" />
                          <h6>262.2 Mb</h6>
                        </div>
                        <div className="internet_img">
                          <img src={wired_img} alt="" />
                          <h6>262.2 Mb</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ------ Wireless Networks -------- */}
                <div className="wireless_net_main mr-t20  ">
                  <div className="wireless_header text-center">
                    <h5>Wireless Networks</h5>
                  </div>
                  <div className="wireless_content">
                    <div className="col-sm-3 col-md-auto bluetooth_card">
                      <svg
                        width="15"
                        height="24"
                        viewBox="0 0 15 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.122 6.757L5.62201 0V10.485L0 6.01601V8.552L4.336 12L0 15.447V17.983L5.62201 13.514V24L14.122 17.243L7.52701 12L14.122 6.757ZM7.46902 3.877L11.092 6.757L7.46902 9.63699V3.877ZM11.092 17.242L7.46902 20.122V14.362L11.092 17.242Z"
                          fill="#BC2835"
                        />
                      </svg>
                      <span>200.0 MB</span>
                    </div>
                    <div className="col-sm-3 col-md-auto bluetooth_card">
                      <svg
                        width="29"
                        height="23"
                        viewBox="0 0 29 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 5.1073C4.08772 1.79571 9.19085 -0.00775741 14.4517 2.50826e-05C19.9269 2.50826e-05 24.955 1.91286 28.9033 5.1073L26.4947 8.08835C23.0881 5.32903 18.8355 3.82652 14.4517 3.83335C9.88999 3.83335 5.69888 5.42802 2.40861 8.08835L0 5.1073ZM4.0135 10.0778C6.96593 7.68585 10.6518 6.38321 14.4517 6.38891C18.4051 6.38891 22.0365 7.77018 24.8898 10.0766L22.4812 13.0589C20.2099 11.2192 17.3745 10.2175 14.4517 10.2222C11.4105 10.2222 8.61732 11.2853 6.4221 13.0589L4.0135 10.0766V10.0778ZM8.02827 15.0484C9.84507 13.5763 12.1133 12.7745 14.4517 12.7778C16.8845 12.7778 19.1194 13.6275 20.875 15.0471L18.4664 18.0295C17.3307 17.1097 15.9131 16.6088 14.4517 16.6111C12.9902 16.6088 11.5726 17.1097 10.4369 18.0295L8.02827 15.0471V15.0484ZM12.043 20.0189C12.7242 19.4666 13.5747 19.1656 14.4517 19.1667C15.364 19.1667 16.2022 19.4861 16.8603 20.0177L14.4517 23L12.043 20.0177V20.0189Z"
                          fill="#C12A37"
                        />
                      </svg>

                      <span>200.0 MB</span>
                    </div>
                  </div>
                </div>

                <div className="logs-wrap mr-t30">
                  <div className="row">
                    <div className="col-6">
                      <h4> Issues</h4>
                    </div>
                  </div>
                  <div className="logs-list-wrap mr-t15">
                    <ul className="logs-list">
                      {this.state.vulnerabilityData.map((issue, index) => (
                        <li key={index}>
                          <div className="l-icon">
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22ZM18.0319 16.6177C19.2635 15.078 20 13.125 20 11C20 6.02944 15.9706 2 11 2C8.87499 2 6.92199 2.73647 5.38231 3.9681L18.0319 16.6177ZM16.6177 18.0319C15.078 19.2635 13.125 20 11 20C6.02944 20 2 15.9706 2 11C2 8.87499 2.73647 6.92199 3.9681 5.38231L16.6177 18.0319Z"
                                fill="#C12A37"
                              />
                            </svg>
                          </div>
                          <div className="logs-details">
                            <h5>Blocked</h5>
                            <p>
                              {issue.website_access}: {issue.description}
                            </p>

                            <time>
                              {moment(issue.date).format("MM/DD/yyyy HH:MM A")}
                            </time>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IOTDevicesDetails;
