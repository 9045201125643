import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function PaymentModal() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setIsOpen] = useState(false);

  useEffect(() => {
    console.log("works?");

    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    console.log("workspt1?");

    if (!clientSecret) {
      return;
    }
    console.log("workspt2?");

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      console.log(paymentIntent.status);
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,

      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "http://localhost:3000/dashboard/dataSharing", //testing url
        return_url: "http://3.214.93.141:5002/dashboard/dataSharing", // live url
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        // onClose={() => this.setState({ open: false })}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert severity="success">Payment Sucess!</Alert>
      </Snackbar>
      <PaymentElement id="payment-element" />
      <div style={{ display: "flex", justifyContent: "center", padding: "10px 0px" }}>
        <button
          style={{
            border: "none",
            borderRadius: "8px",
            backgroundColor: "#42a5f5",
            color: "white",
            padding: "10px auto",
            width: "100%",
            height: "40px",
          }}
          disabled={isLoading || !stripe || !elements}
          id="submit"
        >
          <span id="button-text">{isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}</span>
        </button>
      </div>
      {/* Show any error or success messages */}
      <div style={{ color: "red" }}>{message && <div id="payment-message">{message}</div>}</div>
    </form>
  );
}
