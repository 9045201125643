import React, { Component } from "react";
import ToggleSideNav from "../../UI/ToggleSideNav/ToggleSideNav";
import userMethods from "../../../services/userApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import BackButton from "../../UI/BackButton/BackButton";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";

class Registration extends Component {
  state = {
    usersList: [],
    pageLoader: false,
    device: {},
    deleteConfirmationModal: false,
    userDetails: "",
    ownerCheck: JSON.parse(window.sessionStorage.getItem("UserDetails")).user_id, //checks role_id from login data to determine owner or not
    role_id: "",
    // device_id: JSON.parse(window.sessionStorage.getItem("UserDetails")).device_name[0].device_id,
  };

  componentDidMount = async () => {
    // console.log(this.state.device_id);
    console.log(JSON.parse(window.sessionStorage.getItem("UserDetails")).user_id);
    // console.log(this.state.ownerCheck);
    await this.setState({
      device: this.props.location.state.device,
    });
    this.fetchConnectedUsersList();
  };

  triggerModal = (user) => {
    // console.log(user);
    console.log(user.user_id);
    // let data = {
    //   device_id: this.state.device.device_id,
    //   user_id: user.user_id,
    // };

    // const response = await userMethods.removeGuestUsers(data);

    // if (response.data.statusCode === 200) {
    //   this.setState({ usersList: response.data.result });
    //   this.fetchConnectedUsersList();
    // }
    this.setState({
      deleteConfirmationModal: true,
      userDetails: user.user_id,
    });
    // console.log(this.state.userDetails);
  };

  deleteUser = async () => {
    console.log(this.state.userDetails);
    let data = {
      device_id: this.state.device.device_id,
      user_id: this.state.userDetails,
    };
    const response = await userMethods.removeGuestUsers(data);
    if (response.data.statusCode === 200) {
      this.setState({ usersList: response.data.result });
      this.setState({
        deleteConfirmationModal: false,
      });
      this.fetchConnectedUsersList();
    }
  };

  handleClose = () => {
    this.setState({ deleteConfirmationModal: false });
  };

  fetchConnectedUsersList = async () => {
    let deviceId = {
      device_id: this.state.device.device_id,
    };

    const response = await userMethods.getMappedUsers(deviceId);
    console.log(response.data.result.filter((record) => record.user_id === this.state.ownerCheck));
    const record = response.data.result.filter((record) => record.user_id === this.state.ownerCheck);
    if (record.length > 0) {
      console.log(record[0].role_id);
      this.setState({
        role_id: record[0].role_id,
      });
    }
    if (response.data.statusCode === 200) {
      this.setState({ usersList: response.data.result });
    }
  };

  render() {
    return (
      <div className="routing-main-wrap">
        <div className="page-head">
          <div className="page_head_btn ">
            <div className="aboutus-flex d-flex align-items-center">
              <BackButton count={0} getPath="/dashboard/registration" />
              <h3 className="pd-l10"> User List </h3>
            </div>
          </div>
          <ToggleSideNav batteryRefresh="false" />
        </div>

        <div className="rout-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="device-details">
                {this.state.usersList.length > 0 ? (
                  this.state.usersList.map((user, index) => (
                    <div className="black-card settings-card mr-b20">
                      <div className="b-card-link d-flex">
                        <div className="col-11 feedback-title">
                          <h6>{user.full_name}</h6>
                          <h6>{user.role_id === 1 ? "Admin" : user.role_id === 2 ? "Guest" : "Owner"}</h6>
                        </div>
                        {/* Pending to implement */}
                        {console.log(
                          user.user_id != this.state.ownerCheck,
                          this.state.role_id === 3,
                          this.state.role_id
                        )}
                        {user.user_id != this.state.ownerCheck ? (
                          this.state.role_id === 3 ? (
                            <div className="col-1">
                              
                              <i
                                className="fa fa-trash mr-l10"
                                aria-hidden="true"
                                onClick={() => this.triggerModal(user)}
                                style={{ color: "#BC2835" }}
                              ></i>
                            </div>
                          ) : null
                        ) : null}
                      </div>
                    </div>
                  ))
                ) : (
                  <CircularProgress color="secondary" style={{ margin: "0% 0% 0% 50%" }} />
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.deleteConfirmationModal ? (
          <Modal
            open={this.state.deleteConfirmationModal}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw" role="document">
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">
                      <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5 className="modal-title modal_fp_title " id="exampleModalLabel">
                    Confirm Delete
                  </h5>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body">
                    <div className="fp_label">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "20px",
                        }}
                      >
                        <button
                          type="button"
                          // className="btn-fp-footer btn-Reset"
                          style={{
                            backgroundColor: "#c12a37",
                            color: "white",
                            borderRadius: "10px",
                            height: "38px",
                            width: "150px",
                            border: "1px solid #c12a37",
                          }}
                          onClick={this.deleteUser}
                          disabled={this.state.loader}
                        >
                          Confirm 
                          {this.state.loader ? <i className="fa fa-spinner fa-spin"></i> : null}
                        </button>
                        <button
                          type="button"
                          // className="btn-fp-footer btn-Reset"
                          style={{
                            backgroundColor: "#c12a37",
                            color: "white",
                            borderRadius: "10px",
                            height: "38px",
                            width: "150px",
                            border: "1px solid #c12a37",
                          }}
                          onClick={this.handleClose}
                        >
                          Cancel 
                          {this.state.loader ? <i className="fa fa-spinner fa-spin"></i> : null}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default Registration;
