import React, { Component } from "react";
import {
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import adminMethods from "../../../services/adminApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import Moment from "@date-io/moment";
import moment from "moment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Input from "@material-ui/core/Input";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class UserManagement extends Component {
  state = {
    activeAcc: "No",
    restrictions: "No",
    monthsList: [
      { id: 1, name: "All", isChecked: true },
      { id: 2, name: "Jan", isChecked: true },
      { id: 3, name: "Feb", isChecked: true },
      { id: 4, name: "Mar", isChecked: true },
      { id: 5, name: "Apr", isChecked: true },
      { id: 6, name: "May", isChecked: true },
      { id: 7, name: "Jun", isChecked: true },
      { id: 8, name: "Jul", isChecked: true },
      { id: 9, name: "Aug", isChecked: true },
      { id: 10, name: "Sep", isChecked: true },
      { id: 11, name: "Oct", isChecked: true },
      { id: 12, name: "Nov", isChecked: true },
      { id: 13, name: "Dec", isChecked: true },
    ],
    daysList: [
      { id: 1, name: "All", isChecked: true },
      { id: 2, name: "Sun", isChecked: true },
      { id: 3, name: "Mon", isChecked: true },
      { id: 4, name: "Tue", isChecked: true },
      { id: 5, name: "Wed", isChecked: true },
      { id: 6, name: "Thu", isChecked: true },
      { id: 7, name: "Fri", isChecked: true },
      { id: 8, name: "Sat", isChecked: true },
    ],
    editMonthsList: [
      { id: 1, name: "All", isChecked: false },
      { id: 2, name: "Jan", isChecked: false },
      { id: 3, name: "Feb", isChecked: false },
      { id: 4, name: "Mar", isChecked: false },
      { id: 5, name: "Apr", isChecked: false },
      { id: 6, name: "May", isChecked: false },
      { id: 7, name: "Jun", isChecked: false },
      { id: 8, name: "Jul", isChecked: false },
      { id: 9, name: "Aug", isChecked: false },
      { id: 10, name: "Sep", isChecked: false },
      { id: 11, name: "Oct", isChecked: false },
      { id: 12, name: "Nov", isChecked: false },
      { id: 13, name: "Dec", isChecked: false },
    ],
    monthRule: [],
    editDaysList: [
      { id: 1, name: "All", isChecked: false },
      { id: 2, name: "Sun", isChecked: false },
      { id: 3, name: "Mon", isChecked: false },
      { id: 4, name: "Tue", isChecked: false },
      { id: 5, name: "Wed", isChecked: false },
      { id: 6, name: "Thu", isChecked: false },
      { id: 7, name: "Fri", isChecked: false },
      { id: 8, name: "Sat", isChecked: false },
    ],
    dayRule: [],
    userColumns: [
      { name: "id", title: "Employee ID" },
      { name: "fname", title: "First Name" },
      { name: "lname", title: "Last Name" },
      { name: "email", title: "Email" },
      { name: "dname", title: "Device Count" },
      // { name: "role", title: "User Role" },
      { name: "action", title: "Action" },
    ],
    userData: [],
    sortUserData: {
      sort_by: "id",
      order_by: "asc",
      offset: 0,
      limit: 5,
      status: 1,
    },
    deviceColumns: [
      { name: "id", title: "Device ID" },
      { name: "dname", title: "Device Name" },
      { name: "status", title: "Role" },
      // { name: "admin", title: "Admin" },
      { name: "action", title: "Action" },
    ],
    deviceData: [],
    sortDeviceData: {
      sort_by: "id",
      order_by: "desc",
      offset: 0,
      limit: 5,
      status: 1,
    },
    dtableCount: 5,
    drowsPerPage: 5,
    dpage: 0,
    deviceFilter: "",
    deviceFilteredData: [],
    userFilter: "",
    userFilteredData: [],
    utableCount: 5,
    urowsPerPage: 5,
    upage: 0,
    showTable: true,
    showCreateUser: false,
    showDeleteUser: false,
    showEditUser: false,
    showDevices: false,
    showDeleteDevice: false,
    loader: false,
    userType: "Admin",
    customerName: "",
    customerId: "",
    customer_id: "",
    order: true,
    showCustNameId: false,
    noData: false,
    deviceList: [],
    open: false,
    message: "",
    userDetails: [],
    userDeviceDetails: "",
    user_id: "",
    selecteddeviceList: [],
    startTime: "",
    newStartTime: moment("12:00 AM", "HH:mm A"),
    endTime: "",
    newEndTime: moment("12:00 PM", "HH:mm A"),
    dayRule: "",
    monthRule: "",
    deviceId: "", // for delete purpose
    UserID: "", // for delete purpose
    showLogout: false,
    search: "",
    errorName: "",
    alertFirstName: false,
    errorPhone: "",
    alertPhone: false,
    errorEmail: "",
    alertEmail: false,
    isEmailValid: false,
    alertID: false,
    errorID: "",
    errorPassword: "",
    alertPassword: "",
    errorPassword1: "",
    alertPassword1: false,
    isPasswordValid: false,
    errorRole: "",
    alertRole: false,
    alertDevice: false,
    errorDevice: "",

    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password1: "",
    mobile: "",
    empId: "",
    dob: moment(new Date()).format("MM/DD/YYYY"),
    userRole: "",
    device_id: "",

    edit_first_name: "",
    edit_last_name: "",
    editEmail: "",
    editPhone: "",
    edit_Id: "",
    editDob: "",
    editCompany: "",
  };

  componentDidMount = async () => {
    await this.setState({
      customerName:
        JSON.parse(window.sessionStorage.getItem("CustomerDetails")) === null
          ? ""
          : JSON.parse(window.sessionStorage.getItem("CustomerDetails"))
              .customerName,
      customerId:
        JSON.parse(window.sessionStorage.getItem("CustomerDetails")) === null
          ? ""
          : JSON.parse(window.sessionStorage.getItem("CustomerDetails"))
              .customerId,
      showCustNameId:
        JSON.parse(window.sessionStorage.getItem("CustomerDetails")) === null
          ? true
          : false,
    });
    // console.log(JSON.parse(window.sessionStorage.getItem("UserDetails")).customer_id);
    this.getUserDetails();
  };

  handleDateOfBirth = (date) => {
    this.setState({
      dob: moment(date).format("MM/DD/yyyy"),
    });
  };

  handleDOB = (date) => {
    this.setState({
      editDob: moment(date).format("MM/DD/yyyy"),
    });
  };

  userRole = async (event) => {
    await this.setState({
      userRole: event.target.value,
    });
    // console.log(this.state.UserRole);
  };

  selectDevice = async (event) => {
    await this.setState({
      device_id: event.target.value,
    });
    // console.log(this.state.status);
  };

  getUserDetails = async () => {
    const response = await adminMethods.getCustomerUserDetails(
      this.state.userFilter
    );

    if (response.data.message === "Assignment to constant variable.") {
      this.setState({ noData: true });
    }

    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message != "Session expires. Please login again"
    ) {
      this.setState({
        open: true,
        message: response.data.message,
        loader: false,
      });
    }

    if (response.data.statusCode === 200) {
      var result = response.data.result;

      if (result.length === 0) {
        this.setState({ noData: true });
      } else {
        var userArray = [];
        this.setState({ noData: false });

        result.forEach((element) => {
          userArray.push({
            id: element.employee_id,
            fname: element.first_name,
            lname: element.last_name,
            email: element.email_address,
            dname: element.device_count,
            user_id: element.user_id,
            can_edit: element.can_edit,
            // role: "Admin",
          });
        });

        await this.setState({
          userData: userArray,
          utableCount: userArray.length,
          userFilteredData: this.state.userData,
        });
      }
    } else this.setState({ noData: true });
    this.setState({ loader: false });
  };

  logoutUser = () => {
    // window.sessionStorage.clear();
    // this.props.history.replace({
    //   pathname: "/",
    // });
    this.setState({
      showLogout: false,
    });
  };

  deleteDevice = (user) => {
    this.setState({ showDeleteDevice: true });
    // console.log(user);
    this.setState({
      deviceId: user.device_id,
    });
  };

  deleteDeviceProfile = async () => {
    var deleteId = {
      user_id: this.state.user_id,
      device_id: this.state.deviceId,
    };
    // console.log(deleteId);
    const response = await adminMethods.deleteUserDevices(deleteId);

    if (response.data.statusCode === 200) {
      await this.setState({
        showDeleteDevice: false,
      });
      this.openDeviceTable(this.state.user_id);
      // var id = {
      //   user_id: this.state.user_id,
      // };
      // const response = await adminMethods.getUserDevice(id);
      // // console.log(response.data.result);
      // this.setState({
      //   userDeviceDetails: response.data.result,
      // });
    }
  };

  changeActiveAccount = (event) => {
    this.setState({
      activeAcc: event.target.value,
    });
  };

  changeAccessRestrictions = (event) => {
    this.setState({
      restrictions: event.target.value,
    });
  };

  handleMonthsChange = (event, isChecked) => {
    const monthsList = this.state.monthsList;

    if (event.target.value === "All") {
      monthsList.forEach((month) => {
        month.isChecked = isChecked;
      });
    }

    monthsList.forEach((month) => {
      if (month.name === event.target.value) {
        month.isChecked = isChecked;
      }
    });

    this.setState({
      monthsList: monthsList,
    });
    // console.log(this.state.monthsList);
  };

  handleDaysChange = (event, isChecked) => {
    const daysList = this.state.daysList;

    if (event.target.value === "All") {
      daysList.forEach((day) => {
        day.isChecked = isChecked;
      });
    }

    daysList.forEach((day) => {
      if (day.name === event.target.value) {
        day.isChecked = isChecked;
      }
    });

    this.setState({
      daysList: daysList,
    });
    // console.log(this.state.daysList);
  };

  handleEditMonthsChange = (event, isChecked) => {
    const monthsList = this.state.editMonthsList;

    if (event.target.value === "All") {
      monthsList.forEach((month) => {
        month.isChecked = isChecked;
      });
    }

    monthsList.forEach((month) => {
      if (month.name === event.target.value) {
        month.isChecked = isChecked;
      }
    });

    this.setState({
      editMonthsList: monthsList,
    });
    // console.log(this.state.monthsList);
  };

  handleEditDaysChange = (event, isChecked) => {
    const daysList = this.state.editDaysList;

    if (event.target.value === "All") {
      daysList.forEach((day) => {
        day.isChecked = isChecked;
      });
    }

    daysList.forEach((day) => {
      if (day.name === event.target.value) {
        day.isChecked = isChecked;
      }
    });

    this.setState({
      editDaysList: daysList,
    });
    // console.log(this.state.daysList);
  };

  openUser = async () => {
    this.setState({ showCreateUser: true, showTable: false });
    const response = await adminMethods.getCustomerDeviceDetails(
      this.state.search
    );
    // console.log(response.data.result);
    this.setState({
      deviceList: response.data.result,
      companyName: response.data.result[0]
        ? response.data.result[0].customer_name
        : null,
      customer_id: response.data.result[0] ? response.data.result[0].Id : null,
    });
  };

  editUser = async (user) => {
    this.setState({ showEditUser: true, showTable: false });
    var id = {
      user_id: user.user_id,
    };
    // console.log(id);
    const response = await adminMethods.editCustomer(id);
    // console.log(response.data.result);
    this.setState({
      userDetails: response.data.result,
    });
    // console.log(this.state.userDetails);
    this.setState({
      edit_first_name: this.state.userDetails.first_name,
      edit_last_name: this.state.userDetails.last_name,
      editEmail: this.state.userDetails.email_address,
      editPhone: this.state.userDetails.phone_number,
      edit_Id: this.state.userDetails.employee_id,
      editDob: this.state.userDetails.dob,
      editCompany: this.state.userDetails.company_name,
      dayRule: this.state.userDetails.days,
      monthRule: this.state.userDetails.months,
      startTime: this.state.userDetails.start_time,
      endTime: this.state.userDetails.end_time,
      restrictions: this.state.userDetails.access_restrictions,
      activeAcc: this.state.userDetails.user_status,
    });
    this.setValues();
  };

  setValues() {
    const daysList = this.state.editDaysList;
    const dayRule = this.state.dayRule;

    daysList.forEach((day) => {
      dayRule.forEach((rule) => {
        if (day.name === rule) {
          day.isChecked = true;
        }
      });
    });

    this.setState({
      editDaysList: daysList,
    });

    const monthsList = this.state.editMonthsList;
    const monthsRule = this.state.monthRule;

    monthsList.forEach((month) => {
      monthsRule.forEach((rule) => {
        if (month.name === rule) {
          month.isChecked = true;
        }
      });
    });

    this.setState({
      editMonthList: monthsList,
    });

    if (this.state.startTime !== null || this.state.startTime !== "") {
      var starttimeStr = this.state.startTime;
      var timestart = moment(starttimeStr, "HH:mm A");
      this.setState({
        newStartTime: timestart,
      });
    }

    if (this.state.endTime !== null || this.state.endTime !== "") {
      var endtimeStr = this.state.endTime;
      var timeend = moment(endtimeStr, "HH:mm A");
      this.setState({
        newEndTime: timeend,
      });
    }
  }

  deleteUser = (user) => {
    this.setState({ UserID: user, showDeleteUser: true });
  };

  deleteUserProfile = async () => {
    var deleteId = {
      user_id: this.state.UserID,
    };
    // console.log(deleteId);
    const response = await adminMethods.deleteUser(deleteId);

    if (response.data.statusCode === 200) {
      await this.setState({
        showDeleteUser: false,
      });
      this.getUserDetails();
    }
  };

  handleClose = () => {
    this.setState({
      showCreateUser: false,
      showEditUser: false,
      showDeleteUser: false,
    });
  };

  editUserProfile = async () => {
    const daysList = this.state.editDaysList;
    var edaysArr = [];
    daysList.forEach((day) => {
      if (day.isChecked === true) {
        edaysArr.push(day.name);
      }
    });

    const monthsList = this.state.editMonthsList;
    var emonthsArr = [];
    monthsList.forEach((month) => {
      if (month.isChecked === true) {
        emonthsArr.push(month.name);
      }
    });

    if (
      this.state.edit_first_name != "" &&
      this.state.editEmail != "" &&
      this.state.editPhone.length == 10 &&
      this.state.edit_Id != ""
    ) {
      this.setState({ loader: true });

      var editProfile = {
        first_name: this.state.edit_first_name,
        last_name: this.state.edit_last_name,
        phone_number: this.state.editPhone,
        employee_id: this.state.edit_Id,
        dob: this.state.dob,
        user_status: this.state.activeAcc,
        email_address: this.state.editEmail,
        access_restrictions: this.state.restrictions,
        months: emonthsArr.join(),
        days: edaysArr.join(),
        start_time: moment(this.state.newStartTime).format("hh:mm A"),
        end_time: moment(this.state.newEndTime).format("hh:mm A"),
      };
      // console.log(editProfile);
      const response = await adminMethods.updateUserProfile(editProfile);
      if (response.data.statusCode == 200) {
        this.setState({
          open: true,
          message: response.data.message,
          loader: false,
        });
        window.location.reload();
      } else {
        this.setState({
          open: true,
          message: response.data.message,
          loader: false,
        });
      }
    }
  };

  changeUsertype = (event) => {
    this.setState({
      userType: event.target.value,
    });
  };

  backToUsersTable = () => {
    this.resetForm();
    this.setState({
      showCreateUser: false,
      showTable: true,
      showEditUser: false,
    });
  };

  createUserSortHandler = (property) => (event) => {
    let newSortedData = { ...this.state.sortUserData };
    newSortedData.sort_by = property;
    newSortedData.order_by =
      this.state.sortUserData.order_by === "asc" ? "desc" : "asc";

    var sortedData = this.stableSort(
      this.state.userData,
      this.getComparator(newSortedData.order_by, property)
    );

    this.setState({ sortUserData: newSortedData, userData: [...sortedData] });
  };

  stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  descendingComparator(a, b, orderBy) {
    if (orderBy === "id") {
      return b[orderBy] - a[orderBy] < 0 ? -1 : 1;
    } else {
      if (b[orderBy] > a[orderBy]) {
        return -1;
      }
      if (b[orderBy] < a[orderBy]) {
        return 1;
      }
      return 0;
    }
  }

  handleUserChangePage = (event, newPage) => {
    this.setState({
      upage: newPage,
    });
  };

  handleUserChangeRowsPerPage = (event) => {
    this.setState({
      urowsPerPage: parseInt(event.target.value),
      upage: 0,
    });
  };

  handleDeviceChangePage = (event, newPage) => {
    this.setState({
      dpage: newPage,
    });
  };

  handleDeviceChangeRowsPerPage = (event) => {
    this.setState({
      drowsPerPage: parseInt(event.target.value),
      dpage: 0,
    });
  };

  handleUserSearchData = (event) => {
    this.setState({ userFilter: event.target.value });

    var newArray = [];

    this.state.userData.filter((data) => {
      if (event.target.value === "") {
        this.setState({
          userData: this.state.userFilteredData,
          utableCount: this.state.userFilteredData.length,
          upage: 0,
        });
      }
      if (
        data.id === event.target.value ||
        data.fname.toLowerCase() === event.target.value.toLowerCase() ||
        data.email.toLowerCase() === event.target.value.toLowerCase() ||
        data.role.toLowerCase() === event.target.value.toLowerCase() ||
        data.dname.includes(event.target.value)
      ) {
        newArray.push(data);
        this.setState({
          userData: newArray,
          utableCount: newArray.length,
          upage: 0,
        });
      }
    });
  };

  validateEmail(email) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return pattern.test(email);
  }

  validatePassword(pass) {
    const pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
    return pattern.test(pass);
  }

  handleFormValues = async (event) => {
    await this.setState({ [event.target.name]: event.target.value });

    if (event.target.name === "email") {
      // Checks if email is valid or not
      this.setState({ isEmailValid: this.validateEmail(this.state.email) });

      if (!this.state.isEmailValid) {
        this.setState({
          errorEmail: "Please enter valid email",
          alertEmail: true,
        });
      } else {
        this.setState({ alertEmail: false, errorEmail: "" });
      }
    }

    if (event.target.name === "password") {
      // Checks if email is valid or not
      this.setState({
        isPasswordValid: this.validatePassword(this.state.password),
      });

      if (!this.state.isPasswordValid) {
        this.setState({
          errorPassword1: "Please enter a valid password",
          alertPassword1: true,
        });
      } else {
        this.setState({ alertPassword1: false, errorPassword1: "" });
      }
    }
  };

  onChangeStartTime = (time) => {
    // console.log(time);
    this.setState({
      newStartTime: time,
    });
  };

  onChangeEndTime = (time) => {
    // console.log(time);
    this.setState({
      newEndTime: time,
    });
  };

  togglePassword() {
    var pssInput = document.getElementById("inputpassword");
    var passIcon = document.getElementById("toggleIcon");

    if (pssInput.type === "password") {
      pssInput.type = "text";
      passIcon.className = "fa fa-eye";
    } else {
      pssInput.type = "password";
      passIcon.className = "fa fa-eye-slash";
    }
  }

  createUserProfile = async () => {
    if (this.state.first_name === "") {
      this.setState({
        alertFirstName: true,
        errorFirstName: "Last name field required",
        loader: false,
      });
    } else {
      this.setState({
        alertFirstName: false,
      });
    }

    if (this.state.mobile.length != 10) {
      this.setState({
        alertPhone: true,
        phone: "",
        errorPhone: "Please enter a valid cell number",
        loader: false,
      });
    } else {
      this.setState({
        alertPhone: false,
      });
    }

    if (this.state.email === "") {
      this.setState({
        alertEmail: true,
        errorEmail: "Email field required",
        loader: false,
      });
    } else {
      this.setState({
        alertEmail: false,
      });
    }

    if (this.state.password1 === "") {
      this.setState({
        alertPassword: true,
        errorPassword: "Please confirm your password",
        loader: false,
      });
    } else if (this.state.password != this.state.password1) {
      this.setState({
        alertPassword: true,
        errorPassword: "Password and confirm password should be same",
        loader: false,
      });
    } else {
      this.setState({
        alertPassword: false,
      });
    }

    if (this.state.empId === "") {
      this.setState({
        alertID: true,
        errorID: "ID required",
        loader: false,
      });
    } else {
      this.setState({
        alertEmail: false,
      });
    }

    if (this.state.userRole === "") {
      this.setState({
        alertRole: true,
        errorRole: "Select a Role",
        loader: false,
      });
    } else {
      this.setState({
        alertEmail: false,
      });
    }

    if (this.state.device_id === "") {
      this.setState({
        alertDevice: true,
        errorDevice: "Select a Device",
        loader: false,
      });
    } else {
      this.setState({
        alertEmail: false,
      });
    }

    const daysList = this.state.daysList;
    var daysArr = [];
    daysList.forEach((day) => {
      if (day.isChecked === true) {
        daysArr.push(day.name);
      }
    });

    const monthsList = this.state.monthsList;
    var monthsArr = [];
    monthsList.forEach((month) => {
      if (month.isChecked === true) {
        monthsArr.push(month.name);
      }
    });

    if (
      this.state.name != "" &&
      this.state.mobile.length == 10 &&
      this.state.email != "" &&
      this.state.password1 != "" &&
      this.state.password1 === this.state.password &&
      this.state.empId != "" &&
      this.state.userRole != "" &&
      this.state.device_id != ""
    ) {
      this.setState({ loader: true });

      var newProfile = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email_address: this.state.email,
        password: this.state.password1,
        phone_number: this.state.mobile,
        employee_id: this.state.empId,
        dob: this.state.dob,
        user_role: this.state.userRole,
        customer_id: this.state.customer_id,
        user_status: this.state.activeAcc,
        device_id: this.state.device_id,
        access_restrictions: this.state.restrictions,
        months: monthsArr.join(),
        days: daysArr.join(),
        start_time: moment(this.state.newStartTime).format("hh:mm A"),
        end_time: moment(this.state.newEndTime).format("hh:mm A"),
      };
      // console.log(newProfile);
      const response = await adminMethods.addNewCustomer(newProfile);
      if (response.data.statusCode == 200) {
        this.setState({
          open: true,
          message: response.data.message,
          loader: false,
        });
        window.location.reload();
      } else {
        this.setState({
          open: true,
          message: response.data.message,
          loader: false,
        });
      }
    }
  };

  openCustomerTable = () => {
    this.setState({
      showTable: true,
      showCreateUser: false,
      showDeleteUser: false,
      showEditUser: false,
      showDevices: false,
    });
    this.getUserDetails();
  };

  openDeviceTable = async (user_id) => {
    // console.log(user);
    var payload = {
      user_id: user_id,
      query: this.state.deviceFilter,
    };
    this.setState({
      user_id: user_id,
    });
    // console.log(this.state.user_id);
    const response = await adminMethods.getUserDevice(payload);
    // console.log(response.data.result);
    this.setState({
      userDeviceDetails: response.data.result,
    });
    await this.setState({
      showTable: false,
      showCreateUser: false,
      showDeleteUser: false,
      showEditUser: false,
      showDevices: true,
      deviceData: response.data.result,
      dtableCount: response.data.result.length,
      dpage: 0,
    });

    if (response.data.result > 0) {
      this.setState({ noData: false });
    } else {
      this.setState({ noData: true });
    }
  };

  createDeviceSortHandler = (property) => (event) => {
    let newSortedData = { ...this.state.sortDeviceData };
    newSortedData.sort_by = property;
    newSortedData.order_by =
      this.state.sortDeviceData.order_by === "asc" ? "desc" : "asc";

    var sortedData = this.stableSort(
      this.state.deviceData,
      this.getComparator(newSortedData.order_by, property)
    );

    this.setState({
      sortDeviceData: newSortedData,
      deviceData: [...sortedData],
    });
  };

  openDevice = async () => {
    var id = {
      user_id: this.state.user_id,
    };
    const response = await adminMethods.addUserProfileDevices(id);
    if (response.data.statusCode === 200) {
      var details = response.data.result;
      await this.setState({
        deviceList: details,
      });
      // console.log(this.state.deviceList);
    }
    // console.log(this.state.status);
    this.setState({ showCreateDevice: true });
  };

  editDevice = (device) => {
    {
      device.role_id == 1 && this.setState({ editDeviceRole: "1" });
      device.role_id == 2 && this.setState({ editDeviceRole: "2" });
      device.role_id == 3 && this.setState({ editDeviceRole: "3" });
    }
    this.setState({
      showEditDevice: true,
      deviceId: device.device_id,
      deviceName: device.device_name,
    });
  };

  handleEditDevice = async (event) => {
    this.setState({
      deviceName: event.target.value,
    });
  };

  changeStatus = async (event) => {
    await this.setState({
      editDeviceRole: event.target.value,
    });
    // console.log(this.state.editDeviceRole);
  };

  closeCreateDevice = () => {
    this.setState({
      showCreateDevice: false,
      showEditDevice: false,
      showDeleteDevice: false,
    });
  };

  createDeviceProfile = async () => {
    var deviceList = {
      device_ids: this.state.selecteddeviceList,
      role_id: parseInt(this.state.editDeviceRole),
      user_id: this.state.user_id,
    };
    // console.log(deviceList);
    const response = await adminMethods.insertUserDevice(deviceList);
    if (response.data.statusCode === 200) {
      await this.setState({
        showCreateDevice: false,
      });
      this.openDeviceTable(this.state.user_id);
      // var id = {
      //   id: this.state.user_id,
      // };
      // const response = await adminMethods.getUserDevice(id);
      // // console.log(response.data.result);
      // this.setState({
      //   userDeviceDetails: response.data.result,
      // });
    }
  };

  select = (event) => {
    // console.log(this.state.selecteddeviceList);
    // console.log(event.target.value);
    this.setState({ selecteddeviceList: event.target.value });
  };

  // handleChangeMultiple = (event) => {
  //   const { options } = event.target;
  //   const value = [];
  //   for (let i = 0, l = options.length; i < l; i += 1) {
  //     if (options[i].selected) {
  //       value.push(options[i].value);
  //     }
  //   }
  //   console.log(value);
  //   this.setState({
  //     selecteddeviceList: value,
  //   });
  // };

  editDeviceProfile = async () => {
    var updateData = {
      device_id: this.state.deviceId,
      device_name: this.state.deviceName,
      role_id: parseInt(this.state.editDeviceRole),
      user_id: this.state.user_id,
    };

    const response = await adminMethods.editUserDevice(updateData);

    if (response.data.statusCode === 200) {
      this.setState({
        showEditDevice: false,
      });
      this.openDeviceTable(this.state.user_id);
      // var id = {
      //   id: this.state.user_id,
      // };
      // const response = await adminMethods.getUserDevice(id);
      // // console.log(response.data.result);
      // this.setState({
      //   userDeviceDetails: response.data.result,
      // });
    }
  };
  resetForm = () => {
    this.setState({
      selecteddeviceList: [],
      startTime: "",
      endTime: "",
      dayRule: "",
      monthRule: "",

      errorName: "",
      alertFirstName: false,
      errorPhone: "",
      alertPhone: false,
      errorEmail: "",
      alertEmail: false,
      isEmailValid: false,
      alertID: false,
      errorID: "",
      errorPassword: "",
      alertPassword: "",
      errorPassword1: "",
      alertPassword1: false,
      isPasswordValid: false,
      errorRole: "",
      alertRole: false,
      alertDevice: false,
      errorDevice: "",
      activeAcc: "No",
      restrictions: "No",

      name: "",
      email: "",
      password: "",
      password1: "",
      mobile: "",
      empId: "",
      dob: moment(new Date()).format("MM/DD/YYYY"),
      userRole: "",
      device_id: "",

      edit_first_name: "",
      edit_last_name: "",
      editEmail: "",
      editPhone: "",
      edit_Id: "",
      editDob: "",
      editCompany: "",
    });
  };
  render() {
    return (
      <div className="registered-users col-sm-12">
        <div className="text-center registered-title mr-b20">
          <Snackbar
            open={this.state.open}
            autoHideDuration={3000}
            onClose={() => this.setState({ open: false })}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert
              // onClose={handleClose}
              severity="info"
              sx={{ width: "100%" }}
            >
              {this.state.message}
            </Alert>
          </Snackbar>
          <h6>User Profile</h6>
        </div>

        {this.state.showTable ? (
          <div className="mr-t10">
            {this.state.showCustNameId ? null : (
              <div className="mr-t20 row col-md-12">
                <div className="column col-md-7">
                  <h5 className="mr10">
                    {this.state.customerName} ({this.state.customerId})
                  </h5>
                </div>
              </div>
            )}

            <div className="registered-user-table col-md-12">
              <div className="row">
                <div className="column col-md-5 mr-t10">
                  <input
                    type="text"
                    className="form-control black-input-control"
                    placeholder="Search"
                    name="Search"
                    value={this.state.userFilter}
                    // onChange={this.handleUserSearchData}
                    onChange={(e) =>
                      this.setState({ userFilter: e.target.value }, () => {
                        this.getUserDetails();
                      })
                    }
                    // disabled={this.state.noData}
                  />
                </div>

                {this.state?.userData?.length ? (
                  <div align="right" className="column col-md-7">
                    <button
                      type="button"
                      className="btn btn-secondary customer-btn  col-md-6"
                      onClick={this.openUser}
                    >
                      +Create A User Profile
                    </button>
                  </div>
                ) : null}
              </div>

              <TableContainer component={Paper} className="table-style mt-2">
                {this.state?.userData?.length > 0 ? (
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        {this.state.userColumns.map((column, index) => (
                          <TableCell
                            key={column.name}
                            className="table-color"
                            align="center"
                          >
                            <TableSortLabel
                              active={
                                this.state.sortUserData.sort_by === column.name
                              }
                              direction={
                                this.state.sortUserData.sort_by === column.name
                                  ? this.state.sortUserData.order_by
                                  : "asc"
                              }
                              onClick={this.createUserSortHandler(column.name)}
                            >
                              {column.title}
                              {this.state.sortUserData.sort_by ===
                              column.name ? (
                                <span
                                  style={{
                                    border: 0,
                                    clip: "rect(0 0 0 0)",
                                    height: 1,
                                    overflow: "hidden",
                                    padding: 0,
                                    position: "absolute",
                                    top: 20,
                                    width: 1,
                                  }}
                                ></span>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.userData
                        .slice(
                          this.state.upage * this.state.urowsPerPage,
                          this.state.upage * this.state.urowsPerPage +
                            this.state.urowsPerPage
                        )
                        .map((row) => (
                          <TableRow key={row.id} hover>
                            <TableCell className="table-color" align="center">
                              {row.id}
                            </TableCell>
                            <TableCell className="table-color" align="center">
                              {row.fname}
                            </TableCell>
                            <TableCell className="table-color" align="center">
                              {row.lname}
                            </TableCell>
                            <TableCell className="table-color" align="center">
                              {row.email}
                            </TableCell>
                            <TableCell className="table-color" align="center">
                              <button
                                className="user-button"
                                onClick={() =>
                                  this.openDeviceTable(row?.user_id)
                                }
                              >
                                {row.dname}
                              </button>
                            </TableCell>
                            {/* <TableCell className="table-color" align="center">
                              {row.role}
                            </TableCell> */}

                            <TableCell className="table-color" align="center">
                              {" "}
                              <i
                                className="fa fa-edit"
                                aria-hidden="true"
                                style={
                                  row.can_edit == "0"
                                    ? { color: "gray", pointer: "none" }
                                    : null
                                }
                                onClick={() =>
                                  row.can_edit ? this.editUser(row) : null
                                }
                              ></i>
                              <i
                                className="fa fa-trash mr-l10"
                                style={
                                  row.can_edit == "0" ? { color: "gray" } : null
                                }
                                aria-hidden="true"
                                onClick={() =>
                                  row.can_edit == 1
                                    ? this.deleteUser(row.user_id)
                                    : null
                                }
                              ></i>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          colSpan={8}
                          count={this.state.utableCount}
                          rowsPerPage={this.state.urowsPerPage}
                          page={this.state.upage}
                          SelectProps={{
                            inputProps: { "aria-label": "rows per page" },
                            native: true,
                          }}
                          onChangePage={this.handleUserChangePage}
                          onChangeRowsPerPage={this.handleUserChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                ) : this.state.noData ? (
                  <div className="text-center registered-title mr-b20 mr-t20">
                    <label style={{ color: "#fff" }}>No data to display</label>
                  </div>
                ) : (
                  <CircularProgress
                    style={{ margin: "10% 0% 10% 50%", color: "#c12a37" }}
                  />
                )}
              </TableContainer>
            </div>
          </div>
        ) : null}

        {/* -----------------------------------------------------------------Create New User */}
        {this.state.showCreateUser ? (
          <div className="mr-t10">
            <p align="left" onClick={this.backToUsersTable}>
              <KeyboardBackspaceIcon /> Back to Users
            </p>

            <div className="mr-t20 form-group row">
              <label for="inputfname" className="col-sm-3 col-form-label">
                First Name <em className="mandatory">* </em>
              </label>
              <div className="col-sm-9">
                <input
                  initialValue={this.state.first_name}
                  name="first_name"
                  type="text"
                  className="user-mgmt-label"
                  id="inputfname"
                  onChange={this.handleFormValues}
                  required
                />
                {this.state.alertFirstName ? (
                  <span className="color-red">
                    <i>{this.state.errorFirstName}</i>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="mr-t20 form-group row">
              <label for="inputfname" className="col-sm-3 col-form-label">
                Last Name
              </label>
              <div className="col-sm-9">
                <input
                  initialValue={this.state.last_name}
                  name="last_name"
                  type="text"
                  className="user-mgmt-label"
                  id="inputfname"
                  onChange={this.handleFormValues}
                  required
                />
                {this.state.alertFirstName ? (
                  <span className="color-red">
                    <i>{this.state.errorLastName}</i>
                  </span>
                ) : null}
              </div>
            </div>
            {/* <div className="form-group row">
              <label for="inputlname" className="col-sm-3 col-form-label">
                Last Name <em className="mandatory">* </em>
              </label>
              <div className="col-sm-9">
                <input type="text" className="user-mgmt-label" id="inputlname" />
              </div>
            </div>
            <div className="form-group row">
              <label for="inputuname" className="col-sm-3 col-form-label">
                User Name <em className="mandatory">* </em>
              </label>
              <div className="col-sm-9">
                <input type="text" className="user-mgmt-label" id="inputuname" />
              </div>
            </div> */}
            <div className="form-group row">
              <label for="inputemail" className="col-sm-3 col-form-label">
                Email <em className="mandatory">* </em>
              </label>
              <div className="col-sm-9">
                <input
                  name="email"
                  initialValue={this.state.email}
                  onChange={this.handleFormValues}
                  type="text"
                  className="user-mgmt-label"
                  id="inputemail"
                />
                {this.state.alertEmail ? (
                  <span className="color-red">
                    <i>{this.state.errorEmail}</i>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="form-group row ">
              <label for="inputpassword" className="col-sm-3 col-form-label">
                Password <em className="mandatory">* </em>
              </label>
              <div className="col-sm-9">
                <input
                  initialValue={this.state.password}
                  name="password"
                  onChange={this.handleFormValues}
                  type="password"
                  className="user-mgmt-label"
                  id="inputpassword"
                />
                {this.state.alertPassword1 ? (
                  <span className="color-red">
                    <i>{this.state.errorPassword1}</i>
                  </span>
                ) : null}
                <a href="#" onClick={this.togglePassword}>
                  <i className="fa fa-eye-slash" id="toggleIcon"></i>
                </a>
                <small id="inputpassword" className="form-text text-muted">
                  Your password must be 8-20 characters long, contain letters
                  and numbers, and must not contain spaces, special characters,
                  or emoji.
                </small>
              </div>
            </div>
            <div className="form-group row ">
              <label for="inputpassword" className="col-sm-3 col-form-label">
                Confirm Password <em className="mandatory">* </em>
              </label>
              <div className="col-sm-9">
                <input
                  initialValue={this.state.password1}
                  name="password1"
                  onChange={this.handleFormValues}
                  type="password"
                  className="user-mgmt-label"
                  id="inputpassword"
                />
                {this.state.alertPassword ? (
                  <span className="color-red">
                    <i>{this.state.errorPassword}</i>
                  </span>
                ) : null}
              </div>
            </div>
            {/* <div className="form-group row">
              <label for="inputoffice" className="col-sm-3 col-form-label">
                Office Phone <em className="mandatory">* </em>
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="user-mgmt-label"
                  id="inputoffice"
                />
              </div>
            </div> */}
            <div className="form-group row">
              <label for="inputmobile" className="col-sm-3 col-form-label">
                Mobile Phone <em className="mandatory">* </em>
              </label>
              <div className="col-sm-9">
                <input
                  initialValue={this.state.mobile}
                  name="mobile"
                  onChange={this.handleFormValues}
                  type="number"
                  className="user-mgmt-label"
                  id="inputmobile"
                />
                {this.state.alertPhone ? (
                  <span className="color-red">
                    <i>{this.state.errorPhone}</i>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label for="inputdept" className="col-sm-3 col-form-label">
                Employee Id <em className="mandatory">* </em>
              </label>
              <div className="col-sm-9">
                <input
                  initialValue={this.state.empId}
                  name="empId"
                  onChange={this.handleFormValues}
                  className="user-mgmt-label"
                  id="inputID"
                />
                {this.state.alertID ? (
                  <span className="color-red">
                    <i>{this.state.errorID}</i>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label for="inputdept" className="col-sm-3 col-form-label">
                Date of birth <em className="mandatory">* </em>
              </label>
              <div className="col-sm-9">
                <MuiPickersUtilsProvider utils={Moment}>
                  <DatePicker
                    value={this.state.dob}
                    name="dob"
                    onChange={this.handleDateOfBirth}
                    format="MM/DD/yyyy"
                    className="registration_inputs"
                    inputVariant="outlined"
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className="form-group row">
              <label for="inputurole" className="col-sm-3 col-form-label">
                User Role <em className="mandatory">* </em>
              </label>
              <div className="col-sm-9">
                <Select
                  value={this.state.userRole}
                  onChange={this.userRole}
                  className="user-mgmt-label my-1 mr-sm-2"
                  id="inputurole"
                >
                  <MenuItem selected>Choose...</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="guest">Guest</MenuItem>
                </Select>
                {this.state.alertRole ? (
                  <span className="color-red">
                    <i>{this.state.errorRole}</i>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label for="inputcname" className="col-sm-3 col-form-label">
                Company Name
              </label>
              <div className="col-sm-9">
                <input
                  value={this.state.companyName}
                  type="text"
                  className="user-mgmt-label"
                  id="inputcname"
                />
              </div>
            </div>
            <div className="row">
              <label for="inputactive" className="col-sm-3 col-form-label">
                Active
              </label>
              <div className="col-sm-9 pd-l20">
                <RadioGroup
                  aria-label="activeAcc"
                  name="activeAcc"
                  value={this.state.activeAcc}
                  onChange={this.changeActiveAccount}
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                    checked={this.state.activeAcc === "Yes"}
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="No"
                    checked={this.state.activeAcc === "No"}
                  />
                </RadioGroup>
              </div>
            </div>
            <div className="form-group row">
              <label for="inputurole" className="col-sm-3 col-form-label">
                Device <em className="mandatory">* </em>
              </label>
              <div className="col-sm-9">
                <Select
                  id="invite"
                  className="fp_modal_input"
                  value={this.state.device_id}
                  onChange={this.selectDevice}
                >
                  {this.state.deviceList.map((device, index) => (
                    <MenuItem value={device.device_id} key={index}>
                      {device.device_name}
                    </MenuItem>
                  ))}
                </Select>
                {this.state.alertDevice ? (
                  <span className="color-red">
                    <i>{this.state.errorDevice}</i>
                  </span>
                ) : null}
              </div>
            </div>

            <div className="row">
              <label for="inputactive" className="col-sm-3 col-form-label">
                Access Restrictions
              </label>
              <div className="col-sm-9 pd-l20">
                <RadioGroup
                  aria-label="activeAcc"
                  name="activeAcc"
                  value={this.state.restrictions}
                  onChange={this.changeAccessRestrictions}
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                    checked={this.state.restrictions === "Yes"}
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="No"
                    checked={this.state.restrictions === "No"}
                  />
                </RadioGroup>
              </div>
            </div>
            {this.state.restrictions === "Yes" ? (
              <div>
                <div className="row">
                  <label for="inputmonth" className="col-sm-3 col-form-label">
                    Months <em className="mandatory">* </em>
                  </label>
                  <div className="col-sm-9" style={{ marginLeft: "-5px" }}>
                    {this.state.monthsList.map((element, i) => (
                      <label className="checkbox-label" key={i}>
                        <Checkbox
                          key={element.id}
                          value={element.name}
                          onChange={this.handleMonthsChange}
                          checked={element.isChecked}
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                        />
                        {element.name}
                      </label>
                    ))}
                  </div>
                </div>
                <div className="row">
                  <label for="inputday" className="col-sm-3 col-form-label">
                    Weekdays <em className="mandatory">* </em>
                  </label>
                  <div className="col-sm-9" style={{ marginLeft: "-5px" }}>
                    {this.state.daysList.map((element, i) => (
                      <label className="checkbox-label" key={i}>
                        <Checkbox
                          key={element.id}
                          value={element.name}
                          onChange={this.handleDaysChange}
                          checked={element.isChecked}
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                        />
                        {element.name}
                      </label>
                    ))}
                  </div>
                </div>
                <div className="row mr-t10">
                  <label for="inputtime" className="col-sm-3 col-form-label">
                    System Access Time
                  </label>
                  <div className="col-sm-9">
                    <div className="row">
                      <span className="col-sm-6">
                        <label
                          for="inputtime"
                          className="col-form-label pd-r10"
                        >
                          From
                        </label>
                        <div id="inputurole">
                          <MuiPickersUtilsProvider utils={Moment}>
                            <KeyboardTimePicker
                              openTo="hours"
                              id="time-picker"
                              onChange={this.onChangeStartTime}
                              value={this.state.newStartTime}
                              KeyboardButtonProps={{
                                "aria-label": "change time",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </span>
                      <span className="col-sm-6">
                        <label
                          for="inputtime"
                          className="col-form-label pd-r10"
                        >
                          To
                        </label>
                        <div id="inputurole" value="1">
                          <MuiPickersUtilsProvider utils={Moment}>
                            <KeyboardTimePicker
                              openTo="hours"
                              id="time-picker"
                              KeyboardButtonProps={{
                                "aria-label": "change time",
                              }}
                              value={this.state.newEndTime}
                              onChange={this.onChangeEndTime}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <p align="center" className="mr-t30">
              <button
                type="button"
                className="red-btn col-md-4 f-width"
                onClick={this.createUserProfile}
                style={{ height: "50px" }}
                disabled={this.state.loader}
              >
                <h6>
                  Save &nbsp;
                  {this.state.loader ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : null}
                </h6>
              </button>
            </p>
          </div>
        ) : null}

        {/* ----------------------------------------------------------Edit Users */}
        {this.state.showEditUser ? (
          <div className="mr-t10">
            <p align="left" onClick={this.backToUsersTable}>
              <KeyboardBackspaceIcon /> Back to Users
            </p>

            <div className="mr-t20 form-group row">
              <label for="inputfname" className="col-sm-3 col-form-label">
                First Name <em className="mandatory">* </em>
              </label>
              <div className="col-sm-9">
                <input
                  value={this.state.edit_first_name}
                  name="edit_first_name"
                  type="text"
                  className="user-mgmt-label"
                  id="inputfname"
                  onChange={(e) =>
                    this.setState({ edit_first_name: e.target.value })
                  }
                  required
                />
                {this.state.alertFirstName ? (
                  <span className="color-red">
                    <i>{this.state.errorFirstName}</i>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="mr-t20 form-group row">
              <label for="inputfname" className="col-sm-3 col-form-label">
                Last Name
              </label>
              <div className="col-sm-9">
                <input
                  value={this.state.edit_last_name}
                  name="edit_last_name"
                  type="text"
                  className="user-mgmt-label"
                  id="inputfname"
                  onChange={(e) =>
                    this.setState({ edit_last_name: e.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div className="form-group row">
              <label for="inputemail" className="col-sm-3 col-form-label">
                Email
              </label>
              <div className="col-sm-9">
                <input
                  name="email"
                  value={this.state.editEmail}
                  onChange={this.handleFormValues}
                  type="text"
                  className="user-mgmt-label"
                  id="inputemail"
                />
                {this.state.alertEmail ? (
                  <span className="color-red">
                    <i>{this.state.errorEmail}</i>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label for="inputmobile" className="col-sm-3 col-form-label">
                Mobile Phone <em className="mandatory">* </em>
              </label>
              <div className="col-sm-9">
                <input
                  value={this.state.editPhone}
                  name="mobile"
                  onChange={(e) => this.setState({ editPhone: e.target.value })}
                  type="number"
                  className="user-mgmt-label"
                  id="inputmobile"
                />
                {this.state.alertPhone ? (
                  <span className="color-red">
                    <i>{this.state.errorPhone}</i>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label for="inputdept" className="col-sm-3 col-form-label">
                Employee Id <em className="mandatory">* </em>
              </label>
              <div className="col-sm-9">
                <input
                  value={this.state.edit_Id}
                  name="empId"
                  onChange={(e) => this.setState({ edit_Id: e.target.value })}
                  className="user-mgmt-label"
                  id="inputID"
                />
                {this.state.alertID ? (
                  <span className="color-red">
                    <i>{this.state.errorID}</i>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="form-group row">
              <label for="inputdept" className="col-sm-3 col-form-label">
                Date of birth <em className="mandatory">* </em>
              </label>
              <div className="col-sm-9">
                <MuiPickersUtilsProvider utils={Moment}>
                  <DatePicker
                    value={this.state.editDob}
                    name="dob"
                    onChange={this.handleDOB}
                    format="MM/DD/yyyy"
                    className="registration_inputs"
                    inputVariant="outlined"
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className="form-group row">
              <label for="inputcname" className="col-sm-3 col-form-label">
                Company Name
              </label>
              <div className="col-sm-9">
                <input
                  value={this.state.editCompany}
                  type="text"
                  className="user-mgmt-label"
                  id="inputcname"
                />
              </div>
            </div>
            <div className="row">
              <label for="inputactive" className="col-sm-3 col-form-label">
                Active
              </label>
              <div className="col-sm-9 pd-l20">
                <RadioGroup
                  aria-label="activeAcc"
                  name="activeAcc"
                  value={this.state.activeAcc}
                  onChange={this.changeActiveAccount}
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                    checked={this.state.activeAcc === "Yes"}
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="No"
                    checked={this.state.activeAcc === "No"}
                  />
                </RadioGroup>
              </div>
            </div>

            <div className="row">
              <label for="inputactive" className="col-sm-3 col-form-label">
                Access Restrictions
              </label>
              <div className="col-sm-9 pd-l20">
                <RadioGroup
                  aria-label="activeAcc"
                  name="activeAcc"
                  value={this.state.restrictions}
                  onChange={this.changeAccessRestrictions}
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                    checked={this.state.restrictions === "Yes"}
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="No"
                    checked={this.state.restrictions === "No"}
                  />
                </RadioGroup>
              </div>
            </div>
            {this.state.restrictions === "Yes" ? (
              <div>
                <div className="row">
                  <label for="inputmonth" className="col-sm-3 col-form-label">
                    Months <em className="mandatory">* </em>
                  </label>
                  <div className="col-sm-9" style={{ marginLeft: "-5px" }}>
                    {this.state.editMonthsList.map((element, i) => (
                      <label className="checkbox-label" key={i}>
                        <Checkbox
                          key={element.id}
                          value={element.name}
                          onChange={this.handleEditMonthsChange}
                          checked={element.isChecked}
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                        />
                        {element.name}
                      </label>
                    ))}
                  </div>
                </div>
                <div className="row">
                  <label for="inputday" className="col-sm-3 col-form-label">
                    Weekdays <em className="mandatory">* </em>
                  </label>
                  <div className="col-sm-9" style={{ marginLeft: "-5px" }}>
                    {this.state.editDaysList.map((element, i) => (
                      <label className="checkbox-label" key={i}>
                        <Checkbox
                          key={element.id}
                          value={element.name}
                          onChange={this.handleEditDaysChange}
                          checked={element.isChecked}
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                        />
                        {element.name}
                      </label>
                    ))}
                  </div>
                </div>
                <div className="row mr-t10">
                  <label for="inputtime" className="col-sm-3 col-form-label">
                    System Access Time
                  </label>
                  <div className="col-sm-9">
                    <div className="row">
                      <span className="col-sm-6">
                        <label
                          for="inputtime"
                          className="col-form-label pd-r10"
                        >
                          From
                        </label>
                        <div id="inputurole">
                          <MuiPickersUtilsProvider utils={Moment}>
                            <KeyboardTimePicker
                              openTo="hours"
                              id="time-picker"
                              onChange={this.onChangeStartTime}
                              value={this.state.newStartTime}
                              KeyboardButtonProps={{
                                "aria-label": "change time",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </span>
                      <span className="col-sm-6">
                        <label
                          for="inputtime"
                          className="col-form-label pd-r10"
                        >
                          To
                        </label>
                        <div id="inputurole" value="1">
                          <MuiPickersUtilsProvider utils={Moment}>
                            <KeyboardTimePicker
                              openTo="hours"
                              id="time-picker"
                              KeyboardButtonProps={{
                                "aria-label": "change time",
                              }}
                              value={this.state.newEndTime}
                              onChange={this.onChangeEndTime}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <p align="center" className="mr-t30">
              <button
                type="button"
                className="red-btn col-md-4 f-width"
                onClick={this.editUserProfile}
                style={{ height: "50px" }}
                disabled={this.state.loader}
              >
                <h6>
                  Save &nbsp;
                  {this.state.loader ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : null}
                </h6>
              </button>
            </p>
          </div>
        ) : null}

        {/* ---------------------------------------------Delete User */}
        {this.state.showDeleteUser ? (
          <Modal
            open={this.state.showDeleteUser}
            aria-labelledby="DeleteUserProfile"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Delete User?
                  </h5>
                </div>

                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">
                      Are you sure you want to delete this user?
                      <br />
                      <br />
                      <i>Note: This action cannot be undone!</i>
                    </h6>
                  </div>
                </div>

                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.deleteUserProfile}
                    disabled={this.state.loader}
                  >
                    Delete &nbsp;
                    {this.state.loader ? (
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : null}
                  </button>
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}

        {/* ----------------------------------------------------Show Devices List */}
        {this.state.showDevices ? (
          <div className="registered-users ">
            <div className="text-center registered-title">
              <h6> Device Management </h6>
            </div>

            <div className="mr-t10 row ">
              <div
                className="column pd10 col-md-8"
                onClick={this.openCustomerTable}
              >
                <KeyboardBackspaceIcon /> Back to users
              </div>
            </div>

            <div className="registered-user-table">
              <div className="row">
                <div className="column col-md-5 mr-t10">
                  <input
                    type="text"
                    className="form-control black-input-control"
                    placeholder="Search"
                    name="Search"
                    value={this.state.deviceFilter}
                    onChange={(e) =>
                      this.setState({ deviceFilter: e.target.value }, () => {
                        this.openDeviceTable(this.state.user_id);
                      })
                    }
                  />
                </div>

                <div align="right" className="column col-md-7">
                  <button
                    type="button"
                    className="btn btn-secondary customer-btn  col-md-6"
                    onClick={this.openDevice}
                  >
                    +Add New Device
                  </button>
                </div>
              </div>
              <TableContainer component={Paper} className="table-style mr-t10">
                {this.state.deviceData.length > 0 ? (
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        {this.state.deviceColumns.map((column, index) => (
                          <TableCell
                            key={column.name}
                            className="table-color"
                            align="center"
                          >
                            <TableSortLabel
                              active={
                                this.state.sortDeviceData.sort_by ===
                                column.name
                              }
                              direction={
                                this.state.sortDeviceData.sort_by ===
                                column.name
                                  ? this.state.sortDeviceData.order_by
                                  : "asc"
                              }
                              onClick={this.createDeviceSortHandler(
                                column.name
                              )}
                            >
                              {column.title}
                              {this.state.sortDeviceData.sort_by ===
                              column.name ? (
                                <span
                                  style={{
                                    border: 0,
                                    clip: "rect(0 0 0 0)",
                                    height: 1,
                                    overflow: "hidden",
                                    padding: 0,
                                    position: "absolute",
                                    top: 20,
                                    width: 1,
                                  }}
                                ></span>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.deviceData
                        .slice(
                          this.state.dpage * this.state.drowsPerPage,
                          this.state.dpage * this.state.drowsPerPage +
                            this.state.drowsPerPage
                        )
                        .map((row) => (
                          <TableRow key={row.device_id} hover>
                            <TableCell className="table-color" align="center">
                              {row.device_id}
                            </TableCell>
                            <TableCell className="table-color" align="center">
                              {row.device_name}
                            </TableCell>
                            <TableCell className="table-color" align="center">
                              <span align="left">
                                {row.role_id === 3 && "Owner"}
                                {row.role_id === 1 && "Admin"}
                                {row.role_id === 2 && "Guest"}
                              </span>
                            </TableCell>
                            {/* <TableCell className="table-color" align="center">
                            {row.admin}
                          </TableCell> */}
                            <TableCell className="table-color" align="center">
                              <i
                                className="fa fa-edit"
                                aria-hidden="true"
                                onClick={() => this.editDevice(row)}
                              ></i>
                              <i
                                className="fa fa-trash mr-l10"
                                aria-hidden="true"
                                onClick={() => this.deleteDevice(row)}
                              ></i>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          colSpan={8}
                          count={this.state.dtableCount}
                          rowsPerPage={this.state.drowsPerPage}
                          page={this.state.dpage}
                          SelectProps={{
                            inputProps: { "aria-label": "rows per page" },
                            native: true,
                          }}
                          onChangePage={this.handleDeviceChangePage}
                          onChangeRowsPerPage={
                            this.handleDeviceChangeRowsPerPage
                          }
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                ) : this.state.noData ? (
                  <div className="text-center registered-title mr-b20 mr-t20">
                    <label style={{ color: "#fff" }}>No data to display</label>
                  </div>
                ) : (
                  <CircularProgress
                    style={{ margin: "10% 0% 10% 50%", color: "#c12a37" }}
                  />
                )}
              </TableContainer>
            </div>

            {/* ---------------------------------------------Create Device */}
            {this.state.showCreateDevice ? (
              <Modal
                open={this.state.showCreateDevice}
                aria-labelledby="CreateDevice"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content modal_fp_content">
                    <div className="modal-header forget_pw_header">
                      <button
                        type="button"
                        className="close modal_fp_close_btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeCreateDevice}
                      >
                        <span aria-hidden="true">
                          <svg
                            width="10"
                            height="18"
                            viewBox="0 0 10 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </button>
                      <h5
                        className="modal-title modal_fp_title "
                        id="exampleModalLabel"
                      >
                        Add Device
                      </h5>
                    </div>

                    <div className="modal-body fp_modal_body">
                      <div className="register_detail_wrap ">
                        <div className="col-12 ">
                          <h6>
                            Device Name<em className="mandatory">* </em>
                          </h6>
                          <Select
                            className="fp_modal_input"
                            onChange={this.select}
                            renderValue={(selected) => selected.join(", ")}
                            value={this.state.selecteddeviceList}
                            input={<Input id="select-multiple" />}
                            multiple
                          >
                            {this.state.deviceList.map((device, index) => (
                              <MenuItem value={device.device_id} key={index}>
                                <Checkbox
                                  checked={
                                    this.state.selecteddeviceList.indexOf(
                                      device.device_id
                                    ) > -1
                                  }
                                  // checked={true}
                                />
                                {device.device_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                        <div className="col-12 ">
                          <h6>
                            Role<em className="mandatory">* </em>
                          </h6>
                          <Select
                            id="invite"
                            className="fp_modal_input"
                            onChange={this.changeStatus}
                            value={this.state.editDeviceRole}
                          >
                            <MenuItem value="1" name="Active">
                              Admin
                            </MenuItem>
                            <MenuItem value="2" name="In-active">
                              Guest
                            </MenuItem>
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer footer_fp_modal">
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.createDeviceProfile}
                        disabled={this.state.loader}
                      >
                        Confirm &nbsp;
                        {this.state.loader ? (
                          <i className="fa fa-spinner fa-spin"></i>
                        ) : null}
                      </button>
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.closeCreateDevice}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            ) : null}

            {/* -----------------------------------------------Edit Device Details */}
            {this.state.showEditDevice ? (
              <Modal
                open={this.state.showEditDevice}
                aria-labelledby="EditDeviceProfile"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content modal_fp_content">
                    <div className="modal-header forget_pw_header">
                      <button
                        type="button"
                        className="close modal_fp_close_btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeCreateDevice}
                      >
                        <span aria-hidden="true">
                          <svg
                            width="10"
                            height="18"
                            viewBox="0 0 10 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </button>
                      <h5
                        className="modal-title modal_fp_title "
                        id="exampleModalLabel"
                      >
                        Edit Device Details
                      </h5>
                    </div>

                    <div className="modal-body fp_modal_body">
                      <div className=" register_detail_wrap ">
                        <div className="col-12 ">
                          <h6>
                            Device Id<em className="mandatory">* </em>
                          </h6>

                          <input
                            type="text"
                            className="customer-modal-inputs"
                            name="deviceId"
                            value={this.state.deviceId}
                            disabled
                          />
                        </div>
                        <div className="col-12">
                          <h6>
                            Device Name<em className="mandatory">* </em>
                          </h6>
                          <input
                            type="text"
                            className="customer-modal-inputs"
                            name="deviceName"
                            value={this.state.deviceName}
                            onChange={this.handleEditDevice}
                          />
                        </div>
                        <div className="col-12">
                          <h6>
                            Role<em className="mandatory">* </em>
                          </h6>
                          <Select
                            id="invite"
                            className="fp_modal_input"
                            onChange={this.changeStatus}
                            value={this.state.editDeviceRole}
                          >
                            <MenuItem value="1" name="Active">
                              Admin
                            </MenuItem>
                            <MenuItem value="2" name="In-active">
                              Guest
                            </MenuItem>
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer footer_fp_modal">
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.editDeviceProfile}
                        disabled={this.state.loader}
                      >
                        Update &nbsp;
                        {this.state.loader ? (
                          <i className="fa fa-spinner fa-spin"></i>
                        ) : null}
                      </button>
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.closeCreateDevice}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            ) : null}

            {/* -------------------------------------------------Delete User Device */}
            {this.state.showDeleteDevice ? (
              <Modal
                open={this.state.showDeleteDevice}
                aria-labelledby="DeleteDeleteProfile"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
                  role="document"
                >
                  <div className="modal-content modal_fp_content">
                    <div className="modal-header forget_pw_header">
                      <button
                        type="button"
                        className="close modal_fp_close_btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closeCreateDevice}
                      >
                        <span aria-hidden="true">
                          <svg
                            width="10"
                            height="18"
                            viewBox="0 0 10 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </button>
                      <h5
                        className="modal-title modal_fp_title "
                        id="exampleModalLabel"
                      >
                        Delete Device?
                      </h5>
                    </div>

                    <div className="modal-body fp_modal_body">
                      <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                        <h6 className="delete-dialog">
                          Are you sure you want to delete this device?
                          <br />
                          <br />
                          <i>Note: This action cannot be undone!</i>
                        </h6>
                      </div>
                    </div>

                    <div className="modal-footer footer_fp_modal">
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.deleteDeviceProfile}
                        disabled={this.state.loader}
                      >
                        Delete &nbsp;
                        {this.state.loader ? (
                          <i className="fa fa-spinner fa-spin"></i>
                        ) : null}
                      </button>
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.closeCreateDevice}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            ) : null}
          </div>
        ) : null}

        {/* --------------------------------------logout popup */}
        {this.state.showLogout ? (
          <Modal
            open={this.state.showLogout}
            aria-labelledby="DeleteUserProfile"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Session Expired
                  </h5>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">
                      Please login again.
                      <br />
                      <br />
                    </h6>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.logoutUser}
                    // disabled={this.state.loader}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default UserManagement;
