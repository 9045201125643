import React, { Component } from "react";
import fileSharingMethods from "../../../services/fileSharingApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { IconButton } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FolderIcon from "@material-ui/icons/Folder";
import moment from "moment";
import bytes from "bytes";
import {toast} from "react-toastify"


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
let currentPath1 = "";
class McModal extends Component {
  // state = {
  //   fileStructureList: [],
  //   devicesList: [],
  //   group: JSON.parse(sessionStorage.getItem("Group")),
  //   selectedDevice: this.props.selectedDevice,
  //   currentPath: "",
  //   userDevice: "",
  //   device_id: this.props.initialPath,
  //   desPath: "",
  // };

  // componentDidMount() {
  //   this.setState({ currentPath: this.props.initialPath });
  //   console.log(this.props.location);
  //   this.fetchHarwareDevicesList();
  //   this.fetchFileStructure(this.state.device_id); // from Parent Class
  // }

  // fetchFileStructure = async (filename) => {
  //   console.log(this.state);
  //   this.setState({
  //     loader: true,
  //   });
  //   let pathObject = {
  //     path: filename,
  //   };

  //   const response = await fileSharingMethods.getFileStructure(pathObject);
  //   console.log(filename);
  //   if (response.data.statusCode === 200) {
  //     this.setState({
  //       fileStructureList: response.data.result,
  //       loader: false,
  //     });
  //     // console.log(this.state.desPath, "<<<<<<<<<<<<<<");
  //   }
  // };

  // changeUserDevice = (event) => {
  //   console.log(event.target.value);
  //   this.setState({
  //     device_id: event.target.value,
  //   });
  // };

  // redirectToSubFileStructure = (data) => {
  //   console.log("data", data);
  //   // this.props.history.push({
  //   //   pathname: "/dashboard/subFileSharing",
  //   //   state: [{ selectedDevice: this.state.selectedDevice, folder: data }],
  //   // });
  //   this.setState({ currentPath: data.filename });
  //   this.setState({ desPath: data.filename });
  //   console.log(this.state.currentPath);
  //   this.fetchFileStructure(data.filename);
  // };

  // fetchHarwareDevicesList = async () => {
  //   let getDevices = {
  //     device_id: this.state.device_id,
  //     group_id: JSON.stringify(this.state.group.group_id),
  //   };

  //   const response = await fileSharingMethods.getHardwareDevicesList(getDevices);

  //   if (response.data.statusCode === 200) {
  //     this.setState({
  //       devicesList: response.data.result,
  //       devices: response.data.result,
  //     });
  //   }
  // };

  // goBack = () => {
  //   let currentUrl = this.state.currentPath;
  //   this.setState({ currentPath: currentUrl.substring(0, currentUrl.lastIndexOf("/")) });
  //   this.redirectToSubFileStructure(this.state.currentPath);
  // };

  state = {
    currentPath: "",
    fileStructureList: [],
    message: "",
    selectedDevice: this.props.selectedDevice,
    userDevice: "",
    devicesList: [],
    devices: [],
    group: JSON.parse(sessionStorage.getItem("Group")),
    source: [],
    showLoader: false,
  };

  componentDidMount() {
    if (this.props.initialArray.length > 1) {
      this.setState({ source: this.props.initialArray });
    } else this.setState({ source: [this.props.sourcePath] });

    this.setState({ userDevice: this.props.selectedDevice.device_id });
    currentPath1 = "/dRisk/" + this.state.selectedDevice.device_id;
    this.setState({ currentPath: this.state.selectedDevice.device_id }, () => {
      this.getFileStructureList(currentPath1);
    });
    console.log(this.props.initialArray);
    // this.getFileStructureList(this.props.initialPath);
    this.fetchHarwareDevicesList();
  }

  getFileStructureList = async (pathName) => {
    this.setState({
      showLoader: true,
    });
    const response = await fileSharingMethods.folderStructure({
      path: pathName,
    });
    console.log(pathName);
    if (response.data.statusCode === 200) {
      this.setState({
        fileStructureList: response.data.result,
        loader: false,
        showLoader: false,
      });
    }
    // this.setState({ curentPath: response.data.filename });
  };

  goForwardInFileStructure = async (path) => {
    this.setState({ loader: true });
    currentPath1 = currentPath1 + "/" + path;
    // this.setState({ currentPath: this.state.currentPath + "/" + path });
    // this.setState({ currentPath: path });
    this.getFileStructureList(currentPath1);
  };

  goBackwardInFileStructure = async () => {
    this.setState({ loader: true });
    // let currentUrl = this.state.currentPath;
    // this.setState({ currentPath: currentUrl.substring(0, currentUrl.lastIndexOf("/")) });
    currentPath1 = currentPath1.substring(0, currentPath1.lastIndexOf("/"));
    console.log(this.state.currentPath, "goback");
    this.getFileStructureList(currentPath1);
  };

  fetchHarwareDevicesList = async () => {
    let getDevices = {
      device_id: this.state.device_id,
      group_id: JSON.stringify(this.state.group.group_id),
    };
    const response = await fileSharingMethods.getHardwareDevicesList(
      getDevices
    );
    if (response.data.statusCode === 200) {
      this.setState({
        devicesList: response.data.result,
        devices: response.data.result,
      });
      console.log(this.state.devicesList);
      console.log(this.state.devices);
    }
  };

  changeUserDevice = (event) => {
    console.log(event.target.value);
    // this.setState({
    //   currentPath1: event.target.value,
    // });
    this.setState({ loader: true });
    currentPath1 = "/dRisk/" + event.target.value;
    this.getFileStructureList(currentPath1);
    this.setState({ userDevice: event.target.value });

    console.log(currentPath1);
  };

  moveFile = async (file) => {
    // console.log(file);
    var updateObject = {
      source: this.state.source,
      dest: currentPath1,
    };
    this.setState({
      loader: true,
    });
    const response = await fileSharingMethods.moveFiles(updateObject);
    if (response.data.statusCode === 400) {
      // this.props.setState({
      //   open: true,
      //   message: response.data.message,
      //   loader: false,
      // });
      toast.error(response.data.message);

    }
    if (response.data.statusCode === 200) {
      // this.props.setState({
      //   open: true,
      //   message: "Succefully moved",
      //   loader: false,
      // });
      this.resetValues();
      toast.success(response.data.message)

    }
    else {
      toast.error(response.data.message);
    }
    this.handleClose()
    this.fetchFileStructure()

    // console.log("moves");
    this.setState({ loader: true });
    this.getFileStructureList(currentPath1);
    this.setState({ userDevice: this.props.selectedDevice.device_id });
  };

  copyFile = async (file) => {
    console.log(this.props.sourcePath, this.state.desPath);
    var updateObject = {
      source: this.state.source,
      dest: currentPath1,
    };
    this.setState({
      loader: true,
    });
    const response = await fileSharingMethods.copyFiles(updateObject);
    if (response.data.statusCode === 400) {
      // this.props.setState({
      //   open: true,
      //   message: response.data.message,
      //   loader: false,
      // });
      toast.error(response.data.message);
    }
    if (response.data.statusCode === 200) {
      this.resetValues();
      // this.props.setState({
      //   open: true,
      //   message: "Succefully copy",
      //   loader: false,
      // });
      toast.success(response.data.message)

    }
    else {
      toast.error(response.data.message);
    }
    this.handleClose()
    this.fetchFileStructure()
    this.setState({ loader: true });
    this.getFileStructureList(currentPath1);
    this.setState({ userDevice: this.props.selectedDevice.device_id });
  };

  getCharCount(str) {
    console.log(this.state.currentPath);
    console.log(this.state.fileStructureList);
    let charCount = 0;
    for (let i = 0; i < str.length; i++) {
      if (str.charAt(i) === "/") charCount++;
    }
    return charCount;
  }

  resetValues = () => {
    this.setState({
      currentPath: "",
      // userDevice: "",
      device_id: "",
      desPath: "",
    });
  };
  handleClose = () => {
    this.props.handleClose()
}
fetchFileStructure = () => {
    this.props.fetchFileStructure()
}
  render() {
    console.log("ppos",this.props)
    return (
      <div>
        <Snackbar
          open={this.state.open}
          autoHideDuration={3000}
          onClose={() => this.setState({ open: false })}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            // onClose={handleClose}
            severity="info"
            sx={{ width: "100%" }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
        <h6>
          <div>
            {this.getCharCount(currentPath1) > 2 ? (
              // <button
              //   style={{
              //     // backgroundColor: "red",
              //     // color: "white",
              //     borderRadius: "10px",
              //     height: "30px",
              //     width: "80px",
              //   }}
              <ArrowBack onClick={() => this.goBackwardInFileStructure()}>
                Back
              </ArrowBack>
            ) : null}
             Select Destination
            <em className="mandatory">* </em>
          </div>
        </h6>
        <select
          className="fp_modal_input"
          onChange={this.changeUserDevice}
          value={this.state.userDevice}
          disabled={this.state.loader}
        >
          {this.state.devicesList.map((device, index) => (
            <option value={device.device_id} key={index}>
              {device.device_name}
            </option>
          ))}
        </select>
        <ul
          className="filesharing-card mr-t20 scroll"
          style={{ height: "300px" }}
        >
          {this.state.fileStructureList.length > 0 ? (
            this.state.fileStructureList.map((data, index) => {
              // console.log(data);
              if (data.type === "directory") {
                return (
                  <li
                    key={data.fileid}
                    className="col-md-12"
                    onClick={() => this.goForwardInFileStructure(data.basename)}
                  >
                    {/* {console.log(data.filename)} */}

                    <div className="col-2 col-sm-2 col-md-2">
                      <span className="drop-icons-left">
                        <FolderIcon />
                      </span>
                    </div>

                    <div className="col-6 col-sm-6 col-md-6 header_button_flex">
                      <div className="head_btn_flex">
                        <div className="head_btn_flex_headings">
                          <h5 className="drisk-name">{data.basename}</h5>
                          <h6
                            className="drisk-date"
                            style={{
                              fontSize: "13px !important",
                              fontWeight: "500 !important",
                              color: "#6bc4e2 !important",
                              fontFamily: "Poppins-Regular !important",
                            }}
                          >
                            {moment(data.lastmod).format("LL")}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className="col-2 col-sm-2 col-md-2 header_button_flex mr-t10">
                      <div className="head_btn_flex">
                        <div className="head_btn_flex_headings">
                          <h6 className="drisk-date">
                            {bytes.format(data.size, {
                              unitSeparator: " ",
                            })}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              }
            })
          ) : this.state.showLoader === true ? (
            <CircularProgress
              color="secondary"
              style={{ margin: "10% 0% 0% 50%" }}
            />
          ) : (
            <div className="mr-b20 mr-t20">
              <h6
                style={{
                  color: "#C12A37",
                  fontSize: "15px",
                  display: "block !important",
                  width: "160px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  fontWeight: "600",
                }}
              >
                No Data Available
              </h6>
            </div>
          )}
        </ul>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            type="button"
            // className="btn-fp-footer btn-Reset"
            style={{
              backgroundColor: "#c12a37",
              color: "white",
              borderRadius: "10px",
              height: "38px",
              width: "150px",
              border: "1px solid #c12a37",
            }}
            onClick={this.moveFile}
            disabled={this.state.loader}
          >
            Move 
            {this.state.loader ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : null}
          </button>
          <button
            type="button"
            // className="btn-fp-footer btn-Reset"
            style={{
              backgroundColor: "#c12a37",
              color: "white",
              borderRadius: "10px",
              height: "38px",
              width: "150px",
              border: "1px solid #c12a37",
            }}
            onClick={this.copyFile}
            disabled={this.state.loader}
          >
            Copy 
            {this.state.loader ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : null}
          </button>
        </div>
      </div>
    );
  }
}

export default McModal;
