import React, { Component } from "react";
import ToggleSideNav from "../../UI/ToggleSideNav/ToggleSideNav";
import BackButton from "../../UI/BackButton/BackButton";
import logo from "../../../assets/images/cervais-s-logo.svg";

class AboutUs extends Component {
  redirectToCervaisWebsite() {
    const websiteUrl = "https://www.cervais.com";
    window.open(websiteUrl, "_blank");
  }

  render() {
    return (
      <div className="routing-main-wrap">
        <div className="page-head">
          <div className="page_head_btn w-100">
            <div className="aboutus-flex d-flex  w-100 align-items-center">
              <BackButton count={0} getPath="/dashboard/more" />
              <h3>About Us</h3>
            </div>
          </div>
          <ToggleSideNav batteryRefresh="false" />
        </div>
        <div className="rout-body mr-t80">
          <div className="form-head text-center mr-b30 ">
            <img
              className="f-logo"
              src={logo}
              alt="Cervais"
              title="Cervais"
              height="60"
            />
            <span>
              <h3 className="mr-t20 aboutus-font">ARMIA SG-X</h3>
              <h5 className="mr-t10 ">Version 2.0</h5>
              <h5 className="mr-t10 ">2022 Cervais, Inc</h5>
              <h5 className="mr-t10 ">For product information, go to</h5>
              <h5
                className="mr-t10"
                onClick={this.redirectToCervaisWebsite}
                style={{ color: "#039e77", cursor: "pointer" }}
              >
                <u>https://www.cervais.com</u>
              </h5>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
