import api from "./api";

function getMoreDetails(device_id) {
  return new Promise((resolve, reject) => {
    api.post("/settings/getMoreDetails", device_id).then((response) => {
      resolve(response);
    });
  });
}

function getSettings(device_id) {
  return new Promise((resolve, reject) => {
    api.post("/settings/getSettings", device_id).then((response) => {
      resolve(response);
    });
  });
}

function updateSettings(updatedSettingsObject) {
  return new Promise((resolve, reject) => {
    api
      .post("/settings/updateSettings", updatedSettingsObject)
      .then((response) => {
        resolve(response);
      });
  });
}

function getParentalSettings(device_id) {
  return new Promise((resolve, reject) => {
    api.post("/settings/getParentalSettings", device_id).then((response) => {
      resolve(response);
    });
  });
}

function updateParentalSettings(updatedObject) {
  return new Promise((resolve, reject) => {
    api
      .post("/settings/updateParentalSettings", updatedObject)
      .then((response) => {
        resolve(response);
      });
  });
}

function updateSSID(updateObject) {
  return new Promise((resolve, reject) => {
    api.post("/settings/updateSSID", updateObject).then((response) => {
      resolve(response);
    });
  });
}

function getAllIOTDevices(device_id) {
  return new Promise((resolve, reject) => {
    api.post("/settings/getAllIOTDevices", device_id).then((response) => {
      resolve(response);
    });
  });
}
function fetchScansReport(device_id) {
  return new Promise((resolve, reject) => {
    api.post("/user/getVulnerabilityScanning", device_id).then((response) => {
      resolve(response);
    });
  });
}
function createScan(device_id) {
  return new Promise((resolve, reject) => {
    api.post("/user/createVulnerabilityScan", device_id).then((response) => {
      resolve(response);
    });
  });
}
function updateScan(device_id) {
  return new Promise((resolve, reject) => {
    api.post("/user/updateVulnerabilityScan", device_id).then((response) => {
      resolve(response);
    });
  });
}
function getScanReports(payload) {
  return new Promise((resolve, reject) => {
    console.log(payload);
    api.post("/user/getScanReport", payload).then((response) => {
      resolve(response);
    });
  });
}
function connectWiFiClient(payload) {
  return new Promise((resolve, reject) => {
    console.log(payload);
    api.post("/settings/connectWiFiClient", payload).then((response) => {
      resolve(response);
    });
  });
}
function disconnectWiFiClient(payload) {
  return new Promise((resolve, reject) => {
    console.log(payload);
    api.get("/settings/disconnectFromClient/"+ payload).then((response) => {
      resolve(response);
    });
  });
}
function getScanReportById(scan_id) {
  return new Promise((resolve, reject) => {
    api.get("/user/getScanDetails/" + scan_id).then((response) => {
      resolve(response);
    });
  });
}
function deleteVulnerabilityScan(device_id) {
  return new Promise((resolve, reject) => {
    api
      .delete("/user/deleteVulnerabilityScan/" + device_id)
      .then((response) => {
        resolve(response);
      });
  });
}
function deleteVulnerabilityScanReport(device_id) {
  return new Promise((resolve, reject) => {
    api
      .delete("/user/deleteVulnerabilityReport/" + device_id)
      .then((response) => {
        resolve(response);
      });
  });
}

const settingsMethods = {
  getMoreDetails,
  getSettings,
  updateSettings,
  getParentalSettings,
  updateParentalSettings,
  updateSSID,
  getAllIOTDevices,
  fetchScansReport,
  createScan,
  getScanReports,
  deleteVulnerabilityScan,
  getScanReportById,
  updateScan,
  deleteVulnerabilityScanReport,
  connectWiFiClient,disconnectWiFiClient
};

export default settingsMethods;
