import React from "react";
import ToggleSideNav from "../../components/UI/ToggleSideNav/ToggleSideNav";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DeviceTree from "../../components/AdminPanel/DeviceTree/DeviceTree";
import RegisteredUsers from "../../components/AdminPanel/RegisteredUsers/RegisteredUsers";
import DataUsage from "../../components/AdminPanel/DataUsage/DataUsage";
import VulnerabilityScan from "../../components/AdminPanel/VulnerabilityScanHistory/VulnerabilityScanHistory";
import UserManagement from "../../components/AdminPanel/UserManagement/UserManagement";
import CustomerMgmt from "../../components/AdminPanel/CustomerMgmt/CustomerMgmt";
import AdminMaps from "../../components/AdminPanel/AdminMaps/AdminMaps";
import NetworkSecurity from "../../components/AdminPanel/NetworkSecurity/NetworkSecurity";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "#232323",
  },
}));

export default function Admin() {
  const classes = useStyles();
  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="routing-main-wrap">
      <div className="page-head">
        <h3>Admin Panel</h3>
        <ToggleSideNav batteryRefresh="false" />
      </div>

      <div className="rout-body">
        {/* <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t100 mr-b20 text-center">
          <h6 style={{ color: "white" }}>This feature will be coming soon.</h6>
        </div> */}
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example">
              <Tab value="one" label="Dashboard" {...a11yProps("one")} />
              {/* <Tab value="two" label="Map" {...a11yProps("two")} /> */}
              <Tab value="three" label="Company" {...a11yProps("three")} />
              <Tab value="four" label="User Profile" {...a11yProps("four")} />
              {/* <Tab value="five" label="Network Security" {...a11yProps("five")} /> */}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index="one">
            <DeviceTree />
            <RegisteredUsers />
            {/* <DataUsage /> */}
            {/* <VulnerabilityScan /> */}
          </TabPanel>
          <TabPanel value={value} index="two">
            <AdminMaps />
          </TabPanel>
          <TabPanel value={value} index="three">
            <CustomerMgmt />
          </TabPanel>
          <TabPanel value={value} index="four">
            <UserManagement />
          </TabPanel>
          <TabPanel value={value} index="five">
            <NetworkSecurity />
          </TabPanel>
        </div>
      </div>
    </div>
  );
}
