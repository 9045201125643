import api from "./api";

function userLogin(userObject) {
  return new Promise((resolve, reject) => {
    api.post("/user/login", userObject).then((response) => {
      resolve(response);
    });
  });
}

function userRegistration(registerUserObject) {
  return new Promise((resolve, reject) => {
    api.post("user/registration", registerUserObject).then((response) => {
      resolve(response);
    });
  });
}

function userProfile() {
  return new Promise((resolve, reject) => {
    api.get("user/profile").then((response) => {
      resolve(response);
    });
  });
}

function userForgotPassword(changePasswordObject) {
  return new Promise((resolve, reject) => {
    api.post("user/forgotPassword", changePasswordObject).then((response) => {
      resolve(response);
    });
  });
}

function userChangePassword(updatedObject) {
  return new Promise((resolve, reject) => {
    api.post("user/changePassword", updatedObject).then((response) => {
      resolve(response);
    });
  });
}

function userUpdateProfile(editedObject) {
  return new Promise((resolve, reject) => {
    api.post("user/updateProfile", editedObject).then((response) => {
      resolve(response);
    });
  });
}

function inviteUser(inviteObject) {
  return new Promise((resolve, reject) => {
    api.post("user/inviteUser", inviteObject).then((response) => {
      resolve(response);
    });
  });
}

function userFeedback(feedbackObject) {
  return new Promise((resolve, reject) => {
    api.post("user/userFeedback", feedbackObject).then((response) => {
      resolve(response);
    });
  });
}

function getFAQ() {
  return new Promise((resolve, reject) => {
    api.get("user/getFAQ").then((response) => {
      resolve(response);
    });
  });
}

function getNotification(page, pageSize) {
  return new Promise((resolve, reject) => {
    api
      .get(`user/getNotification?page=${page}&page_size=${pageSize}`)
      .then((response) => {
        resolve(response);
      });
  });
}

function updateNotificationStatus(updatedStatus) {
  return new Promise((resolve, reject) => {
    api
      .post("user/updateNotificationStatus", updatedStatus)
      .then((response) => {
        resolve(response);
      });
  });
}

function getDashboardGraph(dashboardObject) {
  return new Promise((resolve, reject) => {
    api.post("user/getDashboardGraph", dashboardObject).then((response) => {
      resolve(response);
    });
  });
}
function getDeviceGPS(dashboardObject) {
  return new Promise((resolve, reject) => {
    api.get("user/getDeviceGPS").then((response) => {
      resolve(response);
    });
  });
}

function getVulnerabilityLogs(deviceId) {
  return new Promise((resolve, reject) => {
    api.post("user/getVulnerabilityLogs", deviceId).then((response) => {
      resolve(response);
    });
  });
}

function userRegister(registerObject) {
  return new Promise((resolve, reject) => {
    api.post("user/registration", registerObject).then((response) => {
      resolve(response);
    });
  });
}

function registeredUsers() {
  return new Promise((resolve, reject) => {
    api.get("user/registeredUsers").then((response) => {
      resolve(response);
    });
  });
}

function profilePic(updatePic) {
  return new Promise((resolve, reject) => {
    api.put("user/profilePic", updatePic).then((response) => {
      resolve(response);
    });
  });
}

function getAllDriskDevices() {
  return new Promise((resolve, reject) => {
    api.get("user/getAllDriskDevices").then((response) => {
      resolve(response);
    });
  });
}
function getAvailableWiFiNetworks(device_id) {
  return new Promise((resolve, reject) => {
    api
      .get("settings/getAvailableWiFiNetworks/" + device_id)
      .then((response) => {
        resolve(response);
      });
  });
}

function updateDriskDevices(updateDevice) {
  return new Promise((resolve, reject) => {
    api.post("user/updateDriskDevices", updateDevice).then((response) => {
      resolve(response);
    });
  });
}

function updateDeviceConnectionStatus(updateDevice) {
  return new Promise((resolve, reject) => {
    api
      .post("/device/updateDeviceConnectionStatus", updateDevice)
      .then((response) => {
        resolve(response);
      });
  });
}

function getDashboardData(dataObject) {
  return new Promise((resolve, reject) => {
    api.post("user/getDashboardData", dataObject).then((response) => {
      resolve(response);
    });
  });
}

function denyDevice(denyObject) {
  return new Promise((resolve, reject) => {
    api.post("user/denyDevice", denyObject).then((response) => {
      resolve(response);
    });
  });
}

function unblockDevice(denyObject) {
  return new Promise((resolve, reject) => {
    api.post("user/unblockIOT", denyObject).then((response) => {
      resolve(response);
    });
  });
}

function getVulnerabilityLogsForSnort(logsObject) {
  return new Promise((resolve, reject) => {
    api.post("user/getVulnerabilityLogs", logsObject).then((response) => {
      resolve(response);
    });
  });
}

function iotRename(renameObject) {
  return new Promise((resolve, reject) => {
    api.post("user/iotRename", renameObject).then((response) => {
      resolve(response);
    });
  });
}

function exportCSV(renameObject) {
  return new Promise((resolve, reject) => {
    api.post("user/exportCSV", renameObject).then((response) => {
      resolve(response);
    });
  });
}

function getMappedUsers(deviceId) {
  return new Promise((resolve, reject) => {
    api.post("user/getMappedUsers", deviceId).then((response) => {
      resolve(response);
    });
  });
}

function removeGuestUsers(data) {
  return new Promise((resolve, reject) => {
    api.post("user/removeGuestUsers", data).then((response) => {
      resolve(response);
    });
  });
}
const userMethods = {
  userLogin,
  userRegistration,
  userProfile,
  userForgotPassword,
  userChangePassword,
  userUpdateProfile,
  inviteUser,
  userFeedback,
  getFAQ,
  getNotification,
  updateNotificationStatus,
  getDashboardGraph,
  getVulnerabilityLogs,
  userRegister,
  registeredUsers,
  profilePic,
  getAllDriskDevices,
  updateDriskDevices,
  updateDeviceConnectionStatus,
  getDashboardData,
  denyDevice,
  unblockDevice,
  getVulnerabilityLogsForSnort,
  iotRename,
  exportCSV,
  getMappedUsers,
  removeGuestUsers,
  getDeviceGPS,
  getAvailableWiFiNetworks,
};

export default userMethods;
