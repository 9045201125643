import React, { Component } from "react";
import ToggleSideNav from "../UI/ToggleSideNav/ToggleSideNav";

class Registration extends Component {
  render() {
    return (
      <div className="routing-main-wrap">
        <div className="page-head">
          <div className="page_head_btn">
            <h3>Connected Devices</h3>
          </div>
          <div className="registration_btns">
            {/* <a href="#" className="section_route_body">
              <svg
                width="24"
                height="24"
                className="reg_plusbtn"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 2C19.0609 2 20.0783 2.42143 20.8284 3.17157C21.5786 3.92172 22 4.93913 22 6V18C22 19.0609 21.5786 20.0783 20.8284 20.8284C20.0783 21.5786 19.0609 22 18 22H6C4.93913 22 3.92172 21.5786 3.17157 20.8284C2.42143 20.0783 2 19.0609 2 18V6C2 4.93913 2.42143 3.92172 3.17157 3.17157C3.92172 2.42143 4.93913 2 6 2H18ZM18 0H6C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6L0 18C0 19.5913 0.632141 21.1174 1.75736 22.2426C2.88258 23.3679 4.4087 24 6 24H18C19.5913 24 21.1174 23.3679 22.2426 22.2426C23.3679 21.1174 24 19.5913 24 18V6C24 4.4087 23.3679 2.88258 22.2426 1.75736C21.1174 0.632141 19.5913 0 18 0V0Z"
                  fill="white"
                />
                <path
                  d="M12 18C11.7348 18 11.4804 17.8946 11.2929 17.7071C11.1054 17.5196 11 17.2652 11 17V7C11 6.73478 11.1054 6.48043 11.2929 6.29289C11.4804 6.10536 11.7348 6 12 6C12.2652 6 12.5196 6.10536 12.7071 6.29289C12.8946 6.48043 13 6.73478 13 7V17C13 17.2652 12.8946 17.5196 12.7071 17.7071C12.5196 17.8946 12.2652 18 12 18Z"
                  fill="white"
                />
                <path
                  d="M6 12C6 11.7348 6.10536 11.4804 6.29289 11.2929C6.48043 11.1054 6.73478 11 7 11H17C17.2652 11 17.5196 11.1054 17.7071 11.2929C17.8946 11.4804 18 11.7348 18 12C18 12.2652 17.8946 12.5196 17.7071 12.7071C17.5196 12.8946 17.2652 13 17 13H7C6.73478 13 6.48043 12.8946 6.29289 12.7071C6.10536 12.5196 6 12.2652 6 12Z"
                  fill="white"
                />
              </svg>
            </a> */}
            <ToggleSideNav batteryRefresh="false" />
          </div>
        </div>
        <div className="rout-body">
          <div className="row">
            <div className="col-sm-12">
              <h5 className="devices-title">Connected Device</h5>

              <div className="registration-device-details mr-t10">
                <div className="wifi_routers_main">
                  <div className="wifi_router-wrap">
                    <div className="col-1 col-sm-1 col-md-1 wifi_image_routers">
                      <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0)">
                          <path
                            d="M18.5 37C28.7173 37 37 28.7173 37 18.5C37 8.28273 28.7173 0 18.5 0C8.28273 0 0 8.28273 0 18.5C0 28.7173 8.28273 37 18.5 37Z"
                            fill="white"
                          />
                          <path
                            d="M14.3989 22.4847C13.5851 21.6587 12.6778 20.93 11.6957 20.3136C11.8773 19.1144 12.2332 17.9483 12.7523 16.8521C16.0001 18.6531 18.6364 21.3825 20.3235 24.6909C21.38 27.1127 24.2987 24.9584 23.5113 23.061C22.5441 21.0999 21.2818 19.2988 19.7685 17.7205L19.8465 17.6104C20.611 16.5402 21.2317 15.6733 21.9411 14.8401L21.9932 14.8905C24.5273 17.402 26.4158 20.4898 27.4973 23.8898C28.0064 26.1266 30.9985 25.0012 30.3151 22.4953C27.9973 13.9548 19.7654 7.01344 11.9878 6.10679C12.7324 5.34232 13.529 4.67417 14.3989 3.91736C15.4468 4.09121 16.4786 4.3505 17.4842 4.69255C19.0544 5.29495 20.8035 3.40217 19.1401 2.77225C16.4552 1.83654 13.5662 1.64943 10.7831 2.231C7.86883 2.92816 5.33323 4.71716 3.69945 7.22903C4.61375 6.17565 5.73548 5.32217 6.99452 4.72185C8.25356 4.12153 9.62287 3.7873 11.0169 3.74005C10.2376 4.45238 9.52894 5.23829 8.90082 6.08691C3.37681 6.7153 0.904535 10.3496 0.141602 15.8308C1.34028 7.81926 10.8855 6.42943 19.2884 12.5834C18.524 13.4747 17.8803 14.3721 17.1479 15.4042C14.7486 13.5299 11.9036 12.3109 8.89168 11.8663C5.84759 11.6201 4.83094 14.8522 7.64569 15.1886C8.33146 15.2547 9.00955 15.3842 9.67144 15.5754C9.18108 16.6495 8.8074 17.773 8.55685 18.9268C3.93185 17.7037 0.575957 20.3686 1.96728 25.5257C2.24096 26.391 3.34331 26.4675 3.30509 25.7321C3.22125 25.197 3.27246 24.6495 3.45402 24.1392C3.63559 23.629 3.94166 23.1721 4.34465 22.8103C6.27416 21.2248 9.6653 22.4785 12.0535 24.8438C14.4417 27.209 15.6726 30.6231 14.0871 32.5526C11.9833 35.1105 6.42244 32.7805 4.13058 28.3695C3.8248 27.6968 2.99769 27.9567 3.21326 28.4199C4.18675 30.0995 5.49427 31.5617 7.05499 32.7161C8.61571 33.8705 10.3966 34.6927 12.2875 35.1319C19.2243 36.2847 20.3388 28.3848 14.3989 22.4847Z"
                            fill="#00A2FF"
                          />
                          <path
                            d="M8.30816 30.6384C10.8676 31.8967 11.9119 29.3374 9.71334 27.1678C7.54533 24.9707 4.98282 26.0104 6.24265 28.5729C6.69874 29.4601 7.42094 30.1823 8.30816 30.6384Z"
                            fill="#C70000"
                          />
                          <path
                            d="M24.5076 27.6768C24.0581 27.5397 23.576 27.5528 23.1346 27.7142C22.6931 27.8755 22.3165 28.1765 22.0614 28.5712C21.9183 28.7197 21.8118 28.8997 21.7505 29.0966C21.6891 29.2935 21.6745 29.502 21.7079 29.7056C21.7413 29.9091 21.8218 30.1019 21.9429 30.2688C22.064 30.4358 22.2223 30.5723 22.4054 30.6673C22.8549 30.8041 23.3367 30.791 23.7781 30.6296C24.2194 30.4682 24.5963 30.1675 24.8516 29.7729C24.9947 29.6244 25.1012 29.4445 25.1626 29.2476C25.2239 29.0506 25.2383 28.8421 25.2049 28.6386C25.1715 28.435 25.0912 28.2421 24.9701 28.0751C24.8491 27.9081 24.6907 27.7718 24.5076 27.6768Z"
                            fill="#C70000"
                          />
                          <path
                            d="M21.97 6.7961L22.2207 6.94904C24.0554 7.94743 25.5492 6.03169 23.7787 4.9385C22.1978 4.07007 20.1368 5.51333 21.97 6.7961Z"
                            fill="#C70000"
                          />
                          <path
                            d="M28.0694 29.2962C27.9672 31.1657 27.2067 32.9385 25.9229 34.3013C24.6391 35.664 22.9147 36.5287 21.0547 36.7421C26.1292 36.0342 29.7328 33.8997 30.6762 28.7916C30.7847 26.6511 28.1061 27.4156 28.0694 29.2962Z"
                            fill="#C70000"
                          />
                          <path
                            d="M34.6526 26.1007C35.2061 23.479 35.0696 20.7584 34.2566 18.2053C34.6828 17.5732 35.0744 16.9184 35.4294 16.2437C36.5884 19.551 36.5074 23.1665 35.2015 26.4186C36.0064 24.743 36.5401 22.9502 36.7825 21.1071C36.9133 20.001 36.9133 18.8833 36.7825 17.7772C36.5446 13.165 34.6054 8.80481 31.3398 5.53922C28.0742 2.27362 23.714 0.33452 19.1019 0.0966677C17.9962 -0.0338502 16.8791 -0.0338502 15.7734 0.0966677C13.9304 0.339149 12.1376 0.872758 10.4619 1.67757C17.7411 -1.56528 29.5171 3.09489 34.4173 13.6261C34.0106 14.464 33.6527 15.1551 33.3316 15.7223C32.0414 12.8998 30.2341 10.3437 28.0033 8.18619C26.5264 6.82239 24.7392 8.75651 26.08 9.89709C26.1442 9.95213 26.4835 10.2778 26.5386 10.3374C33.486 17.4622 35.6999 28.0729 29.6515 33.1826C32.1642 31.5498 33.9543 29.0148 34.6526 26.1007Z"
                            fill="#C70000"
                          />
                          <path
                            d="M2.31571 16.7771C3.98683 15.2665 4.28808 12.3035 2.60015 13.4578C-0.242126 15.7818 -1.06933 20.3655 1.70261 26.1112C-0.0892886 22.1283 0.346457 18.699 2.31571 16.7771Z"
                            fill="#C70000"
                          />
                          <path
                            d="M20.9212 33.4757C20.8276 33.6394 20.7256 33.7982 20.6154 33.9513C18.8404 36.4297 15.1342 37.1374 10.7676 35.1743C16.8099 38.0793 21.5664 37.0228 23.7634 33.8182C24.8291 32.0813 22.0893 32.0783 20.9212 33.4757Z"
                            fill="#C70000"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="37" height="37" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="col-11 col-sm-11 col-md-11 header_button_flex">
                      <div className="routers_headingname w-100">
                        <div className="head_btn_flex">
                          <div className="head_btn_flex_headings">
                            <h5>35JSKD</h5>
                            <h6>Time Connected 142.1 hrs</h6>
                          </div>
                          <div className="register_routers_button reg_rou_btn">
                            <div className="register_routers_button_content">
                              <h6>(614) 459 - 2050</h6>
                              <p>Registered</p>
                            </div>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M20 18V11H18V18H2V2H9V0H2C0.89543 0 0 0.89543 0 2V18C0 19.1046 0.89543 20 2 20H18C19.1046 20 20 19.1046 20 18ZM17.1781 0.723423C16.7197 0.26142 16.0921 0 15.4374 0C14.7834 0 14.1564 0.26083 13.6954 0.724628L5.3265 9.09342C4.57867 9.75233 4.08844 10.7328 4.00325 11.7873L4 15.0023V16.0023H8.1346C9.2689 15.9245 10.259 15.4295 10.9575 14.6238L19.279 6.30584C19.7407 5.84416 20.0001 5.21799 20.0001 4.56508C20.0001 3.91217 19.7407 3.286 19.279 2.82432L17.1781 0.723423ZM8.06398 14.0048C8.59821 13.967 9.09549 13.7184 9.49479 13.2616L15.5567 7.19972L12.8024 4.44527L6.6961 10.5496C6.29095 10.9079 6.04031 11.4092 6 11.8678V14.0029L8.06398 14.0048ZM14.2169 3.03128L16.9709 5.78551L17.8648 4.89162C17.9514 4.80502 18.0001 4.68756 18.0001 4.56508C18.0001 4.4426 17.9514 4.32514 17.8648 4.23854L15.7611 2.13486C15.6755 2.04855 15.5589 2 15.4374 2C15.3158 2 15.1992 2.04855 15.1136 2.13486L14.2169 3.03128Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </div>

                        <div className="row progress_bar_row">
                          <svg
                            width="652"
                            height="7"
                            viewBox="0 0 652 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="652"
                              width="6.48493"
                              height="652"
                              rx="3.24247"
                              transform="rotate(90 652 0)"
                              fill="#2196F3"
                            />
                            <rect
                              x="130"
                              width="6.48493"
                              height="130"
                              rx="3.24247"
                              transform="rotate(90 130 0)"
                              fill="#BC2835"
                            />
                          </svg>

                          <span>54.6 MB</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mr-t20">
                <div className="col-sm-12">
                  <h5 className="devices-title">Available Devices</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Registration;
