import React, { Component } from "react";
import ToggleSideNav from "../UI/ToggleSideNav/ToggleSideNav";
import userMethods from "../../services/userApi";
import Modal from "@material-ui/core/Modal";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Moment from "@date-io/moment";
import moment from "moment";
import profilepic from "../../assets/images/profilePic.png";
import imageCompression from "browser-image-compression";

class Profile extends Component {
  state = {
    profileData: [],
    userName: "",
    password: "",
    showModal: false,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    loginType: "",
    loader: false,
    showLogout: false,
    editModal: false,
    fullName: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    dateOfBirth: "",
    errorMobile: "Invalid Phone Number!",
    alertMobile: false,
    imgForBase64: "",
    profilePic: profilepic,
    showLogout: false,
    message: "",
  };

  componentDidMount() {
    this.fetchProfileData();
  }

  async fetchProfileData() {
    const response = await userMethods.userProfile();

    if (response.data.statusCode === 200) {
      this.setState({
        profileData: response.data.result,
        userName: response.data.result.email_address.split("@")[0],
        password: window.sessionStorage.getItem("UserData")
          ? JSON.parse(window.sessionStorage.getItem("UserData")).password
          : "",
        loginType: JSON.parse(window.sessionStorage.getItem("UserDetails"))
          .type,
      });

      if (
        this.state.profileData.profile_url === "" ||
        this.state.profileData.profile_url === null ||
        this.state.profileData.profile_url === undefined
      ) {
        this.setState({
          profilePic: profilepic,
        });
      } else {
        this.setState({
          profilePic:
            "data:image/png;base64," + this.state.profileData.profile_url,
        });
      }
    }
    if (response.data.statusCode === 401) {
      this.setState({
        showLogout: true,
      });
    }
  }

  logoutUser = () => {
    window.sessionStorage.clear();
    this.props.history.replace({
      pathname: "/",
    });
  };

  handleOpen = () => {
    this.setState({ showModal: true });
  };

  handleClose = () => {
    this.setState({ showModal: false, showLogout: false, editModal: false });
  };

  handlePasswordChange = (event) => {
    this.setState({
      oldPassword: event.target.value,
    });
  };

  handleNewPasswordChange = (event) => {
    this.setState({
      newPassword: event.target.value,
    });
  };

  handleConfirmPasswordChange = (event) => {
    this.setState({
      confirmPassword: event.target.value,
    });
  };

  resetPassword = async () => {
    this.setState({
      loader: true,
    });

    if (this.state.newPassword === this.state.confirmPassword) {
      const updatedObject = {
        oldPassword: this.state.oldPassword,
        newPassword: this.state.confirmPassword,
      };

      var userObject = {
        email_address: JSON.parse(window.sessionStorage.getItem("UserData"))
          .email_address,
        password: this.state.confirmPassword,
      };

      const response = await userMethods.userChangePassword(updatedObject);
      if (response.data.statusCode === 200) {
        this.resetForm();
        this.handleClose();

        window.sessionStorage.setItem("UserData", JSON.stringify(userObject));
        this.fetchProfileData();
      }
    } else {
      this.setState({
        newPassword: "",
        confirmPassword: "",
        loader: false,
      });
    }
  };

  resetForm() {
    this.setState({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      loader: false,
    });
  }

  togglePassword() {
    var pssInput = document.getElementById("toggle");
    var passIcon = document.getElementById("toggleIcon");

    if (pssInput.type === "password") {
      pssInput.type = "text";
      passIcon.className = "fa fa-eye";
    } else {
      pssInput.type = "password";
      passIcon.className = "fa fa-eye-slash";
    }
  }

  logout = () => {
    this.setState({
      showLogout: true,
    });
  };

  logoutUser = () => {
    this.setState({ loader: true, showLogout: false });
    window.sessionStorage.clear();
    this.props.history.replace({
      pathname: "/",
    });
  };

  handleEditAccount = () => {
    let dateStr = this.state.profileData.dob;
    var dateOB = moment(dateStr);

    this.setState({
      editModal: true,
      fullName: this.state.profileData.full_name,
      phoneNumber: this.state.profileData.phone_number,
      dateOfBirth: dateOB,
    });
  };

  handleFirstName = (event) => {
    this.setState({ firstName: event.target.value });
  };
  handleLastName = (event) => {
    this.setState({ lastName: event.target.value });
  };

  handlePhoneNumber = (event) => {
    this.setState({ phoneNumber: event.target.value });

    if (this.state.phoneNumber.length >= 10) {
      this.setState({
        alertMobile: true,
        phoneNumber: "",
      });
    }
  };

  handleDateOfBirth = (date) => {
    this.setState({
      dateOfBirth: moment(date).format("MM/DD/yyyy"),
    });
  };

  editAccount = async () => {
    this.setState({ loader: true });

    var editedObject = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      phone_number: this.state.phoneNumber,
      dob: moment(this.state.dateOfBirth).format("MM/DD/yyyy"),
    };

    const response = await userMethods.userUpdateProfile(editedObject);

    if (response.data.statusCode === 200) {
      this.fetchProfileData();
      this.setState({ loader: false, editModal: false });
    }
  };

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  blobToBase642 = function (blob, callback) {
    var reader = new FileReader();
    reader.onload = function () {
      var dataUrl = reader.result;
      var base64 = dataUrl.split(",")[1];
      callback(base64);
    };
    reader.readAsDataURL(blob);
  };

  uploadImage = async (event) => {
    // await this.setState({
    //   imgForBase64: event.target.files[0],
    // });

    // var convertedBase64 = await this.toBase64(event.target.files[0]);
    // document.getElementById("imagePreview").src = convertedBase64;

    // await this.setState({
    //   profilePic: convertedBase64,
    // });

    // var updatePic = {
    //   profile_url: this.state.profilePic.split("base64,")[1],
    // };

    // const response = await userMethods.profilePic(updatePic);

    // if (response.data.statusCode === 200) {
    //   this.fetchProfileData();
    // }

    let compressedFile;
    try {
      compressedFile = await imageCompression(event.target.files[0], {
        maxSizeMB: 4,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      });
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB
    } catch (error) {
      console.log(error);
    }
    var convertedBase64 = await this.blobToBase64(compressedFile);
    const response = await userMethods.profilePic({
      profile_url: convertedBase64.split("base64,")[1],
    });
    if (response.data.statusCode === 200) {
      this.fetchProfileData();
    }
  };

  // Converts Image to Base64
  toBase64() {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(this.state.imgForBase64);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  render() {
    return (
      <div className="routing-main-wrap">
        <div className="page-head">
          <div className="page_head_btn">
            <h3>Account</h3>
          </div>

          <div className="registration_btns">
            <button
              className="section_route_body"
              onClick={this.handleEditAccount}
              style={{
                backgroundColor: "transparent",
                border: "none",
                marginRight: "15px",
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 18V11H18V18H2V2H9V0H2C0.89543 0 0 0.89543 0 2V18C0 19.1046 0.89543 20 2 20H18C19.1046 20 20 19.1046 20 18ZM17.1781 0.723423C16.7197 0.26142 16.0921 0 15.4374 0C14.7834 0 14.1564 0.26083 13.6954 0.724628L5.3265 9.09342C4.57867 9.75233 4.08844 10.7328 4.00325 11.7873L4 15.0023V16.0023H8.1346C9.2689 15.9245 10.259 15.4295 10.9575 14.6238L19.279 6.30584C19.7407 5.84416 20.0001 5.21799 20.0001 4.56508C20.0001 3.91217 19.7407 3.286 19.279 2.82432L17.1781 0.723423ZM8.06398 14.0048C8.59821 13.967 9.09549 13.7184 9.49479 13.2616L15.5567 7.19972L12.8024 4.44527L6.6961 10.5496C6.29095 10.9079 6.04031 11.4092 6 11.8678V14.0029L8.06398 14.0048ZM14.2169 3.03128L16.9709 5.78551L17.8648 4.89162C17.9514 4.80502 18.0001 4.68756 18.0001 4.56508C18.0001 4.4426 17.9514 4.32514 17.8648 4.23854L15.7611 2.13486C15.6755 2.04855 15.5589 2 15.4374 2C15.3158 2 15.1992 2.04855 15.1136 2.13486L14.2169 3.03128Z"
                  fill="white"
                />
              </svg>
            </button>
            <ToggleSideNav batteryRefresh="false" />
          </div>
        </div>

        <div className="rout-body">
          <div className="profile-wrap">
            <div className="black-card d-d-card ">
              <div className="profile-head">
                <div className="profile-info-block">
                  <div className="imgUp">
                    <img
                      id="imagePreview"
                      className="img-thumbnail img-fluid"
                      src={this.state.profilePic}
                      height="100px"
                      width="100%"
                      alt="Profile Pic"
                    />
                    <label className="btn btn-primary btn-image">
                      Upload
                      <input
                        type="file"
                        className="uploadFile img"
                        value=""
                        style={{
                          width: "0px",
                          height: "0px",
                          overflow: "hidden",
                        }}
                        onChange={this.uploadImage}
                      />
                    </label>
                  </div>
                  <div className="profile-info ">
                    <h4>{this.state.profileData.full_name}</h4>
                    <span>@{this.state.userName}</span>
                  </div>
                </div>
                <div className="pro-opt-btn">
                  <button
                    type="button"
                    className="trans-btn"
                    onClick={this.logout}
                  >
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.16667 18.3333C4.10406 18.3333 0 14.2293 0 9.16667C0 4.10406 4.10406 0 9.16667 0C14.2293 0 18.3333 4.10406 18.3333 9.16667C18.3333 14.2293 14.2293 18.3333 9.16667 18.3333ZM15.0266 13.8481C16.0529 12.565 16.6667 10.9375 16.6667 9.16667C16.6667 5.02453 13.3088 1.66667 9.16667 1.66667C7.39582 1.66667 5.76831 2.2804 4.48525 3.30676L15.0266 13.8481ZM13.8481 15.0266C12.565 16.0529 10.9375 16.6667 9.16667 16.6667C5.02453 16.6667 1.66667 13.3088 1.66667 9.16667C1.66667 7.39582 2.28039 5.76833 3.30674 4.48527L13.8481 15.0266Z"
                        fill="#C12A37"
                      />
                    </svg>
                    Logout
                  </button>
                </div>
              </div>
            </div>
            <div className="black-card d-d-card ">
              <div className="profile-option-list">
                <ul>
                  <li>
                    <a href="#">
                      <div className="list-title">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 10.875H21M16.5 7.5V3M7.5 7.5V3M3 5.25H21V21H3V5.25Z"
                            stroke="#C12A37"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <h4>Birthday</h4>
                      </div>
                      <div className="list-info">
                        <h5>
                          {moment(this.state.profileData.dob).format(
                            "MM/DD/yyyy"
                          )}
                        </h5>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="list-title">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2 5.25C2 4.69772 2.44772 4.25 3 4.25H21C21.5523 4.25 22 4.69772 22 5.25V18.75C22 19.3023 21.5523 19.75 21 19.75H3C2.44772 19.75 2 19.3023 2 18.75V5.25ZM4 6.25V17.75H20V6.25H4Z"
                            fill="#C12A37"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.24075 4.59922C2.60017 4.17989 3.23147 4.13132 3.6508 4.49074L12 11.6471L20.3492 4.49074C20.7686 4.13132 21.3999 4.17989 21.7593 4.59922C22.1187 5.01855 22.0701 5.64984 21.6508 6.00926L12.6508 13.7234C12.2763 14.0444 11.7237 14.0444 11.3492 13.7234L2.34923 6.00926C1.92991 5.64984 1.88134 5.01855 2.24075 4.59922Z"
                            fill="#C12A37"
                          />
                        </svg>
                        <h4>Email</h4>
                      </div>
                      <div className="list-info">
                        <h5>{this.state.profileData.email_address}</h5>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="list-title">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.625 3H6.375V21H17.625V3Z"
                            stroke="#C12A37"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.9941 17.625H12.0054"
                            stroke="#C12A37"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <h4>Phone Number</h4>
                      </div>
                      <div className="list-info">
                        <h5>{this.state.profileData.phone_number}</h5>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="list-title">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2 9.75C2 9.19772 2.44772 8.75 3 8.75H21C21.5523 8.75 22 9.19772 22 9.75V21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21V9.75ZM4 10.75V20H20V10.75H4Z"
                            fill="#C12A37"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 4C9.48134 4 7.375 6.13206 7.375 8.841C7.375 9.39328 6.92728 9.841 6.375 9.841C5.82272 9.841 5.375 9.39328 5.375 8.841C5.375 5.09919 8.30641 2 12 2C15.6936 2 18.625 5.09919 18.625 8.841C18.625 9.39328 18.1773 9.841 17.625 9.841C17.0727 9.841 16.625 9.39328 16.625 8.841C16.625 6.13206 14.5187 4 12 4Z"
                            fill="#C12A37"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 13.7257C12.5523 13.7257 13 14.1734 13 14.7257V15.8507C13 16.403 12.5523 16.8507 12 16.8507C11.4477 16.8507 11 16.403 11 15.8507V14.7257C11 14.1734 11.4477 13.7257 12 13.7257Z"
                            fill="#C12A37"
                          />
                        </svg>

                        <h4>Change Password</h4>
                      </div>
                      <div className="list-info">
                        <h5>
                          <input
                            type="password"
                            disabled
                            value={this.state.password}
                            className="password-input"
                          />
                        </h5>
                        {this.state.loginType === "erisk" ? (
                          <svg
                            onClick={this.handleOpen}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 6L15 12L9 18"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : null}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {this.state.showModal ? (
          <Modal
            open={this.state.showModal}
            aria-labelledby="changePasswordModal"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Change Password
                  </h5>
                </div>

                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body">
                    <div className="fp_label">
                      <h6>
                        Enter Current Password <em className="mandatory">* </em>
                      </h6>
                      <input
                        type="password"
                        className="fp_modal_input fp_modal_input_profile"
                        value={this.state.oldPassword}
                        onChange={this.handlePasswordChange}
                        name="oldPassword"
                        autoFocus
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body">
                    <div className="fp_label">
                      <h6>
                        Enter New Password <em className="mandatory">* </em>
                      </h6>
                      <input
                        type="password"
                        className="fp_modal_input fp_modal_input_profile"
                        value={this.state.newPassword}
                        onChange={this.handleNewPasswordChange}
                        name="newPassword"
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body">
                    <div className="fp_label">
                      <h6>
                        Confirm New Password <em className="mandatory">* </em>
                      </h6>
                      <input
                        id="toggle"
                        type="password"
                        className="fp_modal_input fp_modal_input_profile"
                        value={this.state.confirmPassword}
                        onChange={this.handleConfirmPasswordChange}
                        name="confirmPassword"
                        autoComplete="off"
                        required
                      />
                      <a href="#" onClick={this.togglePassword}>
                        <i className="fa fa-eye-slash" id="toggleIcon"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.resetPassword}
                    disabled={this.state.loader}
                  >
                    Update
                    {this.state.loader ? (
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}

        {this.state.showLogout ? (
          <Modal
            open={this.state.showLogout}
            aria-labelledby="logoutModal"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Logout?
                  </h5>
                </div>

                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b20 text-center">
                    <h6 style={{ color: "white" }}>
                      Are you sure you want to logout?
                    </h6>
                  </div>
                </div>

                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.logoutUser}
                    disabled={this.state.loader}
                  >
                    Logout
                    {this.state.loader ? (
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : null}
                  </button>
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}

        {this.state.editModal ? (
          <Modal
            open={this.state.editModal}
            aria-labelledby="changePasswordModal"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Edit Details
                  </h5>
                </div>

                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body">
                    <div className="fp_label">
                      <h6>
                        First Name<em className="mandatory">* </em>
                      </h6>
                      <input
                        type="text"
                        className="fp_modal_input fp_modal_input_profile"
                        value={this.state.firstName}
                        onChange={this.handleFirstName}
                        name="firstName"
                        autoFocus
                        autoComplete="off"
                        required
                      />
                    </div>
                    <div className="fp_label">
                      <h6>Last Name</h6>
                      <input
                        type="text"
                        className="fp_modal_input fp_modal_input_profile"
                        value={this.state.lastName}
                        onChange={this.handleLastName}
                        name="lastName"
                        autoFocus
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body">
                    <div className="fp_label">
                      <h6>
                        Phone Number<em className="mandatory">* </em>
                      </h6>
                      <input
                        type="number"
                        pattern="/^[789]\d{9}$/"
                        className="fp_modal_input fp_modal_input_profile"
                        value={this.state.phoneNumber}
                        onChange={this.handlePhoneNumber}
                        name="phonenumber"
                        autoComplete="off"
                        required
                      />
                    </div>
                    {this.state.alertMobile ? (
                      <span className="color-red">
                        <i>{this.state.errorMobile}</i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body">
                    <div className="fp_label">
                      <h6>
                        Select DOB<em className="mandatory">* </em>
                      </h6>
                      <MuiPickersUtilsProvider utils={Moment}>
                        <KeyboardDatePicker
                          value={this.state.dateOfBirth}
                          onChange={this.handleDateOfBirth}
                          format="MM/DD/yyyy"
                          className="fp_modal_input fp_modal_input_profile"
                          inputVariant="outlined"
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.editAccount}
                    disabled={this.state.loader}
                  >
                    Update
                    {this.state.loader ? (
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}

        {this.state.showLogout ? (
          <Modal
            open={this.state.showLogout}
            aria-labelledby="DeleteUserProfile"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Session Expired
                  </h5>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">
                      Please login again.
                      <br />
                      <br />
                    </h6>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.logoutUser}
                    // disabled={this.state.loader}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default Profile;
