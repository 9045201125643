import React, { Component } from "react";
import deviceIcon from "../../../assets/images/device.svg";
const H = window.H;

class AdminMaps extends Component {
  mapRef = React.createRef();
  state = {
    map: null,
    isAdmin: false,
  };

  componentDidMount() {
    const platform = new H.service.Platform({
      apikey: process.env.REACT_APP_HEREMAP_APIKEY,
    });

    const defaultLayers = platform.createDefaultLayers();

    const map = new H.Map(
      this.mapRef.current,
      defaultLayers.vector.normal.map,
      {
        center: { lat: 50, lng: 5 },
        zoom: 4,
        pixelRatio: window.devicePixelRatio || 1,
        useCIT: true,
        useHTTPS: true,
      }
    );

    var coords = { lat: 0, lng: 0 };

    JSON.parse(
      window.sessionStorage.getItem("UserDetails")
    ).device_name.forEach((element) => {
      coords = {
        lat: element.latitude,
        lng: element.longitude,
      };
    });

    map.setCenter(coords);

    // Instantiate a circle object (using the default style):
    var circle = new H.map.Circle(coords, 8000);

    // Add the circle to the map:
    map.addObject(circle);

    // Enable the event system on the map instance:
    var mapEvents = new H.mapevents.MapEvents(map);

    // Instantiate the default behavior, providing the mapEvents object:
    var behavior = new H.mapevents.Behavior(mapEvents);

    // Create the default UI components to allow the user to interact with them
    // This variable is unused
    const ui = H.ui.UI.createDefault(map, defaultLayers);

    var group = new H.map.Group();

    map.addObject(group);

    // add 'tap' event listener, that opens info bubble, to the group
    group.addEventListener(
      "tap",
      function (evt) {
        // event target is the marker itself, group is a parent event target
        // for all objects that it contains
        var bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
          // read custom data
          content: evt.target.getData(),
        });
        // show info bubble
        ui.addBubble(bubble);
      },
      false
    );

    JSON.parse(
      window.sessionStorage.getItem("UserDetails")
    ).device_name.forEach((element) => {
      this.addMarkerToGroup(
        group,
        { lat: element.latitude, lng: element.longitude },
        "<a href='/dashboard/details' style='font-size: 12px;'>" +
          "Device Details" +
          "</a> <h6 style='margin-top: 5px; font-size: 12px'> <b>" +
          element.name +
          "</b> </h6> "
      );
      window.sessionStorage.setItem("IsAdminMap", true);
    });

    this.setState({ map });
  }

  addMarkerToGroup(group, coordinate, html) {
    var svgMarkup = deviceIcon;

    // Create an icon, an object holding the latitude and longitude, and a marker:
    var icon = new H.map.Icon(svgMarkup);

    var marker = new H.map.Marker(coordinate, { icon: icon });
    // add custom data to the marker
    marker.setData(html);
    group.addObject(marker);
  }

  redirectToDeviceDetails() {
    this.props.history.push({
      pathname: "/dashboard/details",
    });
  }

  render() {
    return (
      <div className="registered-users ">
        <div
          ref={this.mapRef}
          style={{
            height: "450px",
            width: "100%",
            cursor: "pointer",
          }}
        />
      </div>
    );
  }
}

export default AdminMaps;
