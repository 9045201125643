import api from "./api";

function getHardwareDevicesList(getDevices) {
  return new Promise((resolve, reject) => {
    api.post("/fileSharing/dataSharing", getDevices).then((response) => {
      resolve(response);
    });
  });
}

function getFileStructure(pathName) {
  return new Promise((resolve, reject) => {
    api.post("/fileSharing/fileStructure", pathName).then((response) => {
      resolve(response);
    });
  });
}

function createDirectory(create) {
  return new Promise((resolve, reject) => {
    api.post("/fileSharing/createDirectory", create).then((response) => {
      resolve(response);
    });
  });
}

function renameFiles(create) {
  return new Promise((resolve, reject) => {
    api.post("/fileSharing/renameFiles", create).then((response) => {
      resolve(response);
    });
  });
}

function downloadFolders(create) {
  return new Promise((resolve, reject) => {
    api
      .get(`/fileSharing/downloadFoldersWeb/{"path":"${create.path}"}`)
      .then((response) => {
        resolve(response);
      });
  });
}

function downloadFiles(create) {
  return new Promise((resolve, reject) => {
    api
      .get(`/fileSharing/downloadFiles/{"path":"${create.path}"}`)
      .then((response) => {
        resolve(response);
      });
  });
}

function moveFiles(create) {
  return new Promise((resolve, reject) => {
    api.post("/fileSharing/moveFiles", create).then((response) => {
      resolve(response);
    });
  });
}

function copyFiles(create) {
  return new Promise((resolve, reject) => {
    api.post("/fileSharing/copyFiles", create).then((response) => {
      resolve(response);
    });
  });
}
function copyFilesToCloud(create) {
  return new Promise((resolve, reject) => {
    api.post("/fileSharing/copyToCloud", create).then((response) => {
      resolve(response);
    });
  });
}

function deleteFile(create) {
  return new Promise((resolve, reject) => {
    api.post("/fileSharing/deleteFile", create).then((response) => {
      resolve(response);
    });
  });
}

function validateSize(create) {
  return new Promise((resolve, reject) => {
    api.post("/fileSharing/validatingDeviceSize", create).then((response) => {
      resolve(response);
    });
  });
}

function uploadFiles(create) {
  return new Promise((resolve, reject) => {
    api.post("/fileSharing/uploadFiles", create).then((response) => {
      resolve(response);
    });
  });
}

function storagePlans(create) {
  return new Promise((resolve, reject) => {
    api.post("/stripe/storagePlans", create).then((response) => {
      resolve(response);
    });
  });
}

function stripePayment(create) {
  return new Promise((resolve, reject) => {
    api.post("/stripe/payment-sheet", create).then((response) => {
      resolve(response);
    });
  });
}
function fileStructure(create) {
  return new Promise((resolve, reject) => {
    api.post("/fileSharing/fileStructure", create).then((response) => {
      resolve(response);
    });
  });
}
function folderStructure(create) {
  return new Promise((resolve, reject) => {
    api.post("/fileSharing/getFolders", create).then((response) => {
      resolve(response);
    });
  });
}
function getNotificationCount(create) {
  return new Promise((resolve, reject) => {
    api.get("/user/getNotificationCount").then((response) => {
      resolve(response);
    });
  });
}

const fileSharingMethods = {
  getHardwareDevicesList,
  getFileStructure,
  createDirectory,
  renameFiles,
  downloadFiles,
  downloadFolders,
  moveFiles,
  copyFiles,
  deleteFile,
  validateSize,
  uploadFiles,
  storagePlans,
  stripePayment,
  getNotificationCount,
  fileStructure,
  folderStructure,
  copyFilesToCloud,
};

export default fileSharingMethods;
